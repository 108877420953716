import { useState, useEffect ,useContext,useRef} from "react";
import "../../App.css";
import Clicker from "../../Components/Clicker/Clicker";
import Footer from "../../Components/Footer/Footer";
import Referal from "../../Components/Referal/Referal";
import Recomp from "../../Components/Recomp/Recomp";
import { Context } from "../../Context/Context";
import { Tabs,Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import HeaderEarn from "../../Components/HeaderEarn/HeaderEarn";
import { Button, Space , Toast, Modal} from 'antd-mobile'
import { Link, useNavigate } from 'react-router-dom';
import {change_lang} from '../../Hooks/firebase_functions'
import {languages,languagesC,translate,translateC} from '../../Hooks/translate'
import backImg from '../../images/backImg.png'
import arrowBelow from '../../images/arrowBelow.png'
import silver2Img from '../../images/silver2Img.png'

const tele = window.Telegram.WebApp;
//const tonConnect = new TonConnect();


function Friends() {
  const {value, value2, value3} = useContext(Context)

  const [langContext, setLangContext] = value
  const [userData, setUserData] = value2
  const [comingSoon, setComingSoon] = useState(false)
  const [langPopup, setLangPopop] = useState(false)
  const navigate = useNavigate();






  
  return (
    <div style={{backgroundColor:"#131313",width:'100vw',overflow:'hidden'}} className="full-screen-settings">
    <Modal
        visible={comingSoon}
        bodyStyle={{
          border:'solid 1px #062321',
          backgroundColor: "#060B18",
          borderRadius:15
        }}
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginLeft: 10, marginRight: 10 }}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: 0, marginTop: 10 }}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",alignItems:'center', padding: 0, marginTop: 10,marginBottom:10,marginLeft:5,marginRight:5 }}>
                <span className="fontRobot" style={{ color: "#fff", fontSize: 25, fontWeight: 900, alignContent: "center", marginLeft: 0 }}>
                 WolfMaster
                </span>
                <span className="fontRobot" style={{ color: "#fff", fontSize: 25, fontWeight: 900, alignContent: "center", marginLeft: 0 }}>
                Only
                </span>
              </div>  
              </div>              
            </div>
          </>
        }
        closeOnAction
        onClose={()=>{setComingSoon(false)}}
        
        actions={[
          {
            key: 'confirm',
            text: 'Ok',
            style: {
              backgroundColor: '#4CC37D', borderRadius: 8, paddingTop: 8, paddingBottom: 8, color: '#fff',
              paddingLeft:20,
              paddingRight:20,fontWeight:800,fontSize:16,
              boxShadow: '0 0 5px #37825E, 0 0 7px #37825E'
            }
          },
        ]}
      />
      <Modal
        visible={langPopup}
        bodyStyle={{
          border:'solid 1px #062321',
          backgroundColor: "#060B18",
          borderRadius:15
        }}
        content={
          <>
          <p style={{fontWeight:700,color:'#fff',marginTop:0,textAlign:'center',fontSize:15,width:'100%'}}>
                Choose language
            </p>
            {languagesC.map((lang,index)=>{
              return(
                <div className="touchable" onClick={async()=>{setLangContext(lang.codeW);await change_lang(userData,lang.codeO)}} style={{marginLeft:5,marginTop:10,marginRight:5}} key={index}>
                  {lang.codeO !== 'FR' ?
                  <div  style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'space-between',backgroundColor:'#141A25',border:langContext == lang.codeW ?'solid 1px #B1B1B1': 'solid 1px #141A25',marginTop:12,borderRadius:10,paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2}}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'space-around'}}>
                      <span style={{fontWeight:700,color:'#fff',marginLeft:8,marginTop:5,fontSize:15,width:'100%'}}>
                          {lang.title}
                      </span>
                      <span style={{fontWeight:500,color:'#B1B1B1',marginLeft:8,marginTop:3,marginBottom:3,fontSize:15,width:'100%'}}>
                      {lang.codeO}
                      </span>
                    </div>
                    <Link onClick={()=>{/* setComingSoon(true) */}} /* to={'/link'} */ style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,width:40,paddingTop:14,paddingBottom:14}}>
                        <img style={{height:15,width:15}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722871138/Arrow_5_uxxodj.png"/>
                        
                      </div>
                    </Link>
                  </div>
                  :null}
                </div>
              )
            })}
          </>
        }
        showCloseButton
        closeOnAction
        onClose={()=>{setLangPopop(false)}}
        
       /*  actions={[
          {
            key: 'confirm',
            text: 'Ok',
            style: {
              backgroundColor: '#4CC37D', borderRadius: 8, paddingTop: 8, paddingBottom: 8, color: '#fff',
              paddingLeft:20,
              paddingRight:20,fontWeight:800,fontSize:16,
              boxShadow: '0 0 5px #37825E, 0 0 7px #37825E'
            }
          },
        ]} */
      />

      <div style={{position:'relative',marginTop:40}}>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',  paddingRight: 0,paddingTop:0,marginBottom:0,backgroundColor:'#131313' ,margin:'5px 20px 5px 20px'}}>
        <div onClick={()=>{navigate('/friends')}} className="touchable">
          <img style={{height:15,width:15,marginLeft:0,marginTop:2}} src={backImg}/>
        </div>
        <p style={{color:'#fff',fontWeight:400,textAlign:'center',fontSize:22,margin:0}}>{translate('Settings', langContext,'ENGLISH')}</p>

        <div style={{opacity:0}} className="touchable">
          <img style={{height:15,width:15,marginLeft:0,marginTop:2}} src={backImg}/>
        </div>
    </div>
        {/* <p style={{color:'#fff',fontWeight:400,textAlign:'center',marginTop:15,fontSize:14,margin:'5px 80px 0 80px'}}>Choose your hzuedi dued neduhd edeu ebebduebdd</p> */}
      </div>

      <div style={{marginLeft:15,marginTop:10,marginRight:15}}> 
        <div  style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'space-between',marginTop:20,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
          <div style={{display:'flex',flexDirection:'column',alignItems:'space-around'}}>
            <span style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:5,fontSize:15,width:'100%'}}>
                {translate('Choose language', langContext,'ENGLISH')}
            </span>
            <span style={{fontWeight:400,color:'#B1B1B1',marginLeft:8,marginTop:3,marginBottom:3,fontSize:15,width:'100%'}}>
                {languagesC.filter((lang)=>{if(lang.codeW == langContext){return lang}})[0].title}
            </span>
          </div>
          <Link onClick={()=>{setLangPopop(true)}} /* to={'/link'} */ style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
            <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,padding:'2px 10px 2px 10px',borderRadius:5}}>
              <img style={{height:25,width:25}} src={arrowBelow}/>
              
            </div>
          </Link>
        </div>
      </div>

      <div style={{borderBottom:'solid 0.5px #464646',display:'flex',flexDirection:'row',justifyContent:'center',margin:'10px 20px 0px 20px'}}></div>

      <div style={{marginLeft:15,marginTop:-10,marginRight:15}}>
        <div  style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'space-between',marginTop:20,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
          <div style={{display:'flex',flexDirection:'row',alignSelf:'center'}}>
            <span style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:-3,fontSize:15,width:'100%'}}>
                {translate('Delete account', langContext,'ENGLISH')}
            </span>

          </div>
          <Link onClick={()=>{setComingSoon(true)}} /* to={'/link'} */ style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
          <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,padding:'2px 10px 2px 10px',borderRadius:5}}>
          <img style={{height:25,width:25}} src={arrowBelow}/>
              
            </div>
          </Link>
        </div>
      </div>

      <p style={{fontWeight:500,color:'#fff',marginLeft:20,marginTop:25,fontSize:14,marginBottom:0,width:'100%'}}>
        {translate('Our networks', langContext,'ENGLISH')}
      </p>

      {/* <div className="cafrd" style={{marginLeft:15,marginRight:15,marginTop:0}}>
        <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'space-between',backgroundColor:'#141A25',marginTop:13,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <img style={{height:50,width:50,padding:5}} src={'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722873607/Groupf_63_xzkypy.png'}/>
            <span 
              className="two-line"
              style={{fontWeight:700,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'75%'            }}>
                Invite 3 friends
            </span>
          </div>
          <Link  style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
            <div className="recCoin touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#5DD387',width:80,paddingTop:10,paddingBottom:10,borderRadius:7}}>
            <span style={{fontWeight:700,color:'#fff',marginBottom:0,fontSize:14}}>&nbsp;{'+5k'}&nbsp;</span>
              <img style={{height:20,width:20}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>
              
            </div>
          </Link>
        
        </div>
      </div>
 */}
      <div className="cafrd" style={{textDecoration:'none',marginLeft:15,marginRight:15,marginTop:0}}>
        <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:13,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <img style={{height:30,width:30,padding:0}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722965365/270_d7kji8.png"}/>
            <span 
              className="two-line"
              style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'75%'}}>
                 {translate('Follow us on X', langContext,'ENGLISH')}
            </span>
          </div>
          <Link onClick={()=>{/* setComingSoon(true) */}} to={"https://x.com/vikex_official"} style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
            <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,paddingTop:6,paddingBottom:6/* padding:'10px 10px 10px 10px' */,borderRadius:5}}>
              <p style={{color:'#fff',fontWeight:'500',fontSize:13,margin:0,alignItems:'center'}}>+10</p>
              <img style={{height:20,width:20,marginLeft:1,marginRight:-2}} src={silver2Img}/>
            </div>
          </Link>
        
        </div>
      </div>

      <div className="cafrd" style={{marginLeft:15,marginRight:15,marginTop:0}}>
        <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:5,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <img style={{height:30,width:30,padding:0}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722965364/267_pr1jhj.png"}/>
            <span 
              className="two-line"
              style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'95%'}}>
                 {translate('Join our Telegram', langContext,'ENGLISH')}
            </span>
          </div>
          <Link   to={"https://t.me/wolfera_announcement"} style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
          <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,paddingTop:6,paddingBottom:6/* padding:'10px 10px 10px 10px' */,borderRadius:5}}>
              <p style={{color:'#fff',fontWeight:'500',fontSize:13,margin:0,alignItems:'center'}}>+10</p>
              <img style={{height:20,width:20,marginLeft:1,marginRight:-2}} src={silver2Img}/>
            </div>
          </Link>
        
        </div>
      </div>

      <div className="cafrd" style={{marginLeft:15,marginRight:15,marginTop:0,marginBottom:20}}>
        <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:5,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <img style={{height:30,width:30,padding:0}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722965363/269_g0c9bm.png"}/>
            <span 
              className="two-line"
              style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'95%'}}>
                
                {translate('Subscribe on Youtube', langContext,'ENGLISH')}
            </span>
          </div>
          <Link  to={"https://www.youtube.com/@Wolfera_io"} style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
          <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,paddingTop:6,paddingBottom:6/* padding:'10px 10px 10px 10px' */,borderRadius:5}}>
              <p style={{color:'#fff',fontWeight:'500',fontSize:13,margin:0,alignItems:'center'}}>+10</p>
              <img style={{height:20,width:20,marginLeft:1,marginRight:-2}} src={silver2Img}/>
            </div>
          </Link>
        
        </div>
      </div>

      {/* <div className="touchable" onClick={()=>{navigate('/friends')}}   style={{width:'90%',display:'flex',flexDirection:'row',marginLeft:'50%',transform:'translate(-50%,0%)',justifyContent:'center',borderRadius:7,padding:5,backgroundColor:'#5DD387',boxShadow:'0 0 2px 3px #2A9656'}}>
          <p  style={{color:'#fff',textAlign:'center',fontSize:16,fontWeight:600,marginTop:8,marginBottom:8}}>  {translate('Back', langContext,'ENGLISH')}</p>
      </div> */}







      {/* <div className="touchable" onClick={()=>{navigate('/home')}}   style={{position:'fixed',width:'70%',display:'flex',bottom:70,flexDirection:'row',left:'50%',transform:'translate(-50%,0%)',justifyContent:'center',borderRadius:7,padding:5,backgroundColor:'#5DD387',boxShadow:'0 0 2px 3px #2A9656'}}>
          <p  style={{color:'#fff',textAlign:'center',fontSize:16,fontWeight:600,marginTop:8,marginBottom:8}}>  {translate('Back', langContext,'ENGLISH')}</p>
      </div> */}

      {/* <Footer/> */}


    </div>
  );
}
export default Friends;
