import React, { useState,useContext ,useEffect} from "react";
import "./ListFriends.css";
import {get_friends,get_balance,get_all_firends,get_users} from '../../Hooks/firebase_functions'
import Button from "../Button/Button";
import { Context } from "../../Context/Context";
import { List, Image } from 'antd-mobile'
import { List as VirtualizedList, AutoSizer } from 'react-virtualized'
import {  SpinLoading } from 'antd-mobile'
import silver2Img from '../../images/silver2Img.png'

import UsernameSpan from './Username'
import { all } from "axios";
const rowCount = 10

const item = [
  {
    avatar:
      'https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    name: 'Adrien W',
    level: 'Silver',
    coin:1.600,
    price: 25
  },
  {
    avatar:
      'https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    name: 'Carla B',
    level: 'Silver',
    coin:1.600,
    price:25
  },
]

const data = Array(rowCount).fill(item)

function ListFriends() {
  const { value, value2, value3,value4,value5,value6,value7 } = useContext(Context);
  const [userData, setUserData] = value2;

  const [visibleMore, setVisibleMore] = useState(false);
  
  const [friends, setFriends] = value7;
  const [loading, setLoading] = useState(true);
  const [isFetch,setIsFetch] = useState(false)
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

 

  const get_data_friends = async (allUsers) => {
    const all_data = []
    for(let user in allUsers){
      //console.log("user",allUsers,user,allUsers[user].userId)
      const dataInit = await get_users(allUsers[user].userId)
      if(dataInit && dataInit.length > 0){
        const data = dataInit[0]
       // console.log("data",data)
        all_data.push({
          username: data.username ? data.username: false,
          userId:data.userId ? data.userId : false,
          refs:data.refs ? data.refs:0,
          balance:data.sheep && data.sheep>0 ? data.sheep : 0,
        })
      }

    }
    return all_data
  }
  useEffect(() => {   
    const loadFriends = async () => {
      setLoading(true);
      const { docs: initialFriendsDocs, lastVisible: newLastVisible } = await get_friends(Number(userData.userId));
      //const allFriends = await get_all_firends(Number(userData.userId))
      //console.log('allFriends',allFriends)
      const allFriends = initialFriendsDocs.map(doc => ({ id: doc.id, ...doc.data() }))
      
      //setFriends(allFriends)
    const a = await get_data_friends(allFriends)
      console.log('AAAAFFF',a)
      setFriends(a);
      setLastVisible(newLastVisible);
      setLoading(false);
      setHasMore(initialFriendsDocs.length === 5);
      setIsFetch(true)
    };
    if(!friends){
      loadFriends();
    }else{
      setIsFetch(true)
    }
   
  }, [userData.userId]);

  const loadMoreFriends = async () => {
    const { docs: moreFriendsDocs, lastVisible: newLastVisible } = await get_friends(userData.userId, lastVisible);
    const moreF = moreFriendsDocs.map(doc => ({ id: doc.id, ...doc.data() }))
    const newFriends = await get_data_friends(moreF)
    
    setFriends(prevFriends => [...prevFriends, ...newFriends]);


    setLastVisible(newLastVisible);
    setHasMore(moreFriendsDocs.length === 5);
  };




  return (
    <div style={{marginTop:5,marginBottom:200,paddingBottom:250}}>
      {isFetch ?
      <>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'flex-start',marginLeft:20,marginRight:20}}>
        <p style={{color:'#919191',fontSize:13,fontWeight:400,width:17,marginBottom:0}}>#</p>
        <p style={{color:'#919191',fontSize:13,fontWeight:400,width:'20%',marginBottom:0}}>User</p>
        <p style={{color:'#919191',fontSize:13,fontWeight:400,minWidth:60,marginBottom:0}}>Referrals</p>
        <p style={{color:'#919191',fontSize:13,fontWeight:400,minWidth:60,marginBottom:0}}>Balance</p>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',alignContent:'flex-start'}}>
          <p style={{color:'#919191',fontSize:13,fontWeight:400,minWidth:85,marginBottom:0,textAlign:'right'}}>Daily Reward</p>
          <p style={{color:'#919191',fontSize:13,fontWeight:400,minWidth:85,marginBottom:0,marginTop:0,textAlign:'right'}}>(10%)</p>
        </div>
      </div>
      {friends && friends.length > 0 && friends.map((i,k)=>{
        return(
          <>
            {i.userId ?  (
            <div style={{display:'flex',borderTop:k !== 0 ? 'solid 0.5px #414141' :'solid 0px #000',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginLeft:10,marginRight:20}}>          
              <div style={{backgroundColor:k == 0 ? '#C2A63B': (k == 1 ? '#C6C6C7' : (k == 2 ? '#D78939' : '#21212C')),width:27,height:27,borderRadius:50,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <p style={{color:k == 0 ? '#21212C': (k == 1 ? '#21212C' : (k == 2 ? '#21212C' : '#fff')),fontWeight:'400',textAlign:'center',fontSize:11}}>{k + 1}</p>
              </div>
              <div style={{width:'20%'}}>
                {/* <UsernameSpan  userId={i.userId} price={i.price}/> */}
                <p style={{color:'#fff',fontSize:13,fontWeight:400,minWidth:60,textAlign:'left'}}>{i.username ? i.username : i.userId}</p>
              </div>
              <p style={{color:'#fff',fontSize:13,fontWeight:400,minWidth:60,textAlign:'center'}}>{i.refs}</p>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',minWidth:60,justifyContent:'center'}}>
                <p style={{color:'#fff',fontSize:13,fontWeight:400,textAlign:'center'}}>{i.balance}</p>
                <img style={{height:20,width:20,marginLeft:2}} src={silver2Img}/>
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',minWidth:85,justifyContent:'center'}}>
                <p style={{color:'#fff',fontSize:13,fontWeight:400,textAlign:'center'}}>+{Math.floor(i.balance/10)}</p>
                <img style={{height:20,width:20,marginLeft:2}} src={silver2Img}/>
              </div>
            </div>
            ):null}
          </>
        )
      })}
      {friends &&friends.length > 0 ?
      <>
        {/* friends.length%5 ==0 */true ?
        <div className="touchable" style={{marginTop:5,display:'flex',flexDirection:'row',justifyContent:'center'}} onClick={async()=>{await loadMoreFriends()}}>
          <span style={{color:'#BBB9CC',fontWeight:700,fontSize:14,textAlign:'center',border:'solid 0px #BBB9CC',borderRadius:8}}>{'Load more'}</span>
        </div>
        :null}
      </>
      
      :
      <div  style={{marginTop:5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <span style={{color:'#919191',fontWeight:500,fontSize:14,textAlign:'center',border:'solid 0px #BBB9CC',borderRadius:8}}>{"You don't have referrals yet"}</span>
      </div>
      }
      </>
      :
      <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:50}}>
        <SpinLoading style={{ '--size': '24px','--color':'white' }} />
      </div>
      }
      
    </div>
  );
}

export default ListFriends;
