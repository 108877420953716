import { useState, useEffect } from "react";
import "./App.css";
import Card from "./Components/Card/Card";
import Clicker from "./Components/Clicker/Clicker";
import Footer from "./Components/Footer/Footer";
import { Routes ,Route,Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ContextProvider } from './Context/Context'
import Friends from "../src/pages/Friends"
import Airdrop from "../src/pages/Airdrop"
import Home from "../src/pages/Home"
import Onboarding from "../src/pages/Onboarding"
import Trade from "./pages/Trade"
import Earn from "./pages/Earn"
import WolfRocket from './pages/Rocket'
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import LoadingPage from "./Components/LoadingPage/LoadingPage";
import MenuExchange from "./pages/MenuExchange"
import Leaderboard from "./pages/Leaderboard"
import MobileOnly from "./pages/MobileOnly";
import Settings from './pages/Settings'
import History from './Components/TradingComp/History'
import Setusername from './pages/Setusername'
import ComingSoon from "./pages/ComingSoon";
const manifestUrl = 'https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json';


const tele = window.Telegram.WebApp;

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

// Fonction utilitaire pour vérifier si la largeur de l'écran est inférieure à un certain seuil
const isMobileScreen = () => {
  return window.innerWidth <= 768; // seuil pour mobile
};
function App() {
  const [loading, setLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(true);
  function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  useEffect(() => {
    const checkAccess = () => {
      if (!isMobileDevice() || !isMobileScreen()) {
        setIsAllowed(false);
      }
    };

    // Vérification initiale
    checkAccess();

    // Vérification à chaque redimensionnement de la fenêtre
    window.addEventListener('resize', checkAccess);

    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', checkAccess);
    };
  }, []);
 
  useEffect(() => {
    // Simulez une période de chargement
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 3 secondes, ajustez selon vos besoins
  }, []);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    if (tg) {
      tg.ready();
      tg.expand();
      //tg.isVerticalSwipesEnabled = false
      //tg.disableVerticalSwipes()
      // Verrouiller les dimensions de la fenêtre
      window.addEventListener('resize', preventResize);

      return () => {
        window.removeEventListener('resize', preventResize);
      };
    }
  }, []);
  

  // Fonction pour empêcher le redimensionnement
  const preventResize = (event) => {
    event.preventDefault();
    window.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };


  useEffect(() => {
    const tg = window.Telegram.WebApp;

    // Détecter la fermeture de la WebApp
    tg.onEvent('web_app_close', () => {
      //console.log("WebApp closed");
      // Faites quelque chose avant la fermeture de la WebApp, comme envoyer des données
    });

    // Détecter la fermeture de l'application Telegram ou la fermeture de l'onglet/navigateur
    const handleBeforeUnload = (event) => {
     // console.log("Telegram app or browser tab closed");
      // Faites quelque chose avant la fermeture de l'onglet/navigateur, comme envoyer des données
      event.preventDefault(); // Empêche la fermeture immédiate
      event.returnValue = ''; // Standard pour certains navigateurs
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (!isAllowed || isAllowed){
    return (
      <div className="blocked" style={{backgroundColor:'#141414',scrollbarWidth:0}}>
        <>
        {loading ? <LoadingPage page={1}/> : 
        <ContextProvider>
          <TonConnectUIProvider manifestUrl={manifestUrl}>
            <Router>
              <Routes>
                {/* <Route exact path="/" element={<Home/>} /> */}
                <Route exact path="/" element={<ComingSoon/>} />
                <Route path="/mobile" element={<ComingSoon/>} />
                <Route path="/onboarding" element={<ComingSoon/>} />
                <Route path="/setusername" element={<ComingSoon/>} />
                <Route path="/exchange" element={<ComingSoon/>} />
                <Route path="/manage" element={<ComingSoon/>} />
                <Route path="/trade" element={<ComingSoon/>} />
                <Route path="/earn" element={<ComingSoon/>} />
                <Route path="/friends" element={<ComingSoon/>} />
                <Route path="/airdrop" element={<ComingSoon/>} />
                <Route path="/rocket" element={<ComingSoon/>} />
                <Route path="/leaderboard" element={<ComingSoon/>} />
                <Route path="/settings" element={<ComingSoon/>} />
                <Route path="/history" element={<ComingSoon/>} />
                <Route path="/comingsoon" element={<ComingSoon/>} />

                <Route path="*" element={<Friends/>} />
                
                {/* <Route path="*" element={<Home/>} /> */}
              </Routes>
            </Router>
            
          </TonConnectUIProvider>
        </ContextProvider>
      }
      </>
      </div>
    );
  }else{
    return(
      <div className="restriction-container">
        <div className="restriction-content">
          <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723046010/icons8-ne-pas-de%CC%81ranger-100_knfngz.png" alt="Mobile Icon" className="restriction-icon" />
          <h1 style={{marginTop:-5}}>Restricted</h1>
          <p>This application is only accessible from a mobile device.</p>
        </div>
      </div>
    )
  }
}

export default App;
