

import { useState, useEffect } from 'react';

export const usePageVisibility = () => {
  const [isPageVisible, setIsPageVisible] = useState(!document.hidden);
  
  const handleVisibilityChange = () => {
    setIsPageVisible(!document.hidden);
  };

  const handleBeforeUnload = (event) => {
    setIsPageVisible(false);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Telegram Web App close event
    if (window.Telegram?.WebApp) {
     /*  window.Telegram.WebApp.onEvent('backButtonClicked', () => {
        setIsPageVisible(false);
      }); */

      // Ouvrir un popup pour gérer la fermeture de l'application
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.setHeaderColor('#131313');	
      window.Telegram.WebApp.setBackgroundColor('#131313');	

      var BackButton =  window.Telegram.WebApp.BackButton;
      BackButton.show();
      BackButton.onClick(function() {
        setIsPageVisible(false);
        //window.Telegram.WebApp.close()
      });
      /* window.Telegram.WebApp.onEvent('backButtonClicked', function() {
        setIsPageVisible(false);
        window.Telegram.WebApp.close()
      }); */
      window.Telegram.WebView.receiveEvent('popup_closed', () => {
        setIsPageVisible(false);
      });

    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      var BackButton =  window.Telegram.WebApp.BackButton;
      BackButton.show();
      BackButton.onClick(function() {
        setIsPageVisible(false);
        //window.Telegram.WebApp.close()
      });
      
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.offEvent('backButtonClicked', () => {
          setIsPageVisible(false);
        });
        var BackButton =  window.Telegram.WebApp.BackButton;
      BackButton.show();
      BackButton.onClick(function() {
        setIsPageVisible(false);
        //window.Telegram.WebApp.close()
      });
      }
    };
  }, []);

  return isPageVisible;
};
