import { useState, useEffect ,useContext,useRef} from "react";
import "../../App.css";
import Clicker from "../../Components/Clicker/Clicker";
import Footer from "../../Components/Footer/Footer";
import Referal from "../../Components/Referal/Referal";
import Recomp from "../../Components/Recomp/Recomp";
import { Context } from "../../Context/Context";
import { Tabs,Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import HeaderEarn from "../../Components/HeaderEarn/HeaderEarn";
import { Button, Space , Toast} from 'antd-mobile'
import Header from "../../Components/Header/Header";
import { translate } from "../../Hooks/translate";
import { TonConnect } from '@tonconnect/sdk';
import { TonConnectButton } from '@tonconnect/ui-react';
import { useTonConnectModal, useTonWallet } from '@tonconnect/ui-react';
import {add_wallet,check_wallet} from '../../Hooks/firebase_functions'
import silverAirdrop from "../../images/silverAirdrop.png"
import silver2Img from '../../images/silver2Img.png'
import { Link, useNavigate } from 'react-router-dom';

const tele = window.Telegram.WebApp;
//const tonConnect = new TonConnect();


function Friends() {
  const {value, value2, value3} = useContext(Context)
  const { state, open, close } = useTonConnectModal();
  const wallet = useTonWallet();
  const navigate = useNavigate();

  const [langContext, setLangContext] = value
  const [userData, setUserData] = value2
  const [tlgmInfo, setTlgmInfo] = value3
  const [cartItems, setCartItems] = useState([]);
  const [user, setUser] = useState('');
  const [visibleMore, setVisibleMore] = useState(false);
  const [shared, setShared] = useState(true);
  const [hasConnect, setHasConnect] = useState(false);

  const swiperRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const referralLink = "https://t.me/share/url?url=https://t.me/WolferaBot?start=referral_user_id="+userData.userId

  useEffect(() => {
    const fn = async () => {
      const rep = await add_wallet(userData.userId,wallet.account.address )
      setHasConnect(true)
    }
    if (wallet && !shared) {
      fn()
      setShared(true)
        console.log('Connected Wallet:', wallet);
        // You can handle the connected wallet data here
    }
}, [wallet]);
/*   useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
    }
  }, []); */
  const [tonConnect, setTonConnect] = useState(null);
  const [walletsList, setWalletsList] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const rep = await check_wallet(userData.userId)
      setShared(rep)
      setHasConnect(rep)
    }
    const initTonConnect = async () => {
      const tonConnectInstance = new TonConnect();
      await tonConnectInstance.restoreConnection();
      setTonConnect(tonConnectInstance);
      setWalletsList(tonConnectInstance.wallets);
    };
    fn()
    initTonConnect();
  }, []);

  const handleConnect = () => {
    open();  // Opens the TonConnect modal
};

/*   const handleConnectWallet = async () => {
    if (tonConnect) {
      try {
        await tonConnect.connectWallet({
          universalLink: "https://tonkeeper.com/ton-connect",
          bridgeUrl: "https://bridge.tonapi.io/bridge",
        });
        const connectedWallet = tonConnect.wallet;
        console.log('Connected Wallet:', connectedWallet);
      } catch (error) {
        console.error('Error connecting to TON Wallet:', error);
      }
    } else {
      alert("TON Connect n'est pas initialisé.");
    }
  }; */



  const mainCircleSize = 210;
  const mainCircleOpacity = 0.24;
  const numCircles = 8;


  
  return (
    <div style={{display: 'flex', flexDirection: 'column',backgroundColor:'#131313'}}>
      <div className="main-content">
      <div className="headerColor"  style={{ zIndex: 15000 }}>
        <Header />
      </div>

    <div style={{marginTop:-130}}>
      <img 
        src={silverAirdrop} 
        alt="Loading"
        style={{width:'100vw',objectFit:'cover',padding:60}}
      />
      
    </div>

    {/* <div class="blfock">
    <div className="ripple-container-ripple" style={{zIndex:1}}>
    
      <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720130668/file_5_vgr38e.png"  alt="Golden Coin" className="golden-coin" />
    </div>
    </div> */}
    
      {/* <HeaderEarn/> */}
       {/* <TonConnectButton  className="wallet-button" style={{display:'flex',position:'fixed',bottom:100,backgroundColor:'#26A5FE',justifyContent:'center',borderRadius:7}} /> */}
      <div style={{position:'fixed',bottom:120,width:'100vw'}}>
      <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginBottom:10}}>
      <p style={{color:'#fff',fontSize:24,fontWeight:200,textAlign:'center',marginBottom:0}}>Airdrop</p>
      <p style={{color:'#C5C5C5',fontSize:14,fontWeight:200,textAlign:'center',marginBottom:0}}>Coming in October. Stay tuned</p>

      <img 
          src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722878099/Group_66_pbrvxd.png" 
          style={{width:150,objectFit:'cover'}}
        />

      <div style={{borderBottom:'solid 1px #464646',width:'80%'}}></div>
      
      <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',width:'100%'}}>
        <div className="cafrd" style={{textDecoration:'none',marginLeft:15,marginRight:15,marginTop:0}}>
          <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:13,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <img style={{height:30,width:30,padding:0}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722965365/270_d7kji8.png"}/>
              <span 
                className="two-line"
                style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'75%'}}>
                  {translate('Follow us on X', langContext,'ENGLISH')}
              </span>
            </div>
            <Link onClick={()=>{/* setComingSoon(true) */}} to={"https://x.com/vikex_official"} style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
              <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,paddingTop:3,paddingBottom:3/* padding:'10px 10px 10px 10px' */,borderRadius:3}}>
                <p style={{color:'#fff',fontWeight:'500',fontSize:13,margin:0,alignItems:'center'}}>+10</p>
                <img style={{height:20,width:20,marginLeft:1,marginRight:-2}} src={silver2Img}/>
              </div>
            </Link>
          
          </div>
        </div>

        <div className="cafrd" style={{marginLeft:15,marginRight:15,marginTop:0}}>
          <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:5,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <img style={{height:30,width:30,padding:0}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722965364/267_pr1jhj.png"}/>
              <span 
                className="two-line"
                style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'95%'}}>
                  {translate('Join our Telegram', langContext,'ENGLISH')}
              </span>
            </div>
            <Link   to={"https://t.me/wolfera_announcement"} style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
            <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,paddingTop:3,paddingBottom:3/* padding:'10px 10px 10px 10px' */,borderRadius:3}}>
            <p style={{color:'#fff',fontWeight:'500',fontSize:13,margin:0,alignItems:'center'}}>+10</p>
                <img style={{height:20,width:20,marginLeft:1,marginRight:-2}} src={silver2Img}/>
              </div>
            </Link>
          
          </div>
        </div>

        <div className="cafrd" style={{marginLeft:15,marginRight:15,marginTop:0,marginBottom:20}}>
          <div className="card-revferal centerved-elt" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:5,borderRadius:10,border:'solid 0px #2E2942',paddingLeft:8,paddingRight:10,paddingTop:5,paddingBottom:5}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <img style={{height:30,width:30,padding:0}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722965363/269_g0c9bm.png"}/>
              <span 
                className="two-line"
                style={{fontWeight:500,color:'#fff',marginLeft:8,marginTop:0,fontSize:15,width:'95%'}}>
                  
                  {translate('Subscribe on Youtube', langContext,'ENGLISH')}
              </span>
            </div>
            <Link  to={"https://www.youtube.com/@Wolfera_io"} style={{display:'flex',flexDirection:'column',alignSelf:'center',marginTop:0,marginLeft:10,textDecoration:'none'}}>
            <div className="touchable" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2,marginBottom:2,backgroundColor:'#4285F4',width:65,paddingTop:3,paddingBottom:3/* padding:'10px 10px 10px 10px' */,borderRadius:3}}>
            <p style={{color:'#fff',fontWeight:'500',fontSize:13,margin:0,alignItems:'center'}}>+10</p>
                <img style={{height:20,width:20,marginLeft:1,marginRight:-2}} src={silver2Img}/>
              </div>
            </Link>
          
          </div>
        </div>
      </div>

      {/* <p  style={{color:'#fff',textAlign:'center',fontSize:35,fontWeight:600,marginTop:-5,marginBottom:8}}>COMING SOON</p> */}


    </div>
    <div className={'visible bottom-btn' } style={{position:'fixed',bottom:90}}>
      <div   className="card-referal centered-elt"  style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <div className="touchable invite-btgn" onClick={handleConnect} id="shareButton"  fill='solid'  style={{borderRadius:7,padding:5,flex:4,marginRight:10,width:'100%',backgroundColor:'#4285F4',border:'solid 0px #BBB9CC',marginLeft:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
          <p  style={{color:'#fff',textAlign:'center',fontSize:16,fontWeight:'500',marginTop:8,marginBottom:8}}>Connect your wallet</p>
        </div>
      </div>   
    </div>
    {/* {hasConnect ?
    <>


      <div className={'visible bottom-btn' } style={{position:'fixed',bottom:80}}>
      <div   className="card-referal centered-elt"  style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <div className="touchable invite-btgn" onClick={handleConnect} id="shareButton"  fill='solid'  style={{borderRadius:7,padding:5,flex:4,marginRight:10,width:'100%',backgroundColor:'#4285F4',border:'solid 0px #BBB9CC',marginLeft:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
          <p  style={{color:'#fff',textAlign:'center',fontSize:16,fontWeight:'500',marginTop:8,marginBottom:8}}>Connect your wallet</p>
        </div>
      </div>   
    </div>
    </>
      :
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
      <img style={{width:60,height:60,justifyContent:'center'}} src='https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720447197/iPhone_14_15_Pro_-_28_2_lkmxpc.png'/>

      </div>
      } */}
      </div>

      </div>
      <Footer/>


    </div>
  );
}
export default Friends;
