import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';
import sha256 from 'js-sha256';

const timePeriods = {
  '30m': 1800000,
  '1h': 3600000,
  '6h': 21600000,
  '1d': 86400000,
  '1w': 604800000
};


const fBTC_histo = [
    {
        "date": "2011-09-19",
        "price": 4.9,
        "significance": 0.7799999999999994
    },
    {
        "date": "2011-09-25",
        "price": 6.05,
        "significance": 1.62
    },
    {
        "date": "2011-10-10",
        "price": 4.51,
        "significance": 1.0299999999999998
    },
    {
        "date": "2011-10-28",
        "price": 4.2,
        "significance": 1.7600000000000002
    },
    {
        "date": "2011-11-14",
        "price": 2.47,
        "significance": 0.9099999999999997
    },
    {
        "date": "2011-11-24",
        "price": 3,
        "significance": 0.6600000000000001
    },
    {
        "date": "2011-12-13",
        "price": 3.56,
        "significance": 0.16999999999999993
    },
    {
        "date": "2011-12-20",
        "price": 4.55,
        "significance": 0.8699999999999997
    },
    {
        "date": "2012-01-05",
        "price": 6.65,
        "significance": 1.9300000000000006
    },
    {
        "date": "2012-01-17",
        "price": 6,
        "significance": 2.1999999999999993
    },
    {
        "date": "2012-01-31",
        "price": 5.3,
        "significance": 0.8600000000000003
    },
    {
        "date": "2012-02-13",
        "price": 5.79,
        "significance": 1.0200000000000005
    },
    {
        "date": "2012-03-05",
        "price": 5.05,
        "significance": 0.3099999999999996
    },
    {
        "date": "2012-03-17",
        "price": 5.42,
        "significance": 0.33000000000000007
    },
    {
        "date": "2012-03-25",
        "price": 4.54,
        "significance": 0.4800000000000004
    },
    {
        "date": "2012-04-18",
        "price": 5.16,
        "significance": 0.20999999999999996
    },
    {
        "date": "2012-04-23",
        "price": 5,
        "significance": 0.25
    },
    {
        "date": "2012-05-10",
        "price": 4.87,
        "significance": 0.22999999999999954
    },
    {
        "date": "2012-05-22",
        "price": 5.03,
        "significance": 0.11999999999999922
    },
    {
        "date": "2012-06-08",
        "price": 5.61,
        "significance": 0.20999999999999996
    },
    {
        "date": "2012-06-18",
        "price": 6.06,
        "significance": 0.5300000000000011
    },
    {
        "date": "2012-07-08",
        "price": 6.73,
        "significance": 0.2699999999999996
    },
    {
        "date": "2012-07-18",
        "price": 9,
        "significance": 0.6899999999999995
    },
    {
        "date": "2012-08-03",
        "price": 10.79,
        "significance": 0.629999999999999
    },
    {
        "date": "2012-08-19",
        "price": 8.06,
        "significance": 5.469999999999999
    },
    {
        "date": "2012-09-04",
        "price": 10.36,
        "significance": 0.8200000000000021
    },
    {
        "date": "2012-09-19",
        "price": 12.32,
        "significance": 0.45000000000000107
    },
    {
        "date": "2012-09-25",
        "price": 11.75,
        "significance": 0.5999999999999996
    },
    {
        "date": "2012-10-08",
        "price": 11.62,
        "significance": 0.46000000000000263
    },
    {
        "date": "2012-10-26",
        "price": 10,
        "significance": 0.9800000000000004
    },
    {
        "date": "2012-11-05",
        "price": 10.78,
        "significance": 0.17999999999999794
    },
    {
        "date": "2012-11-27",
        "price": 12.07,
        "significance": 0.27999999999999936
    },
    {
        "date": "2012-12-04",
        "price": 12.59,
        "significance": 0.6199999999999992
    },
    {
        "date": "2012-12-18",
        "price": 13.13,
        "significance": 0.3399999999999981
    },
    {
        "date": "2013-01-02",
        "price": 13.09,
        "significance": 0.23000000000000043
    },
    {
        "date": "2013-01-13",
        "price": 13.64,
        "significance": 0.7099999999999991
    },
    {
        "date": "2013-01-31",
        "price": 20.46,
        "significance": 1.120000000000001
    },
    {
        "date": "2013-02-13",
        "price": 25,
        "significance": 2.8699999999999974
    },
    {
        "date": "2013-03-06",
        "price": 42.32,
        "significance": 2.210000000000001
    },
    {
        "date": "2013-03-11",
        "price": 48.25,
        "significance": 6.369999999999997
    },
    {
        "date": "2013-03-27",
        "price": 89.3,
        "significance": 10.269999999999996
    },
    {
        "date": "2013-04-09",
        "price": 229,
        "significance": 110.4
    },
    {
        "date": "2013-04-24",
        "price": 149,
        "significance": 18.810000000000002
    },
    {
        "date": "2013-05-14",
        "price": 107,
        "significance": 10.939999999999998
    },
    {
        "date": "2013-05-29",
        "price": 130.35,
        "significance": 7.969999999999985
    },
    {
        "date": "2013-06-09",
        "price": 100.17,
        "significance": 12.989999999999995
    },
    {
        "date": "2013-06-26",
        "price": 98.77,
        "significance": 3.6400000000000006
    },
    {
        "date": "2013-07-04",
        "price": 79.4,
        "significance": 14.86
    },
    {
        "date": "2013-07-18",
        "price": 85.28,
        "significance": 6.920000000000002
    },
    {
        "date": "2013-07-31",
        "price": 98.28,
        "significance": 3.3700000000000045
    },
    {
        "date": "2013-08-21",
        "price": 110.43,
        "significance": 6.8700000000000045
    },
    {
        "date": "2013-08-26",
        "price": 111.88,
        "significance": 7.1000000000000085
    },
    {
        "date": "2013-09-11",
        "price": 127.55,
        "significance": 6.650000000000006
    },
    {
        "date": "2013-10-02",
        "price": 103.85,
        "significance": 37.24000000000001
    },
    {
        "date": "2013-10-15",
        "price": 142.76,
        "significance": 12.539999999999992
    },
    {
        "date": "2013-10-23",
        "price": 203.96,
        "significance": 24.30000000000001
    },
    {
        "date": "2013-11-10",
        "price": 323,
        "significance": 39.150000000000034
    },
    {
        "date": "2013-11-18",
        "price": 669.01,
        "significance": 324.63
    },
    {
        "date": "2013-12-01",
        "price": 955,
        "significance": 256.52
    },
    {
        "date": "2013-12-18",
        "price": 519.86,
        "significance": 318.9
    },
    {
        "date": "2014-01-07",
        "price": 785,
        "significance": 172.69000000000005
    },
    {
        "date": "2014-01-15",
        "price": 841.14,
        "significance": 51.889999999999986
    },
    {
        "date": "2014-01-27",
        "price": 751.95,
        "significance": 98.84999999999991
    },
    {
        "date": "2014-02-13",
        "price": 604.11,
        "significance": 112.76999999999998
    },
    {
        "date": "2014-03-03",
        "price": 677.69,
        "significance": 116.1400000000001
    },
    {
        "date": "2014-03-09",
        "price": 639.25,
        "significance": 29.110000000000014
    },
    {
        "date": "2014-03-27",
        "price": 475,
        "significance": 133.5
    },
    {
        "date": "2014-04-10",
        "price": 363.12,
        "significance": 138.45
    },
    {
        "date": "2014-04-24",
        "price": 502.2,
        "significance": 45.879999999999995
    },
    {
        "date": "2014-05-10",
        "price": 455.39,
        "significance": 18.409999999999968
    },
    {
        "date": "2014-05-22",
        "price": 529.99,
        "significance": 42.23000000000002
    },
    {
        "date": "2014-06-04",
        "price": 640.59,
        "significance": 50.43999999999994
    },
    {
        "date": "2014-06-25",
        "price": 560.4,
        "significance": 31.81000000000006
    },
    {
        "date": "2014-06-30",
        "price": 641.11,
        "significance": 44.08000000000004
    },
    {
        "date": "2014-07-23",
        "price": 621,
        "significance": 22.08000000000004
    },
    {
        "date": "2014-07-30",
        "price": 563.84,
        "significance": 40.309999999999945
    },
    {
        "date": "2014-08-16",
        "price": 521.97,
        "significance": 46.83000000000004
    },
    {
        "date": "2014-08-24",
        "price": 507.85,
        "significance": 14.880000000000052
    },
    {
        "date": "2014-09-08",
        "price": 468.33,
        "significance": 11.970000000000027
    },
    {
        "date": "2014-09-23",
        "price": 438.97,
        "significance": 54.190000000000055
    },
    {
        "date": "2014-10-14",
        "price": 403.38,
        "significance": 19.639999999999986
    },
    {
        "date": "2014-10-29",
        "price": 335.7,
        "significance": 31.69999999999999
    },
    {
        "date": "2014-11-12",
        "price": 426.63,
        "significance": 61.68000000000001
    },
    {
        "date": "2014-11-19",
        "price": 379.03,
        "significance": 26.709999999999923
    },
    {
        "date": "2014-12-07",
        "price": 377.26,
        "significance": 18.839999999999975
    },
    {
        "date": "2014-12-20",
        "price": 331.79,
        "significance": 25.240000000000066
    },
    {
        "date": "2015-01-02",
        "price": 315.42,
        "significance": 35.03000000000003
    },
    {
        "date": "2015-01-14",
        "price": 171.41,
        "significance": 95.16
    },
    {
        "date": "2015-01-26",
        "price": 274.8,
        "significance": 30.310000000000002
    },
    {
        "date": "2015-02-14",
        "price": 256.99,
        "significance": 41.35000000000002
    },
    {
        "date": "2015-02-26",
        "price": 236.84,
        "significance": 17.069999999999993
    },
    {
        "date": "2015-03-18",
        "price": 256.45,
        "significance": 31.710000000000036
    },
    {
        "date": "2015-03-24",
        "price": 245,
        "significance": 22.879999999999995
    },
    {
        "date": "2015-04-14",
        "price": 215.8,
        "significance": 14.429999999999978
    },
    {
        "date": "2015-04-26",
        "price": 219.52,
        "significance": 14.309999999999974
    },
    {
        "date": "2015-05-06",
        "price": 228.49,
        "significance": 15.009999999999991
    },
    {
        "date": "2015-05-22",
        "price": 238.99,
        "significance": 5.220000000000027
    },
    {
        "date": "2015-06-07",
        "price": 222.7,
        "significance": 7.760000000000019
    },
    {
        "date": "2015-06-16",
        "price": 250.09,
        "significance": 16.50000000000003
    },
    {
        "date": "2015-07-05",
        "price": 271.5,
        "significance": 14.29000000000002
    },
    {
        "date": "2015-07-12",
        "price": 310.55,
        "significance": 36.78000000000003
    },
    {
        "date": "2015-08-04",
        "price": 285,
        "significance": 8.439999999999998
    },
    {
        "date": "2015-08-18",
        "price": 224.8,
        "significance": 33.78999999999999
    },
    {
        "date": "2015-08-24",
        "price": 209.72,
        "significance": 31.829999999999984
    },
    {
        "date": "2015-09-08",
        "price": 244.21,
        "significance": 9.880000000000024
    },
    {
        "date": "2015-09-28",
        "price": 239.14,
        "significance": 8.929999999999978
    },
    {
        "date": "2015-10-06",
        "price": 246.57,
        "significance": 9.099999999999994
    },
    {
        "date": "2015-10-18",
        "price": 261.05,
        "significance": 11.629999999999995
    },
    {
        "date": "2015-11-11",
        "price": 309.91,
        "significance": 54.14999999999998
    },
    {
        "date": "2015-11-15",
        "price": 319.28,
        "significance": 25.27000000000004
    },
    {
        "date": "2015-12-08",
        "price": 417.89,
        "significance": 23.21999999999997
    },
    {
        "date": "2015-12-15",
        "price": 464.53,
        "significance": 29.829999999999927
    },
    {
        "date": "2016-01-06",
        "price": 429.56,
        "significance": 30.310000000000002
    },
    {
        "date": "2016-01-15",
        "price": 360,
        "significance": 96.46000000000004
    },
    {
        "date": "2016-01-24",
        "price": 402.16,
        "significance": 27.020000000000095
    },
    {
        "date": "2016-02-14",
        "price": 405,
        "significance": 19.730000000000018
    },
    {
        "date": "2016-02-23",
        "price": 419.1,
        "significance": 23.84999999999991
    },
    {
        "date": "2016-03-11",
        "price": 419.13,
        "significance": 11.360000000000014
    },
    {
        "date": "2016-03-27",
        "price": 424.77,
        "significance": 9.70999999999998
    },
    {
        "date": "2016-04-05",
        "price": 422.71,
        "significance": 5.639999999999986
    },
    {
        "date": "2016-04-26",
        "price": 467.42,
        "significance": 27.130000000000052
    },
    {
        "date": "2016-05-02",
        "price": 444.06,
        "significance": 15.490000000000009
    },
    {
        "date": "2016-05-19",
        "price": 436.3,
        "significance": 23.04000000000002
    },
    {
        "date": "2016-06-06",
        "price": 584.5,
        "significance": 17.550000000000068
    },
    {
        "date": "2016-06-16",
        "price": 766.62,
        "significance": 93.01999999999998
    },
    {
        "date": "2016-07-02",
        "price": 702.48,
        "significance": 71.83000000000004
    },
    {
        "date": "2016-07-17",
        "price": 679.54,
        "significance": 24.52999999999986
    },
    {
        "date": "2016-08-02",
        "price": 540,
        "significance": 89.76999999999998
    },
    {
        "date": "2016-08-09",
        "price": 582.75,
        "significance": 16.649999999999977
    },
    {
        "date": "2016-08-27",
        "price": 568.31,
        "significance": 15.360000000000127
    },
    {
        "date": "2016-09-11",
        "price": 606.32,
        "significance": 17.04999999999984
    },
    {
        "date": "2016-09-26",
        "price": 606.47,
        "significance": 9.700000000000045
    },
    {
        "date": "2016-10-11",
        "price": 639,
        "significance": 27.5
    },
    {
        "date": "2016-10-22",
        "price": 654.52,
        "significance": 31.299999999999955
    },
    {
        "date": "2016-11-03",
        "price": 685.22,
        "significance": 74.25999999999999
    },
    {
        "date": "2016-11-20",
        "price": 727.09,
        "significance": 33.32999999999993
    },
    {
        "date": "2016-12-02",
        "price": 769.99,
        "significance": 27.690000000000055
    },
    {
        "date": "2016-12-11",
        "price": 768.97,
        "significance": 11.239999999999895
    },
    {
        "date": "2017-01-04",
        "price": 1114.92,
        "significance": 189.86000000000013
    },
    {
        "date": "2017-01-11",
        "price": 778.62,
        "significance": 155.27999999999997
    },
    {
        "date": "2017-01-24",
        "price": 885.47,
        "significance": 36.00999999999988
    },
    {
        "date": "2017-02-09",
        "price": 986,
        "significance": 74.48000000000013
    },
    {
        "date": "2017-02-23",
        "price": 1188.11,
        "significance": 66.06999999999971
    },
    {
        "date": "2017-03-10",
        "price": 1116.97,
        "significance": 133.5
    },
    {
        "date": "2017-03-21",
        "price": 1114.39,
        "significance": 155.26000000000022
    },
    {
        "date": "2017-04-02",
        "price": 1078.01,
        "significance": 72.69000000000005
    },
    {
        "date": "2017-04-16",
        "price": 1162.31,
        "significance": 25.37000000000012
    },
    {
        "date": "2017-05-07",
        "price": 1554.01,
        "significance": 99.53999999999996
    },
    {
        "date": "2017-05-24",
        "price": 2420.29,
        "significance": 283.8199999999997
    },
    {
        "date": "2017-06-06",
        "price": 2880.74,
        "significance": 380.44999999999936
    },
    {
        "date": "2017-06-11",
        "price": 2954.22,
        "significance": 341.3899999999999
    },
    {
        "date": "2017-06-26",
        "price": 2421.22,
        "significance": 224.5900000000006
    },
    {
        "date": "2017-07-19",
        "price": 2265.21,
        "significance": 648.3200000000002
    },
    {
        "date": "2017-07-31",
        "price": 2855.81,
        "significance": 238.25
    },
    {
        "date": "2017-08-14",
        "price": 4306.23,
        "significance": 402.91999999999916
    },
    {
        "date": "2017-08-29",
        "price": 4578.82,
        "significance": 204.44999999999982
    },
    {
        "date": "2017-09-04",
        "price": 4277,
        "significance": 448.78000000000065
    },
    {
        "date": "2017-09-18",
        "price": 4101.6,
        "significance": 651.4100000000008
    },
    {
        "date": "2017-10-09",
        "price": 4764.7,
        "significance": 182.1300000000001
    },
    {
        "date": "2017-10-24",
        "price": 5523.4,
        "significance": 560.2500000000009
    },
    {
        "date": "2017-11-08",
        "price": 7450.32,
        "significance": 622.3599999999997
    },
    {
        "date": "2017-11-12",
        "price": 5870.37,
        "significance": 1139.5600000000004
    },
    {
        "date": "2017-11-28",
        "price": 9868.82,
        "significance": 204.88999999999942
    },
    {
        "date": "2017-12-16",
        "price": 19187.78,
        "significance": 1951.0599999999977
    },
    {
        "date": "2017-12-30",
        "price": 12640,
        "significance": 2940
    },
    {
        "date": "2018-01-11",
        "price": 13243.83,
        "significance": 2183.7700000000004
    },
    {
        "date": "2018-01-30",
        "price": 9971,
        "significance": 1369.6200000000008
    },
    {
        "date": "2018-02-05",
        "price": 6874.27,
        "significance": 2179.829999999999
    },
    {
        "date": "2018-02-18",
        "price": 10421.06,
        "significance": 1431.880000000001
    },
    {
        "date": "2018-03-10",
        "price": 8791.47,
        "significance": 1182.1000000000022
    },
    {
        "date": "2018-03-28",
        "price": 7932.41,
        "significance": 984.7399999999998
    },
    {
        "date": "2018-04-03",
        "price": 7416.27,
        "significance": 979.8300000000008
    },
    {
        "date": "2018-04-24",
        "price": 9648,
        "significance": 1484.0599999999995
    },
    {
        "date": "2018-05-03",
        "price": 9732.43,
        "significance": 546.2900000000009
    },
    {
        "date": "2018-05-23",
        "price": 7502.89,
        "significance": 565.1799999999994
    },
    {
        "date": "2018-05-28",
        "price": 7112.5,
        "significance": 591.8400000000001
    },
    {
        "date": "2018-06-10",
        "price": 6781.17,
        "significance": 810.9699999999993
    },
    {
        "date": "2018-06-28",
        "price": 5848.33,
        "significance": 643.2200000000003
    },
    {
        "date": "2018-07-10",
        "price": 6299.46,
        "significance": 447.8299999999999
    },
    {
        "date": "2018-07-24",
        "price": 8403.83,
        "significance": 913.9799999999996
    },
    {
        "date": "2018-08-08",
        "price": 6283.59,
        "significance": 694.2600000000002
    },
    {
        "date": "2018-08-20",
        "price": 6260.82,
        "significance": 439.6200000000008
    },
    {
        "date": "2018-09-04",
        "price": 7361,
        "significance": 783.71
    },
    {
        "date": "2018-09-17",
        "price": 6254.52,
        "significance": 323.27999999999884
    },
    {
        "date": "2018-10-03",
        "price": 6456.77,
        "significance": 134.01999999999953
    },
    {
        "date": "2018-10-14",
        "price": 6183,
        "significance": 275.4299999999994
    },
    {
        "date": "2018-11-07",
        "price": 6501,
        "significance": 149.01000000000022
    },
    {
        "date": "2018-11-18",
        "price": 5559.26,
        "significance": 879.7200000000012
    },
    {
        "date": "2018-11-30",
        "price": 3971.06,
        "significance": 448.0700000000006
    },
    {
        "date": "2018-12-09",
        "price": 3529.75,
        "significance": 252.69999999999982
    },
    {
        "date": "2018-12-27",
        "price": 3591.69,
        "significance": 511.5999999999999
    },
    {
        "date": "2019-01-09",
        "price": 4002.13,
        "significance": 381.8499999999999
    },
    {
        "date": "2019-01-29",
        "price": 3390.5,
        "significance": 85.84999999999991
    },
    {
        "date": "2019-02-07",
        "price": 3359.33,
        "significance": 268.9000000000001
    },
    {
        "date": "2019-02-23",
        "price": 4113.53,
        "significance": 556.5599999999995
    },
    {
        "date": "2019-03-04",
        "price": 3698.66,
        "significance": 236.27000000000044
    },
    {
        "date": "2019-03-27",
        "price": 4025.5,
        "significance": 116.19000000000005
    },
    {
        "date": "2019-04-10",
        "price": 5323.47,
        "significance": 414.1400000000003
    },
    {
        "date": "2019-04-15",
        "price": 5027.31,
        "significance": 310.52999999999975
    },
    {
        "date": "2019-05-04",
        "price": 5764.65,
        "significance": 161.7599999999993
    },
    {
        "date": "2019-05-19",
        "price": 8200,
        "significance": 1134.2399999999998
    },
    {
        "date": "2019-06-02",
        "price": 8734.49,
        "significance": 773.909999999999
    },
    {
        "date": "2019-06-09",
        "price": 7632.99,
        "significance": 687.3900000000012
    },
    {
        "date": "2019-06-26",
        "price": 12920.54,
        "significance": 3207.0200000000023
    },
    {
        "date": "2019-07-15",
        "price": 10850,
        "significance": 2095.3199999999997
    },
    {
        "date": "2019-07-27",
        "price": 9481.37,
        "significance": 417.3899999999994
    },
    {
        "date": "2019-08-05",
        "price": 11800,
        "significance": 1143.0400000000009
    },
    {
        "date": "2019-08-19",
        "price": 10927.3,
        "significance": 763.3899999999994
    },
    {
        "date": "2019-09-06",
        "price": 10311.09,
        "significance": 435.869999999999
    },
    {
        "date": "2019-09-22",
        "price": 10020.58,
        "significance": 373.6899999999987
    },
    {
        "date": "2019-10-06",
        "price": 7854.12,
        "significance": 642.460000000001
    },
    {
        "date": "2019-10-20",
        "price": 8235.74,
        "significance": 297.34000000000015
    },
    {
        "date": "2019-10-27",
        "price": 9557.08,
        "significance": 644.9699999999993
    },
    {
        "date": "2019-11-10",
        "price": 9024.49,
        "significance": 516.4099999999999
    },
    {
        "date": "2019-11-24",
        "price": 6908.36,
        "significance": 625.8100000000013
    },
    {
        "date": "2019-12-17",
        "price": 6612.3,
        "significance": 944.4999999999991
    },
    {
        "date": "2019-12-22",
        "price": 7509.7,
        "significance": 555.8299999999999
    },
    {
        "date": "2020-01-13",
        "price": 8105.01,
        "significance": 783.7800000000007
    },
    {
        "date": "2020-01-28",
        "price": 9400,
        "significance": 616.2799999999988
    },
    {
        "date": "2020-02-10",
        "price": 9850.01,
        "significance": 742.9299999999985
    },
    {
        "date": "2020-02-18",
        "price": 10185.17,
        "significance": 1077.4700000000012
    },
    {
        "date": "2020-03-11",
        "price": 7934.57,
        "significance": 3141.5499999999993
    },
    {
        "date": "2020-03-22",
        "price": 5813.15,
        "significance": 1054.9900000000007
    },
    {
        "date": "2020-03-29",
        "price": 5870.9,
        "significance": 919.0500000000011
    },
    {
        "date": "2020-04-15",
        "price": 6619,
        "significance": 753.4200000000001
    },
    {
        "date": "2020-04-29",
        "price": 8790.32,
        "significance": 1185.5299999999997
    },
    {
        "date": "2020-05-14",
        "price": 9793.5,
        "significance": 965.2900000000009
    },
    {
        "date": "2020-06-01",
        "price": 10203.64,
        "significance": 1435.3599999999988
    },
    {
        "date": "2020-06-11",
        "price": 9264.26,
        "significance": 828.869999999999
    },
    {
        "date": "2020-06-21",
        "price": 9283.03,
        "significance": 472.5199999999986
    },
    {
        "date": "2020-07-08",
        "price": 9437.16,
        "significance": 382.2600000000002
    },
    {
        "date": "2020-07-27",
        "price": 11046.19,
        "significance": 1208.460000000001
    },
    {
        "date": "2020-08-02",
        "price": 11071.66,
        "significance": 910.5599999999995
    },
    {
        "date": "2020-08-17",
        "price": 12298.38,
        "significance": 724.6399999999976
    },
    {
        "date": "2020-09-03",
        "price": 10160,
        "significance": 1245.630000000001
    },
    {
        "date": "2020-09-23",
        "price": 10233.42,
        "significance": 811.4899999999998
    },
    {
        "date": "2020-10-05",
        "price": 10795,
        "significance": 314.0400000000009
    },
    {
        "date": "2020-10-15",
        "price": 11511.11,
        "significance": 271.52000000000044
    },
    {
        "date": "2020-10-27",
        "price": 13647.9,
        "significance": 954.3400000000001
    },
    {
        "date": "2020-11-15",
        "price": 15975.49,
        "significance": 859.9799999999996
    },
    {
        "date": "2020-11-30",
        "price": 19700.19,
        "significance": 2436.9199999999983
    },
    {
        "date": "2020-12-08",
        "price": 18316.43,
        "significance": 1097.2799999999988
    },
    {
        "date": "2020-12-26",
        "price": 26466.28,
        "significance": 1967.8099999999977
    },
    {
        "date": "2021-01-12",
        "price": 34035.02,
        "significance": 4779.030000000006
    },
    {
        "date": "2021-01-21",
        "price": 30818.18,
        "significance": 6852.610000000001
    },
    {
        "date": "2021-02-07",
        "price": 38858.39,
        "significance": 7981.769999999997
    },
    {
        "date": "2021-02-23",
        "price": 48887.93,
        "significance": 6151.790000000001
    },
    {
        "date": "2021-03-01",
        "price": 49629.33,
        "significance": 5501.350000000006
    },
    {
        "date": "2021-03-15",
        "price": 55644.37,
        "significance": 4635.989999999991
    },
    {
        "date": "2021-04-07",
        "price": 55932.83,
        "significance": 4249.239999999998
    },
    {
        "date": "2021-04-12",
        "price": 59831.66,
        "significance": 3880.4199999999983
    },
    {
        "date": "2021-05-04",
        "price": 53236.84,
        "significance": 8258.62000000001
    },
    {
        "date": "2021-05-19",
        "price": 36780.43,
        "significance": 9957.79
    },
    {
        "date": "2021-05-23",
        "price": 34706.79,
        "significance": 6926.82
    },
    {
        "date": "2021-06-12",
        "price": 35542.76,
        "significance": 5269.3499999999985
    },
    {
        "date": "2021-06-21",
        "price": 31634.16,
        "significance": 4866.7099999999955
    },
    {
        "date": "2021-07-04",
        "price": 35289.21,
        "significance": 2185.909999999996
    },
    {
        "date": "2021-07-20",
        "price": 29786.58,
        "significance": 3394.579999999998
    },
    {
        "date": "2021-08-03",
        "price": 38177.07,
        "significance": 3225.980000000003
    },
    {
        "date": "2021-08-25",
        "price": 49011.75,
        "significance": 3435.8799999999974
    },
    {
        "date": "2021-09-06",
        "price": 52708.2,
        "significance": 6740.299999999996
    },
    {
        "date": "2021-09-21",
        "price": 40596.54,
        "significance": 5432.439999999995
    },
    {
        "date": "2021-10-06",
        "price": 55345.79,
        "significance": 5402.790000000001
    },
    {
        "date": "2021-10-20",
        "price": 65990.31,
        "significance": 5519.569999999992
    },
    {
        "date": "2021-10-25",
        "price": 63101.43,
        "significance": 4964.479999999996
    },
    {
        "date": "2021-11-08",
        "price": 67559,
        "significance": 4872.279999999999
    },
    {
        "date": "2021-11-25",
        "price": 58990.14,
        "significance": 7005.870000000003
    },
    {
        "date": "2021-12-13",
        "price": 46725.02,
        "significance": 5064.300000000003
    },
    {
        "date": "2021-12-22",
        "price": 48618.99,
        "significance": 2541.8800000000047
    },
    {
        "date": "2022-01-12",
        "price": 43933.5,
        "significance": 2559.0599999999977
    },
    {
        "date": "2022-01-22",
        "price": 35091.11,
        "significance": 2606.8600000000006
    },
    {
        "date": "2022-02-04",
        "price": 41579.57,
        "significance": 4411.309999999998
    },
    {
        "date": "2022-02-15",
        "price": 44582.48,
        "significance": 2723.1800000000003
    },
    {
        "date": "2022-02-27",
        "price": 37712.68,
        "significance": 6900.280000000006
    },
    {
        "date": "2022-03-13",
        "price": 37790.54,
        "significance": 2906.649999999994
    },
    {
        "date": "2022-04-06",
        "price": 43191.59,
        "significance": 2574.080000000009
    },
    {
        "date": "2022-04-11",
        "price": 39524.86,
        "significance": 3181.5899999999965
    },
    {
        "date": "2022-05-04",
        "price": 39685.23,
        "significance": 5106.580000000009
    },
    {
        "date": "2022-05-09",
        "price": 30082,
        "significance": 4861.470000000001
    },
    {
        "date": "2022-06-01",
        "price": 29787.4,
        "significance": 2625.139999999996
    },
    {
        "date": "2022-06-15",
        "price": 22555.27,
        "significance": 2630.3600000000006
    },
    {
        "date": "2022-06-22",
        "price": 19963.68,
        "significance": 1878.3499999999985
    },
    {
        "date": "2022-07-12",
        "price": 19321.14,
        "significance": 1539.7799999999988
    },
    {
        "date": "2022-07-17",
        "price": 20801.85,
        "significance": 2026.8300000000054
    },
    {
        "date": "2022-08-09",
        "price": 23164.78,
        "significance": 1456.1200000000026
    },
    {
        "date": "2022-08-19",
        "price": 20841,
        "significance": 2665
    },
    {
        "date": "2022-09-06",
        "price": 18789,
        "significance": 1492
    },
    {
        "date": "2022-09-12",
        "price": 22401,
        "significance": 2790
    },
    {
        "date": "2022-10-04",
        "price": 20343,
        "significance": 888
    },
    {
        "date": "2022-10-17",
        "price": 19547,
        "significance": 496
    },
    {
        "date": "2022-10-26",
        "price": 20775,
        "significance": 1167
    },
    {
        "date": "2022-11-09",
        "price": 15877,
        "significance": 4347
    },
    {
        "date": "2022-11-30",
        "price": 17170,
        "significance": 929
    },
    {
        "date": "2022-12-07",
        "price": 16836,
        "significance": 644
    },
    {
        "date": "2022-12-19",
        "price": 16446,
        "significance": 755
    },
    {
        "date": "2023-01-04",
        "price": 16849,
        "significance": 202
    },
    {
        "date": "2023-01-18",
        "price": 20670,
        "significance": 880
    },
    {
        "date": "2023-01-29",
        "price": 23746,
        "significance": 1628
    },
    {
        "date": "2023-02-15",
        "price": 24332,
        "significance": 2932
    },
    {
        "date": "2023-02-28",
        "price": 23136,
        "significance": 855
    },
    {
        "date": "2023-03-17",
        "price": 27466,
        "significance": 2902
    },
    {
        "date": "2023-03-29",
        "price": 28353,
        "significance": 1395
    },
    {
        "date": "2023-04-18",
        "price": 30395,
        "significance": 2515
    },
    {
        "date": "2023-05-01",
        "price": 28079,
        "significance": 1757
    },
    {
        "date": "2023-05-17",
        "price": 27404,
        "significance": 953
    },
    {
        "date": "2023-05-28",
        "price": 28072,
        "significance": 1523
    },
    {
        "date": "2023-06-05",
        "price": 25739,
        "significance": 2883
    },
    {
        "date": "2023-06-21",
        "price": 30009,
        "significance": 1814
    },
    {
        "date": "2023-07-12",
        "price": 30382,
        "significance": 1328
    },
    {
        "date": "2023-07-23",
        "price": 30085,
        "significance": 1204
    },
    {
        "date": "2023-08-01",
        "price": 29696,
        "significance": 997
    },
    {
        "date": "2023-08-23",
        "price": 26431,
        "significance": 652
    },
    {
        "date": "2023-08-29",
        "price": 27726,
        "significance": 2049
    },
    {
        "date": "2023-09-11",
        "price": 25155,
        "significance": 1370
    },
    {
        "date": "2023-10-01",
        "price": 27995,
        "significance": 1517
    },
    {
        "date": "2023-10-16",
        "price": 28518,
        "significance": 1454
    },
    {
        "date": "2023-11-01",
        "price": 35440,
        "significance": 1282
    },
    {
        "date": "2023-11-15",
        "price": 37877,
        "significance": 4025
    },
    {
        "date": "2023-11-21",
        "price": 35758,
        "significance": 3388
    },
    {
        "date": "2023-12-11",
        "price": 41242,
        "significance": 2760
    },
    {
        "date": "2023-12-17",
        "price": 41345,
        "significance": 2206
    },
    {
        "date": "2024-01-08",
        "price": 46995,
        "significance": 3922
    },
    {
        "date": "2024-01-22",
        "price": 39528,
        "significance": 2372
    },
    {
        "date": "2024-01-29",
        "price": 43304,
        "significance": 1638
    },
    {
        "date": "2024-02-13",
        "price": 49720,
        "significance": 2341
    },
    {
        "date": "2024-03-04",
        "price": 68359,
        "significance": 9780
    },
    {
        "date": "2024-03-19",
        "price": 61897,
        "significance": 11691
    },
    {
        "date": "2024-04-02",
        "price": 65475,
        "significance": 4737
    },
    {
        "date": "2024-04-13",
        "price": 64054,
        "significance": 4791
    },
    {
        "date": "2024-04-29",
        "price": 63832,
        "significance": 3936
    },
    {
        "date": "2024-05-14",
        "price": 61543,
        "significance": 6085
    },
    {
        "date": "2024-05-20",
        "price": 71401,
        "significance": 6391
    },
    {
        "date": "2024-06-11",
        "price": 67306,
        "significance": 3132
    },
    {
        "date": "2024-06-24",
        "price": 60252,
        "significance": 4431
    },
    {
        "date": "2024-07-06",
        "price": 58251,
        "significance": 4041
    },
    {
        "date": "2024-07-18",
        "price": 63964,
        "significance": 2868
    }
]

function adjustDates(data, newStartDate) {
    const originalStartDate = new Date(data[0].date);
    const newStartDateObj = new Date(newStartDate);

    return data.map(item => {
        const originalDate = new Date(item.date);
        const timeDifference = originalDate - originalStartDate;
        const newDate = new Date(newStartDateObj.getTime() + timeDifference);

        return {
            ...item,
            date: newDate.toISOString().split('T')[0]
        };
    });
}

const BTC_histo = adjustDates(fBTC_histo, "2013-06-24");

// Exemple d'utilisation
const exampleDate = "2014-06-01";


  function getHashFromDate(date) {
    const hash = sha256(`fixed_string_${date.toISOString()}`);
    return parseInt(hash.slice(0, 8), 16); // Prendre les 8 premiers caractères pour générer un nombre
  }
  
  function getFixedPrice(lowerPrice, upperPrice, date) {
    const fluctuation = Math.sin(getHashFromDate(date) % 1000 / 100) * 0.5 + 0.5;
    const range = upperPrice - lowerPrice;
    const price = lowerPrice + fluctuation * range;
    return price;
  }
  

  
function getRandomPriceForDateOld(date) {
    const inputDate = new Date(date);
  
    let lowerDate = null;
    let lowerPrice = null;
    let upperDate = null;
    let upperPrice = null;
  
    for (let i = 0; i < BTC_histo.length; i++) {
      const currentDate = new Date(BTC_histo[i].date);
      const currentPrice = BTC_histo[i].price;
  
      if (currentDate <= inputDate) {
        if (lowerDate === null || currentDate > lowerDate) {
          lowerDate = currentDate;
          lowerPrice = currentPrice;
        }
      } else {
        if (upperDate === null || currentDate < upperDate) {
          upperDate = currentDate;
          upperPrice = currentPrice;
        }
      }
    }
  
    if (lowerDate === null || upperDate === null) {
      throw new Error("Impossible de trouver des dates inférieure et supérieure pour la date donnée.");
    }
  
    const randomPrice = lowerPrice + Math.random() * (upperPrice - lowerPrice);
    return randomPrice;
  }
  function getRandomPriceForDate(date) {
    const inputDate = new Date(date);
    
    let lowerDate = null;
    let lowerPrice = null;
    let upperDate = null;
    let upperPrice = null;
    
    for (let i = 0; i < BTC_histo.length; i++) {
      const currentDate = new Date(BTC_histo[i].date);
      const currentPrice = BTC_histo[i].price;
    
      if (currentDate <= inputDate) {
        if (lowerDate === null || currentDate > lowerDate) {
          lowerDate = currentDate;
          lowerPrice = currentPrice;
        }
      } else {
        if (upperDate === null || currentDate < upperDate) {
          upperDate = currentDate;
          upperPrice = currentPrice;
        }
      }
    }
    
    if (lowerDate === null || upperDate === null) {
      throw new Error("Impossible de trouver des dates inférieure et supérieure pour la date donnée.");
    }
  
    const price = getFixedPrice(lowerPrice, upperPrice, inputDate);
    
    return price;
  }

  const generateDataOld = (interval) => {
    const now = new Date();
    const startDate = new Date(now - interval);
    const data = [];
    let currentDate = startDate;
  
    while (currentDate <= now) {
      data.push({
        x: format(currentDate, 'yyyy-MM-dd HH:mm:ss'),
        y: getRandomPriceForDate(currentDate),
      });
      currentDate = new Date(currentDate.getTime() + interval / 25); // Adjust the step to get more data points within the interval
    }
    return data;
  };
  const generateData = (interval) => {
    const now = new Date();
    const startDate = new Date(now - interval);
    const data = [];
    let currentDate = startDate;
    let minPrice = Infinity;
    let maxPrice = -Infinity;
  
    while (currentDate <= now) {
      const price = getRandomPriceForDate(currentDate);
      data.push({
        x: format(currentDate, 'yyyy-MM-dd HH:mm:ss'),
        y: price,
      });
      if (price < minPrice) minPrice = price;
      if (price > maxPrice) maxPrice = price;
      currentDate = new Date(currentDate.getTime() + interval / 25); // Adjust the step to get more data points within the interval
    }
  
    return { data, minPrice, maxPrice };
  };
  
  const StockChart = () => {
    const [chartData, setChartData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(timePeriods['1d']);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);

/*     useEffect(() => {
      setChartData(generateData(selectedPeriod));
    }, [selectedPeriod]); */

    useEffect(() => {
        const { data, minPrice, maxPrice } = generateData(selectedPeriod);
        setChartData(data);
        setMinPrice(minPrice);
        setMaxPrice(maxPrice);
      }, [selectedPeriod]);
    
  
    const handlePeriodChange = (selectedOption) => {
      setSelectedPeriod(selectedOption.value);
    };
  
    const data = [
      {
        id: 'Stock Price',
        data: chartData,
      },
    ];
  
    const periodOptions = [
      { value: timePeriods['30m'], label: '30 Minutes' },
      { value: timePeriods['1h'], label: '1 Hour' },
      { value: timePeriods['6h'], label: '6 Hours' },
      { value: timePeriods['1d'], label: '1 Day' },
      { value: timePeriods['1w'], label: '1 Week' },
    ];
    const periodList = [
        { value: timePeriods['30m'], label: '30mn' },
        { value: timePeriods['1h'], label: '1h' },
        { value: timePeriods['6h'], label: '6h' },
        { value: timePeriods['1d'], label: '1d' },
        { value: timePeriods['1w'], label: '1w' },
      ];
  
    return (
      <div style={{ backgroundColor: '#1C1C1E', padding: '20px 0px 0px 0px', borderRadius: '10px' }}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
            {periodList.map((item,index)=>{
                return(
                    <div onClick={()=>{setSelectedPeriod(item.value)}} className='touchable' style={{display:'flex',flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',borderRadius:8,backgroundColor: item.value == selectedPeriod ? '#56D38D':'#333333',width:50,height:39,marginRight:10}}>
                        <p style={{marginTop:3,marginBottom:3,color:item.value == selectedPeriod ?'#333333':'#ffff',textAlign:'center',fontWeight:800}}>{item.label}</p>
                    </div>
                )
            })}
        </div>
        <div style={{ height: '250px', marginTop: '20px' }}>
        <ResponsiveLine
            data={data}
            margin={{ top: 10, right: 50, bottom: 0, left: 10 }}
            xScale={{ type: 'time', format: '%Y-%m-%d %H:%M:%S', precision: 'minute' }}
            xFormat="time:%Y-%m-%d %H:%M:%S"
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            axisTop={null}
            axisLeft={null}
            axisBottom={{
                format: '%b %d',
                tickValues: 'every 2 days',
                legendOffset: -12,
                legendPosition: 'middle',
                tickRotation: -45,
            }}
            enableArea={true}
            areaOpacity={0.2}
            axisRight={{
                tickValues: 4, // Moins de points sur l'axe des ordonnées
                legendOffset: -40,
                legendPosition: 'middle',
            }}
            enablePoints={false}
            enableGridX={false}
            enableGridY={true}
            colors={{ scheme: 'dark2' }}
            useMesh={false}
            legends={[]}
            theme={{
                background: '#1C1C1E',
                textColor: 'white',
                fontSize: 12,
                axis: {
                domain: {
                    line: {
                    stroke: 'white',
                    strokeWidth: 0,
                    },
                },
                ticks: {
                    line: {
                    stroke: '#515658',
                    strokeWidth: 0.5,
                    },
                    text: {
                    fill: '#515658',
                    },
                },
                },
                grid: {
                line: {
                    stroke: '#515658',
                    strokeWidth: 0.5,
                },
                },
            }}
            />
        </div>
      </div>
    );
  };
  
  export default StockChart;