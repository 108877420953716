import { useState, useEffect ,useContext,useRef} from "react";
import "../../App.css";
import Clicker from "../../Components/Clicker/Clicker";
import Footer from "../../Components/Footer/Footer";
import Referal from "../../Components/Referal/Referal";
import Recomp from "../../Components/Recomp/Recomp";
import { Context } from "../../Context/Context";
import { Tabs,Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import HeaderEarn from "../../Components/HeaderEarn/HeaderEarn";
import { Button, Space , Toast} from 'antd-mobile'
import Header from "../../Components/Header/Header";
import soonImg from "../../images/soonImg.png"

const tele = window.Telegram.WebApp;
//const tonConnect = new TonConnect();


function ComingSoon() {
  const {value, value2, value3} = useContext(Context)

  
  return (
    <div style={{display: 'flex', flexDirection: 'column',backgroundColor:'#131313'}}>
      <div className="main-content">
      <div className="headerColor"  style={{ zIndex: 15000 }}>
        <Header />
      </div>
      <div className="restriction-container2">
        <div className="restriction-content2">
          <img src={soonImg} alt="Mobile Icon" className="restriction-icon" />
          <h1 style={{marginTop:-5,color:'#fff'}}>Coming Soon</h1>
          <p style={{margin:3,color:'#fff'}}>This page will be available in October.</p>
        </div>
      </div>


      </div>
      <Footer/>


    </div>
  );
}
export default ComingSoon;
