import React,{ useState, useEffect ,useContext,useRef,useCallback} from "react";
import "../../App.css";
import Clicker from "../../Components/Clicker/Clicker";
import Footer from "../../Components/Footer/Footer";
import Recomp from "../../Components/Recomp/Recomp";
import {get_shop,buy_article,update_pph,hasSponsoredDocuments} from '../../Hooks/firebase_functions'
import { Tabs, Swiper,ProgressBar } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import { Context } from "../../Context/Context";
import CoinCounter from "../../Components/CoinCounter/CoinCounter"
import SheepCounter from "../../Components/SheepCounter/SheepCounter"
import CountdownTimer from "../../Components/CountdownTimer/CountdownTimer";
import { ActionSheet, Button,NoticeBar } from 'antd-mobile'
import createGlobe, { COBEOptions } from "cobe";
import { useSpring } from "react-spring";
import Globe from '../../Components/Globe/Globe'
import {  Dialog, Space, Toast, Divider,Modal } from 'antd-mobile'
import Header from '../../Components/Header/Header'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'
import { ExclamationCircleFill } from 'antd-mobile-icons'
import DateNavigator from "../../Components/DateNavigator/DateNavigator";
import { Link, useNavigate } from 'react-router-dom';
import {pah,capman,pah_levels,capman_levels,dailyfun} from '../../db/db'
import { translate } from "../../Hooks/translate";
import ConversionModule from '../../Components/ConversionModule/ConversionModule'
const tele = window.Telegram.WebApp;

const articles = [
  {
    id:1,
    status:'SOON',
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/visionlongterme_plsghs.png',
    title:'Résilience émotionnelle',
    description:'La capacité de surmonter les adversités, de se relever après des échecs et de maintenir une attitude positive malgré les difficultés.',
    price:10,
  },
  {
    id:2,
    status:'off',
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/equilibre_gffg3q.png',
    title:'Résilience émotionnelle',
    description:'La capacité de surmonter les adversités, de se relever après des échecs et de maintenir une attitude positive malgré les difficultés.',
    price:74.5,
  },
  {
    id:3,
    status:'NEW',
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/autocritique_xvktvt.png',
    title:'Résilience émotionnelle',
    description:'La capacité de surmonter les adversités, de se relever après des échecs et de maintenir une attitude positive malgré les difficultés.',
    price:34.5,
  },
  {
    id:4,
    status:true,
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/resilience_jgqdc5.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
  {
    id:5,
    status:true,
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/clarte-vision_mxfa1m.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
  {
    id:6,
    status:false,
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/leader_euu5wn.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
]
const news = [
  {
    id:1,
    status:'SOON',
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/visionlongterme_plsghs.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
  {
    id:2,
    status:'off',
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721722079/Ajouter_un_titre_82_1_y3xtxe.png',
    title:"Bitcoin collapses after attack on Trump",
    description:'',
    price:74.5,
  },
  {
    id:3,
    status:'NEW',
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/autocritique_xvktvt.png',
    title:'Résilience émotionnelle',
    description:'',
    price:34.5,
  },
  {
    id:4,
    status:true,
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/resilience_jgqdc5.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
  {
    id:5,
    status:true,
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/clarte-vision_mxfa1m.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
  {
    id:6,
    status:false,
    img:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721666610/leader_euu5wn.png',
    title:'Résilience émotionnelle',
    description:'',
    price:10,
  },
]
const flash_sale = [
	{
		id: 1,
		color: "",
		img: "/assets/img/product/1.png",
		new_price: 70,
		old_price: 15,
		discount: 33,
		test: "Sold",
		title: "Learn Engeneery",
	},
	{
		id: 2,
		color: "",
		img: "/assets/img/product/2.png",
		new_price: 14,
		old_price: 21,
		discount: 77,
		test: "Sold",
		title: "Black Table",
	},
	{
		id: 3,
		color: "bg-danger",
		img: "/assets/img/product/3.png",
		new_price: 36,
		old_price: 49,
		discount: 99,
		test: "Sold",
		title: "Black Table",
	},
	// doplicate
	{
		id: 1,
		color: "",
		img: "/assets/img/product/1.png",
		new_price: 70,
		old_price: 15,
		discount: 33,
		test: "Sold",
		title: "Black Table",
	},
	{
		id: 2,
		color: "",
		img: "/assets/img/product/2.png",
		new_price: 14,
		old_price: 21,
		discount: 77,
		test: "Sold",
		title: "Black Table",
	},
	{
		id: 3,
		color: "bg-danger",
		img: "/assets/img/product/3.png",
		new_price: 36,
		old_price: 49,
		discount: 99,
		test: "Sold",
		title: "Black Table",
	},
	// doplicat
	{
		id: 1,
		color: "",
		img: "/assets/img/product/1.png",
		new_price: 7.99,
		old_price: 15,
		discount: 33,
		test: "Sold",
		title: "Black Table Lamp",
	},
	{
		id: 2,
		color: "",
		img: "/assets/img/product/2.png",
		new_price: 14,
		old_price: 21,
		discount: 77,
		test: "Sold",
		title: "Black Table Lamp",
	},
	{
		id: 3,
		color: "bg-danger",
		img: "/assets/img/product/3.png",
		new_price: 36,
		old_price: 49,
		discount: 99,
		test: "Sold",
		title: "Black Table Lamp",
	},
	// doplicat
	{
		id: 1,
		color: "",
		img: "/assets/img/product/1.png",
		new_price: 7.99,
		old_price: 15,
		discount: 33,
		test: "Sold",
		title: "Black Table Lamp",
	},
	{
		id: 2,
		color: "",
		img: "/assets/img/product/2.png",
		new_price: 14,
		old_price: 21,
		discount: 77,
		test: "Sold",
		title: "Black Table Lamp",
	},
	{
		id: 3,
		color: "bg-danger",
		img: "/assets/img/product/3.png",
		new_price: 36,
		old_price: 49,
		discount: 99,
		test: "Sold",
		title: "Black Table Lamp",
	},
];
function Friends() {
  const swiperRef = useRef(null)

  const {value, value2, value3,value11} = useContext(Context)
  const [langContext,setLangContext] = value
  const [userData, setUserData] = value2
  const [chargesData, setChargesData] = value3
  const [userArticles, setUserArticles] = value11;
  const [articlesFetched,setArticlesFetched] = useState(false)
  const [hasInvite, setHasInvite] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleDescription, setVisibleDescription] = useState(false)
  const [popupInfo, setPopupInfo] = useState()

  function roundNumber(num) {
    if (num >= 100) {
        return Math.round(num / 100) * 100;
    } else {
        return Math.round(num / 10) * 10;
    }
  }

  useEffect(() => {
    tele.ready();
 
  });
  useEffect(()=>{
    const fn = async () => {
      const rep = await get_shop(userData.userId)
      const rep2 = await hasSponsoredDocuments(userData.userId)
      setHasInvite(rep2)
      if(rep.length>0){
        setUserArticles(rep[0].articles)
      }else{
        const art = []
        setUserArticles(art)
      }
      
      setArticlesFetched(true)
    }
    fn()
  },[])
  const actions= [
    { text: 'Buy', key: 'copy' },
  ]

  const getMonth = (date) => {
    return date.toLocaleDateString('en-US', { month: 'long' }).slice(0, 4).toUpperCase();
  };
 
  const tabItems = [
    { key: 'token growth', title: 'Token Growth' },
    { key: 'news', title: 'News' },
  ]

  return (
    <div className="scrollable" style={{backgroundColor:'#0F111A'}}>
      <div className="main-content">
      <div>
        <Header/>
      </div>

      {/* <div className="footer-nav-area-tris"  style={{borderRadius:10,marginTop:15,marginLeft:0,marginRight:0}}>
        <div onClick={()=>{}} style={{ display:'flex', flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',backgroundColor:'#1F4936',borderRadius:8,margin:'0px 0px 0px 0px',opacity:0}}>
          <img 
            src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722003766/Bonjour_Je_vous_conseille_de_rejoindre_ce_groupe_Des_dizaines_d_annonces_de_me%CC%81nage_sont_disponibles_par_jour_Domeli_est_une_entreprise_en_partenariat_avec_la_re%CC%81gion_Ile-de-France_httpschat.w_9_1_woihda.png"
            style={{height:25,width:25,marginLeft:6,marginRight:6}}
          />
        </div>
          <div lassName="touchable" onClick={()=>{}} style={{ display:'flex',width:260, flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',backgroundColor:'#03C76B',borderRadius:8,margin:'0px 0px 0px 0px'}}>

            <p style={{margin:'13px 0px 13px 0px',marginLeft:0,color:'#fff',fontSize:15,fontWeight:600}}>Manage</p>
          </div>
          <Link to={"/trade"} className="touchable" style={{ display:'flex', flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',backgroundColor:'#1F4936',borderRadius:8,margin:'0px 0px 0px 0px'}}>
            <img 
              src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722454517/return_ben8dk.png"
              style={{height:25,width:25,marginLeft:6,marginRight:6}}
            />
        </Link>
           
     
      </div> */}

      {/* <div className="footer-nav-area-bis"  style={{borderRadius:10,marginTop:15,marginLeft:0,marginRight:0}}>
        <div className="" style={{marginTop:5,marginBottom:5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:'solid 2px #18212F',borderRadius:8}}>
            
            <div 
              className="touchable"
              onClick={()=>{
                setPageSelected('Token Growth');
                setActiveIndex(0)
                swiperRef.current?.swipeTo(0)
              }} 
              style={{backgroundColor:activeIndex == 1  ?'#0F111A':'#18212F',borderRadius:5,marginLeft:0,marginRight:0,width:130}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:5,marginBottom:5}}>
              <span className={activeIndex !== 1  ? "green-circle": "white-circle"}/>
                <p style={{textAlign:'center',marginTop:0,fontSize:13,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,color: '#fff',marginLeft:10}}>Token Growth</p>
              </div>
            </div>
            <div 
              className="touchable"
              onClick={()=>{
                setPageSelected('News');
                setActiveIndex(1)
                swiperRef.current?.swipeTo(1)
              }} 
              style={{backgroundColor:activeIndex == 0  ?'#0F111A':'#18212F',borderRadius:5,marginLeft:0,marginRight:0,width:130}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:5,marginBottom:5,marginLeft:10}}>
              <span className={activeIndex == 1  ? "green-circle": "white-circle"}/>
               <p style={{textAlign:'center',marginTop:0,fontSize:13,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,marginLeft:22,marginRight:22,color: '#fff',marginLeft:10}}>News</p>
              </div>
            </div>
           
        </div>
      </div> */}
      <ConversionModule/>
      <div className="footer-nav-area-bis"  style={{borderRadius:10,marginTop:15,marginLeft:0,marginRight:0}}>
        <div className="" style={{marginTop:5,marginBottom:5,display:'flex',flexDirection:'row',width:'95%',justifyContent:'space-around',alignItems:'center',border:'solid 0px #18212F',borderRadius:8}}>
            
            <div 
              className="touchable"
              onClick={()=>{
                setActiveIndex(0)
                swiperRef.current?.swipeTo(0)
              }} 
              style={{backgroundColor:activeIndex == 0  ?'#18212F':'#0F111A',border:'solid 1.5px #18212F',borderRadius:7,marginLeft:0,marginRight:3,width:'31%',height:45,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',alignContent:'center'}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',alignContent:'center',marginTop:0,marginBottom:0,maxWidth:'90%'}}>
              <span className={activeIndex == 0 ? "green-circle": "white-circle"} style={{position:'absolute',left:'7px'}}/>
                <p style={{textAlign:'center',marginTop:0,fontSize:12,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,color: '#fff',marginLeft:2,width:'90%'}}>{translate('Health', langContext,'ENGLISH')}</p>
              </div>
            </div>

            <div 
              className="touchable"
              onClick={()=>{
                setActiveIndex(1)
                swiperRef.current?.swipeTo(1)
              }} 
              style={{backgroundColor:activeIndex == 1  ?'#18212F':'#0F111A',border:'solid 1.5px #18212F',borderRadius:7,marginLeft:0,marginRight:3,width:'33%',height:45,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',alignContent:'center'}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:0,marginBottom:0,maxWidth:'90%'}}>
              <span className={activeIndex == 1 ? "green-circle": "white-circle"}  style={{position:'absolute',left:'7px'}}/>
                <p style={{textAlign:'center',marginTop:0,fontSize:12,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,color: '#fff',marginLeft:2,width:'90%'}}>{translate('Capital', langContext,'ENGLISH')}</p>
              </div>
            </div>

            <div 
              className="touchable"
              onClick={()=>{
                setActiveIndex(2)
                swiperRef.current?.swipeTo(2)
              }} 
              style={{backgroundColor:activeIndex == 2  ?'#18212F':'#0F111A',border:'solid 1.5px #18212F',borderRadius:7,marginLeft:0,marginRight:3,width:'31%',height:45,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',alignContent:'center'}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:0,marginBottom:0,maxWidth:'90%'}}>
              <span className={activeIndex == 2 ? "green-circle": "white-circle"} style={{position:'absolute',left:'7px'}}/>
                <p style={{textAlign:'center',marginTop:0,fontSize:12,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,color: '#fff',marginLeft:2,width:'90%'}}>{translate('News', langContext,'ENGLISH')}</p>
              </div>
            </div>
            <div 
              className="touchable"
              onClick={()=>{
                setActiveIndex(3)
                swiperRef.current?.swipeTo(3)
              }} 
              style={{backgroundColor:activeIndex == 3  ?'#18212F':'#0F111A',border:'solid 1.5px #18212F',borderRadius:7,marginLeft:0,marginRight:3,width:'31%',height:45,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',alignContent:'center'}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:0,marginBottom:0,maxWidth:'90%'}}>
              <span className={activeIndex == 3 ? "green-circle": "white-circle"} style={{position:'absolute',left:'7px'}}/>
                <p style={{textAlign:'center',marginTop:0,fontSize:12,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,color: '#fff',marginLeft:2,width:'90%'}}>{translate('Daily Fun', langContext,'ENGLISH')}</p>
              </div>
            </div>
            {/* <div 
              className="touchable"
              onClick={()=>{
                setPageSelected('Capital Management');
                setActiveIndex(1)
                swiperRef.current?.swipeTo(1)
              }} 
              style={{backgroundColor:activeIndex == 0  ?'#0F111A':'#18212F',border:activeIndex == 0 ? 'solid 1.5px #18212F':'solid 1.5px #18212F',borderRadius:5,marginLeft:3,marginRight:3,width:'33%',height:45}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:0,marginBottom:0,paddingLeft:10,paddingRight:5}}>
              <span className={activeIndex == 1  ? "green-circle": "white-circle"}/>
               <p style={{textAlign:'center',marginTop:0,fontSize:11,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,marginLeft:22,marginRight:22,color: '#fff',marginLeft:10}}>Capital Management</p>
              </div>
            </div>
            <div 
              className="touchable"
              onClick={()=>{
                setPageSelected('News');
                setActiveIndex(2)
                swiperRef.current?.swipeTo(2)
              }} 
              style={{backgroundColor:activeIndex == 2  ?'#18212F':'#0F111A',borderRadius:5,marginLeft:0,marginRight:0,width:'33%'}}
            >
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:5,marginBottom:5,marginLeft:10}}>
              <span className={activeIndex == 2  ? "green-circle": "white-circle"}/>
               <p style={{textAlign:'center',marginTop:0,fontSize:13,marginBottom:0,fontWeight:500,paddingTop:7,paddingBottom:7,marginLeft:22,marginRight:22,color: '#fff',marginLeft:10}}>News</p>
              </div>
            </div> */}
           
        </div>
      </div>
      <Modal
        visible={visibleDescription}
        bodyStyle={{
          border:'solid 1px #062321',
          backgroundColor: "#060B18",
          borderRadius:15
        }}
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: -10, marginLeft: 10, marginRight: 10 }}>
              <img style={{ width: 100, height: 100 }} src={popupInfo && popupInfo.image ?popupInfo.image  : 'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png'} />
              <span style={{ color: "#fff", fontSize: 17,marginTop:5, textAlign: 'center', fontWeight: 600, alignContent: "center" }}>
                {popupInfo ? popupInfo.name : ''}
              </span>
              <span style={{ color: "#fff", fontSize: 13,marginTop:10, textAlign: 'center', fontWeight: 500, alignContent: "center",marginLeft:-10,marginRight:-10 }}>
                {popupInfo ? popupInfo.description : ''}
              </span>

            <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#202527',marginTop:10,borderRadius:7,padding:'3px 7px 3px 7px',marginRight:0}}>
                <span style={{ color: "#fff", fontSize: 12, fontWeight: 600 }}>
                    Price :
                </span>
                <img style={{ width: 13, height: 13, marginLeft:3 }} src={'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png'} />
                <span style={{ color: "#fff", fontSize: 12, fontWeight: 600, marginLeft:3 }}>
                    {popupInfo ? roundNumber(popupInfo.price) : ''}
                </span>
              </div>
              </div>

              <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>

              <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#202527',marginTop:10,borderRadius:7,padding:'3px 7px 3px 7px',marginLeft:0}}>
                <span style={{ color: "#fff", fontSize: 12, fontWeight: 600 }}>
                    Profit Per Day :
                </span>
                <img style={{ width: 13, height: 13, marginLeft:3 }} src={'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png'} />
                <span style={{ color: "#fff", fontSize: 12, fontWeight: 600, marginLeft:3 }}>
                    {popupInfo ? '+'+roundNumber(popupInfo.ppd) : ''}
                </span>
              </div>
             </div>
              
            </div>
          </>
        }
        closeOnAction
        onClose={()=>{setVisibleDescription(false)}}
        showCloseButton
        actions={[
          {
            key: 'confirm',
            text: 'Upgrade',
            style: {
              backgroundColor:popupInfo && userData.balance >=  roundNumber(popupInfo.price) ?"#57D48D" : "#1E3E39", borderRadius: 8, paddingTop: 8, paddingBottom: 8, color:popupInfo && userData.balance >=  popupInfo.price? '#fff' : '#A6A9AD',
              paddingLeft:20,
              paddingRight:20,fontWeight:800,fontSize:16,
              boxShadow:popupInfo && userData.balance >=  roundNumber(popupInfo.price)?'0 0 5px #37825E, 0 0 7px #37825E':'0 0 0px #37825E, 0 0 0px #37825E'
            },
            onClick: async()=>{
              if(popupInfo && userData.balance >=  roundNumber(popupInfo.price)){
                const oldUserArticles =  userArticles
                const newArticle = {
                  id: popupInfo.id,
                  level: popupInfo.level
                }
                const articleIndex = oldUserArticles.findIndex(article => article.id === newArticle.id);
  
                if (articleIndex !== -1) {
                    // Article exists, increment the level
                    oldUserArticles[articleIndex].level += 1;
                } else {
                    // Article does not exist, add it to the array
                    oldUserArticles.push(newArticle);
                }
                
               
                const buy = await buy_article(newArticle, userData.userId)
               
                const oldUserData = userData
                oldUserData.pph = oldUserData.pph + roundNumber(popupInfo.ppd)
                const updatePph = await update_pph(userData,oldUserData.pph)
                oldUserData.balance = userData.balance - roundNumber(popupInfo.price)
                setUserData(oldUserData)
                setUserArticles(oldUserArticles)

              }
              
            }
          },
        ]}
      />

      {/* <div className={activeIndex == 0 || activeIndex == 1? 'visibleG' : 'hiddenG'}  style={{marginLeft:15,marginRight:15,marginTop:15,height:135,display:'flex',flexDirection:'column'}}>
          <Globe/>
      </div> */}
  {/* <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                <p style={{margin:0,paddingLeft:5,paddingBottom:5,color:'#fff',fontSize:15,fontWeight:600,textAlign:'left'}}>{getMonth(new Date())}</p>
                <DateNavigator/>
              </div> */}
      {/* <div className={activeIndex == 2 ? 'visibleG' : 'hiddenG'} style={{marginLeft:15,marginRight:15,marginTop:2,height:135,display:'flex',flexDirection:'column'}}>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              <p style={{margin:0,color:'#fff',fontSize:24,fontWeight:600}}>Day 1</p>
              <p style={{marginTop:5,color:'#fff',fontSize:16,fontWeight:600,marginBottom:7}}>in Wolfera</p>
            
          </div>
          <NoticeBar    
            style={{backgroundColor:'#191F28',border: 'solid 0px #fff',color:"#000",marginTop:20,paddingTop:5,paddingBottom:5,marginLeft:-20,marginRight:-20}}         
            icon={<></>}
            content={<span style={{color:"#CCCED0",fontWeight:500,fontStyle:'italic'}}>NEWS ALERT : Satowolf Nakamoto created Bitcoiw </span>} color='alert' 
          /> 
      </div> */}
   

      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:10,marginLeft:0}}>
        <div className="touchable" style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
          <CoinCounter/>
        </div>
      </div>

      <Swiper
        style={{border:'solid 0px #1C1C1E'}}
          direction='horizontal'
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={index => {
            setActiveIndex(index)
          }}
        >
          <Swiper.Item style={{border:'solid 0px #1C1C1E',display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-evenly',marginTop:10,marginBottom:150}}>
            {articlesFetched && pah.map((item,index)=>{
              return(
                <div 
                  key={item.id}
                  className={item.status !== 'off' ?"touchable" : "" }
                  onClick={async() => {
                    const itemPopup = item
                    const calc_price = async() => {
                      if(userArticles.filter((elt)=>{if(elt.id == itemPopup.id){return elt}}).length > 0 ){
                        const levelArt = pah_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == itemPopup.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0]
                        return levelArt 
                        
                      }else{
                        const levelArt=pah_levels.filter((art)=>{
                          if(art.card == itemPopup.id && art.level == 1){
                            return art
                          }})[0]
                        return levelArt
                      }
                    }
                    const a = await calc_price()
                    itemPopup.price =  roundNumber(a['price'])
                    itemPopup.ppd = roundNumber(a["profit per day"])
                    itemPopup.level = a['level']
                    setPopupInfo(itemPopup)
                    setVisibleDescription(true)
                  }}
                  style={{backgroundColor:'#0F1724',marginTop:5,marginBottom:5,borderRadius:10,width:"30%",position:'relative',border:'solid 0px #0F1724',minHeight:185}} >
                 
                  <div style={{display:'flex',opacity: item.status == true|| item.status == false || item.status == 'off' || !item.status ? 0 : 1,flexDirection:'row',marginTop:6,alignItems:'center',justifyContent:'flex-start',marginLeft:7}}>
                    <span className={item.status == "NEW" ? "green-circle": "purle-circle"}/>
                    <span style={{color:'#fff',fontSize:12,fontWeight:600,marginLeft:5}}>{item.status}</span>
                  </div>
                  <div style={{display:'flex',marginTop:5,alignContent:'center',justifyContent:'center'}}>
                    <img style={{width:55,height:55}} src={item.image} alt={item.image} /> 
                  </div>
                  <div style={{paddingTop:5,}}>
                    <p style={{color:item.status !== 'off' || !item.status ?"#57D48D" : "#37825E",fontSize:13,fontWeight:400,margin:0,textAlign:'center'}}>{userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 'Level '+ userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0].level : 'Level 0'}</p>
                  </div>
                  <div style={{paddingTop:4,marginLeft:2,marginRight:2}}>
                    <p className="two-lines" style={{color:"#FEFFFE",fontSize:12,fontWeight:700,margin:0,textAlign:'center',}}>{translate(item.name, langContext,'ENGLISH')}</p>
                  </div>
                  <div style={{paddingTop:5,marginLeft:2,marginRight:2}}>
                    <p  style={{color:"#BFC3C9",fontSize:11,fontWeight:400,margin:0,textAlign:'center',}}>{translate("Profit per day", langContext,'ENGLISH')}</p>
                  </div>
                  <div className="touchable" style={{paddingTop:5,paddingBottom:2,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <p style={{color:"#FEFFFE",fontSize:12,fontWeight:700,margin:0,textAlign:'center',}}>
                    +{userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 
                       roundNumber( pah_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0]["profit per day"] )
                      : 
                      roundNumber(pah_levels.filter((art)=>{
                        if(art.card == item.id && art.level == 1){
                          return art
                        }
                      })[0]["profit per day"]) }
                    </p>

                    <img style={{width:12,height:12,marginLeft:3}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>

                  </div>
                  <div className="touchable" style={{paddingTop:5,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',backgroundColor:(userData.balance >= roundNumber(pah_levels.filter((art)=>{
                        if(art.card == item.id && art.level == 1){
                          return art
                        }
                      })[0].price) && userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length == 0) || (userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0  && userData.balance >= roundNumber(pah_levels.filter((art)=>{
                        const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                        if(art.card == a.id && art.level == a.level){
                          return art
                        }
                      })[0].price) ) ?"#57D48D" : "#1E3E39",marginLeft:20,marginRight:20,borderRadius:7,marginTop:5,marginBottom:9}}>
                    <img style={{width:15,height:15,marginLeft:-5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>
                    <span style={{color: "#fff",fontSize:14,fontWeight:700,marginLeft:3}}>
                      {userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 
                        roundNumber(pah_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0].price) 
                      : 
                      roundNumber(pah_levels.filter((art)=>{
                        if(art.card == item.id && art.level == 1){
                          return art
                        }
                      })[0].price) }
                    </span>
                  </div>
                </div>
              )
            })}
          </Swiper.Item>
          <Swiper.Item style={{border:'solid 0px #1C1C1E',display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-evenly',marginTop:10,marginBottom:150}}>

            {hasInvite ?
            <>
              {articlesFetched && capman.map((item,index)=>{
                return(
                  <div 
                    key={item.id}
                    className={item.status !== 'off' ?"touchable" : "" }
                    onClick={async() => {
                      const itemPopup = item
                      const calc_price = async() => {
                        if(userArticles.filter((elt)=>{if(elt.id == itemPopup.id){return elt}}).length > 0 ){
                          const levelArt = capman_levels.filter((art)=>{
                            const a = userArticles.filter((elt)=>{if(elt.id == itemPopup.id){return elt}})[0]
                            if(art.card == a.id && art.level == a.level){
                              return art
                            }
                          })[0]
                          return levelArt 
                          
                        }else{
                          const levelArt=capman_levels.filter((art)=>{
                            if(art.card == itemPopup.id && art.level == 1){
                              return art
                            }})[0]
                          return levelArt
                        }
                      }
                      const a = await calc_price()
                      itemPopup.price =  roundNumber(a['price'])
                      itemPopup.ppd = roundNumber(a["profit per day"])
                      itemPopup.level = a['level']
                      setPopupInfo(itemPopup)
                      setVisibleDescription(true)
                    }}
                    style={{backgroundColor:'#0F1724',marginTop:5,marginBottom:5,borderRadius:10,width:"30%",position:'relative',border:'solid 0px #0F1724',minHeight:185}} >
                  
                    <div style={{display:'flex',opacity: item.status == true|| item.status == false || item.status == 'off' || !item.status ? 0 : 1,flexDirection:'row',marginTop:6,alignItems:'center',justifyContent:'flex-start',marginLeft:7}}>
                      <span className={item.status == "NEW" ? "green-circle": "purle-circle"}/>
                      <span style={{color:'#fff',fontSize:12,fontWeight:600,marginLeft:5}}>{item.status}</span>
                    </div>
                    <div style={{display:'flex',marginTop:5,alignContent:'center',justifyContent:'center'}}>
                      <img style={{width:55,height:55}} src={item.image} alt={item.image} /> 
                    </div>
                    <div style={{paddingTop:5,}}>
                      <p style={{color:item.status !== 'off' || !item.status ?"#57D48D" : "#37825E",fontSize:13,fontWeight:400,margin:0,textAlign:'center'}}>{userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 'Level '+ userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0].level : 'Level 0'}</p>
                    </div>
                    <div style={{paddingTop:4,marginLeft:2,marginRight:2}}>
                      <p className="two-lines" style={{color:"#FEFFFE",fontSize:12,fontWeight:700,margin:0,textAlign:'center',}}>{translate(item.name, "RUSSIAN",'ENGLISH')}</p>
                    </div>
                    <div style={{paddingTop:5,marginLeft:2,marginRight:2}}>
                      <p  style={{color:"#BFC3C9",fontSize:11,fontWeight:400,margin:0,textAlign:'center',}}>Profit per day</p>
                    </div>
                    <div className="touchable" style={{paddingTop:5,paddingBottom:2,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                      <p style={{color:"#FEFFFE",fontSize:12,fontWeight:700,margin:0,textAlign:'center',}}>
                      +{userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 
                          roundNumber(capman_levels.filter((art)=>{
                            const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                            if(art.card == a.id && art.level == a.level){
                              return art
                            }
                          })[0]["profit per day"])
                        : 
                        roundNumber(capman_levels.filter((art)=>{
                          if(art.card == item.id && art.level == 1){
                            return art
                          }
                        })[0]["profit per day"]) }
                      </p>

                      <img style={{width:12,height:12,marginLeft:3}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>

                    </div>
                    
                    <div className="touchable" style={{paddingTop:5,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',backgroundColor:(userData.balance >= roundNumber(capman_levels.filter((art)=>{
                          if(art.card == item.id && art.level == 1){
                            return art
                          }
                        })[0].price) && userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length == 0) || (userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0  && userData.balance >= roundNumber(capman_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0].price) ) ?"#57D48D" : "#1E3E39",marginLeft:20,marginRight:20,borderRadius:7,marginTop:5,marginBottom:9}}>
                      <img style={{width:15,height:15,marginLeft:-5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>
                      <span style={{color: "#fff",fontSize:14,fontWeight:700,marginLeft:3}}>
                        {userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 
                          roundNumber(capman_levels.filter((art)=>{
                            const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                            if(art.card == a.id && art.level == a.level){
                              return art
                            }
                          })[0].price) 
                        : 
                        roundNumber(capman_levels.filter((art)=>{
                          if(art.card == item.id && art.level == 1){
                            return art
                          }
                        })[0].price) }
                      </span>
                    </div>
                  </div>
                )
              })}
            </>
            :
            <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginLeft: 10, marginRight: 10, 
              backgroundColor: '#0F111A',marginTop:50, borderRadius: 8, paddingTop: 8, paddingBottom: 8, color: '#fff',
              paddingLeft:20,
              paddingRight:20,fontWeight:800,fontSize:16,
              boxShadow: '0 0 5px #37825E, 0 0 7px #37825E'
             }}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",alignItems:'center', padding: 0, marginTop: 10,marginBottom:10,marginLeft:5,marginRight:5 }}>
              <img style={{width:55,height:55,display:'flex',flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',alignSelf:'center',marginBottom:10}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722985085/Ajouter_un_titre_157_1_boxfzx.png"/>

                <span className="fontRobot" style={{ color: "#fff", fontSize: 25, fontWeight: 900, alignContent: "center", marginLeft: 0 }}>
                Invite a friend 
                </span>
                <span className="fontRobot" style={{ color: "#fff", fontSize: 25, fontWeight: 900, alignContent: "center", marginLeft: 0 }}>
                to unlock
                </span>
              </div>              
            </div>
            }
          </Swiper.Item>
          <Swiper.Item style={{border:'solid 0px #1C1C1E',display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-evenly',marginTop:10,marginBottom:150}}>
            <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginLeft: 10, marginRight: 10, 
              backgroundColor: '#0F111A',marginTop:50, borderRadius: 8, paddingTop: 8, paddingBottom: 8, color: '#fff',
              paddingLeft:20,
              paddingRight:20,fontWeight:800,fontSize:16,
              boxShadow: '0 0 5px #37825E, 0 0 7px #37825E'
             }}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",alignItems:'center', padding: 0, marginTop: 10,marginBottom:10,marginLeft:5,marginRight:5 }}>
              <img style={{width:55,height:55,display:'flex',flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',alignSelf:'center',marginBottom:10}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722985085/Ajouter_un_titre_157_1_boxfzx.png"/>

                <span className="fontRobot" style={{ color: "#fff", fontSize: 25, fontWeight: 900, alignContent: "center", marginLeft: 0 }}>
                 WolfMaster
                </span>
                <span className="fontRobot" style={{ color: "#fff", fontSize: 25, fontWeight: 900, alignContent: "center", marginLeft: 0 }}>
                Only
                </span>
              </div>              
            </div>
            {/* {news.map((item,index)=>{
              return(
                <div 
                key={item.id}
                  className={item.status !== 'off' ?"touchable" : "" }
                  onClick={() => {
                    setPopupInfo(item)
                    setVisibleDescription(true)
                  }}
                  style={{backgroundColor:index !== 1 ?'#0F1724':'#192330',marginTop:5,marginBottom:5,borderRadius:10,width:"30%",position:'relative',border:'solid 0px #0F1724',minHeight:185}} >
                  <div style={{display:'flex',marginTop:15,alignContent:'center',justifyContent:'center'}}>
                    <img style={{width:75,height:75}} src={index !== 1 ? 'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721722080/Ajouter_un_titre_81_5_rvw1ph.png':item.img} alt={item.title} /> 
                  </div>
                  <div style={{position:'absolute',bottom:0}}>
                    <div style={{paddingTop:12,marginLeft:10,marginRight:10}}>
                      <p style={{color:"#FEFFFE",fontSize:index!==1?13:12,fontWeight:index!==1? 400 : 700,margin:0,textAlign:'center'}}>{item.title}</p>
                    </div>
                    {index!==1?
                    <div className="touchable" style={{paddingTop:5,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',backgroundColor:item.status !== 'off' ?"#57D48D" : "#1E3E39",marginLeft:20,marginRight:20,borderRadius:7,marginTop:9,marginBottom:9}}>
                      <img style={{width:15,height:15,marginLeft:-5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>
                      <span style={{color:item.status !== 'off' ?"#fff" : "#fff",fontSize:14,fontWeight:700,marginLeft:3}}>{item.price.toString()}k</span>
                    </div>
                    :
                    <div className="touchable" style={{paddingTop:5,opacity:0,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',backgroundColor:item.status !== 'off' ?"#57D48D" : "#1E3E39",marginLeft:20,marginRight:20,borderRadius:7,marginTop:9,marginBottom:9}}>
                      <img style={{width:15,height:15,marginLeft:-5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>
                      <span style={{color:item.status !== 'off' ?"#fff" : "#fff",fontSize:14,fontWeight:700,marginLeft:3}}>{item.price.toString()}k</span>
                    </div>
                    }
                  </div>
                </div>
              )
            })} */}
            
            

          </Swiper.Item>
          <Swiper.Item style={{border:'solid 0px #1C1C1E',display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-evenly',marginTop:10,marginBottom:150}}>


            {articlesFetched && dailyfun.map((item,index)=>{
              return(
                <div 
                  key={item.id}
                  className={item.status !== 'off' ?"touchable" : "" }
                 /*  onClick={async() => {
                    const itemPopup = item
                    const calc_price = async() => {
                      if(userArticles.filter((elt)=>{if(elt.id == itemPopup.id){return elt}}).length > 0 ){
                        const levelArt = capman_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == itemPopup.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0]
                        return levelArt 
                        
                      }else{
                        const levelArt=capman_levels.filter((art)=>{
                          if(art.card == itemPopup.id && art.level == 1){
                            return art
                          }})[0]
                        return levelArt
                      }
                    }
                    const a = await calc_price()
                    itemPopup.price =  a['price']
                    itemPopup.ppd = a["profit per day"]
                    itemPopup.level = a['level']
                    setPopupInfo(itemPopup)
                    setVisibleDescription(true)
                  }} */
                  style={{backgroundColor:'#0F1724',marginTop:5,marginBottom:5,borderRadius:10,width:"30%",position:'relative',border:'solid 0px #0F1724',minHeight:185}} >
                 
                  <div style={{display:'flex',opacity: item.status == true|| item.status == false || item.status == 'off' || !item.status ? 0 : 1,flexDirection:'row',marginTop:6,alignItems:'center',justifyContent:'flex-start',marginLeft:7}}>
                    <span className={item.status == "NEW" ? "green-circle": "purle-circle"}/>
                    <span style={{color:'#fff',fontSize:12,fontWeight:600,marginLeft:5}}>{item.status}</span>
                  </div>
                  <div style={{display:'flex',marginTop:5,alignContent:'center',justifyContent:'center'}}>
                    <img style={{width:55,height:55}} src={item.image} alt={item.image} /> 
                  </div>
                  <div style={{paddingTop:5,}}>
                    <p style={{color:item.status !== 'off' || !item.status ?"#57D48D" : "#37825E",fontSize:13,fontWeight:400,margin:0,textAlign:'center'}}>{userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 'Level '+ userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0].level : 'Level 0'}</p>
                  </div>
                  <div style={{paddingTop:4,marginLeft:2,marginRight:2}}>
                    <p className="two-lines" style={{color:"#FEFFFE",fontSize:12,fontWeight:700,margin:0,textAlign:'center',}}>{item.name}</p>
                  </div>
                  <div style={{display:'flex',marginTop:0,alignContent:'center',justifyContent:'center'}}>

                  <img style={{width:35,height:'auto',display:'flex',flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center',alignSelf:'center'}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722985085/Ajouter_un_titre_157_1_boxfzx.png"/>
                  </div>
                 {/*  <div style={{paddingTop:5,marginLeft:2,marginRight:2}}>
                    <p  style={{color:"#BFC3C9",fontSize:11,fontWeight:400,margin:0,textAlign:'center',}}>Profit per day</p>
                  </div> */}
                  {/* <div className="touchable" style={{paddingTop:5,paddingBottom:2,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <p style={{color:"#FEFFFE",fontSize:12,fontWeight:700,margin:0,textAlign:'center',}}>
                    +{userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 
                        capman_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0]["profit per day"] 
                      : 
                      capman_levels.filter((art)=>{
                        if(art.card == item.id && art.level == 1){
                          return art
                        }
                      })[0]["profit per day"] }
                    </p>

                    <img style={{width:12,height:12,marginLeft:3}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>

                  </div> */}
                  {/* <div className="touchable" style={{paddingTop:5,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',backgroundColor:userData.balance >=  item.price?"#57D48D" : "#1E3E39",marginLeft:20,marginRight:20,borderRadius:7,marginTop:5,marginBottom:9}}>
                    <img style={{width:15,height:15,marginLeft:-5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png"/>
                    <span style={{color: "#fff",fontSize:14,fontWeight:700,marginLeft:3}}>
                      {userArticles.filter((elt)=>{if(elt.id == item.id){return elt}}).length > 0 ? 
                        capman_levels.filter((art)=>{
                          const a = userArticles.filter((elt)=>{if(elt.id == item.id){return elt}})[0]
                          if(art.card == a.id && art.level == a.level){
                            return art
                          }
                        })[0].price 
                      : 
                      capman_levels.filter((art)=>{
                        if(art.card == item.id && art.level == 1){
                          return art
                        }
                      })[0].price }
                    </span>
                  </div> */}
                </div>
              )
            })}
          </Swiper.Item>
         
          </Swiper>
   
      {/* <Clicker/> */}

      </div>
      <Footer/>

      {/* <Cart cartItems={cartItems} onCheckout={onCheckout}/>

      <div className="cards__container">
        {foods.map((food) => {
          return (
            <Card food={food} key={food.id} onAdd={onAdd} onRemove={onRemove} />
          );
        })}
      </div> */}
</div>
  );
}
export default Friends;


                  {/* <div style={{display:'flex',flexDirection:'row'}}>
                    <div style={{display:'flex',marginTop:10,alignContent:'center',justifyContent:'center',marginLeft:5}}>
                      <img style={{width:45,height:45}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1715870349/icons8-session-timeout-100_sa6tq1.png"} alt={item.title} /> 
                    </div>
                    <div>
                     <div style={{paddingTop:7,width:"90%",marginLeft:15,display:'flex',flexDirection:'column'}}>
                      <span style={{color:"#FCFAFF",fontSize:16,fontWeight:700}}>Article {index}</span>
                      </div>
                      <div style={{paddingTop:2,width:"90%",marginLeft:7,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <img style={{width:23,height:23}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720026456/1_2_bvxzko.png"/>
                        <span style={{color:"#BBB9CC",fontSize:16,fontWeight:400,marginLeft:2}}>{item.new_price}K</span>
                      </div>
                    </div>
                  </div>
                  <div style={{paddingTop:7,width:"90%",marginLeft:10,marginBottom:3,display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span style={{color:"#FCFAFF",fontSize:12,fontWeight:700,marginLeft:0}}>Capacity</span>
                      <span style={{color:"#FCFAFF",fontSize:12,fontWeight:700,marginLeft:0}}>3/20</span>
                    </div>
                    <div className="neon-gauge-container" style={{marginBottom:9}}>
                      <div
                        className="neon-gauge"
                        style={{ width:  `${item.new_price}%`  }}
                      ></div>
                    </div>
                  </div> */}