import React, { useState,useContext,useRef, useEffect } from "react";
import "./Header.css";
import { format_number,format_number_inv ,updatelevel} from "../../Hooks/firebase_functions";
import { Context } from "../../Context/Context";
import { Avatar, List, Space,ProgressBar,Divider } from 'antd-mobile'
import { RightOutline,InformationCircleOutline } from 'antd-mobile-icons'
import { Modal } from 'antd-mobile';
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import { Button, Swiper, Toast } from 'antd-mobile'
import { levels } from "../../db/db";
import { Link, useNavigate } from 'react-router-dom';
import { translate } from "../../Hooks/translate";
import silver2Img from '../../images/silver2Img.png'

function Header() {
  const {value, value2, value3,value8} = useContext(Context)
  const [langContext,setLangContext] = value
  const [tlgmInfo, setTlgmInfo] = value3;
  const [userData, setUserData] = value2;
  const [currentLvl,setCurrentLvl] = value8;
  const [levelPopup, setLevelPopup] = useState(false);
  const [nextLevelPopup, setNextLevelPopup] = useState(false);
  const [oldLvl, setOldLvl] = useState(0);

  const ref = useRef(null);

/*   const ptnPrg = userData.max - levels[currentLvl].step
  const ptNxtL = levels[currentLvl+1].step - levels[currentLvl].step
  const prgF = (ptnPrg/ptNxtL)*100 */

  

/*   const items = levels.map((elt, index) => (
    <Swiper.Item key={index} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignSelf:'center'}}>

        <img style={{height:150,objectFit:'cover',display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignSelf:'center'}} src={elt.img}/>
        <div>
          <p className="fontRobot" style={{ color: "#fff", fontSize: 23, textAlign: 'center', fontWeight: 900,textTransform:'uppercase', alignContent: "center",marginTop:10,marginBottom:10 }}>
          {elt.name}
          </p>

          <div style={{ paddingLeft: '6%', marginRight: "6%", display: 'flex', flexDirection: 'column' }}>
            {currentLvl == index ?
            <div className="neon-gauge-container">
              <div className="neon-gauge" style={{ width: `${currentLvl === 0 ? (userData.balance / levels[0].step) * 100 :currentLvl === 8 ?  (userData.balance / levels[8].step) * 100 : (userData.balance - levels[currentLvl-1].step > 0 ? ((userData.balance - sumPreviousSteps(currentLvl))/((levels[currentLvl].step)))*100 : 0 )}%` }}></div>
            </div>
            :null}
            <div style={{display:'flex',alignContent:'center',justifyContent:'space-between',marginTop:10, opacity:currentLvl == index ? 1:0.5}}>
                <span style={{ color: "#FCFAFF", fontSize: 13, fontWeight: 700, marginRight: 3 }}>Level</span>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                  <img className="coinShadowN" style={{ width: 14, height: 14,marginRight:3 }} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png" alt="" />
                  <span style={{ color: currentLvl == index ?"#FFD535":"#fff", fontSize: 13, fontWeight: 700, marginRight: 3 }}>{format_number(levels[index].step)}</span>
                </div>
                
            </div>
            {index < levels.length-1 ?
            <div style={{display:'flex',alignContent:'center',justifyContent:'space-between',marginTop:10,opacity:0.5}}>
                <span style={{ color: "#FCFAFF", fontSize: 13, fontWeight: 700, marginRight: 3 }}>Next Level</span>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                  <img className="coinShadowN" style={{ width: 14, height: 14,marginRight:3  }} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png" alt="" />
                  <span style={{ color: "#FCFAFF", fontSize: 13, fontWeight: 700, marginRight: 3 }}>{format_number(levels[index+1].step)}</span>
                </div>
            </div>:null
            }
          </div>
        </div>           
    </Swiper.Item>
  )) */

  function sumPreviousSteps(currentLevel) {
    let sum = 0;
    
    for (let i = 0; i < currentLevel; i++) {
      sum += levels[i].step;
    }
    
    return sum;
  }

/*   useEffect(()=>{
    if ((currentLvl > 0 && userData.balance >= (levels[currentLvl].step + sumPreviousSteps(currentLvl))) || (currentLvl ==0 && userData.balance >= (levels[0].step)))  {
      let oldLevel = currentLvl
      setOldLvl(oldLevel)
      if(currentLvl<8){
        oldLevel = currentLvl
        const newLvl = oldLevel + 1
        setCurrentLvl(newLvl)
        updatelevel(userData,newLvl)
      }
      const oldData = { ...userData };
      const val = levels[oldLevel].bonus;
      oldData.balance = val + userData.balance;
      setUserData(oldData);
      setNextLevelPopup(true)
     
    }
  },[userData.balance]) */

  function calcul_level(currentPoints) {
    let currentLevel = 0;
    let nextLevel = 0;
    for (let i = 0; i < levels.length; i++) {
      if (currentPoints < levels[i].step) {
        nextLevel = i;
        currentLevel = i - 1;
        break;
      }
    }
  
    if (currentLevel === -1) {
      const progress = (currentPoints / levels[0].step) * 100;
      return [progress, "Pup", levels[0].step, 0,levels[0]];
      // User is below the first level
      //return (currentPoints / levels[0].step) * 100;
    } else if (nextLevel === 0) {
      // User is at or above the last level
      return [100, levels[currentLevel].name, levels[currentLevel].step, levels[currentLevel].step,levels[currentLevel]];
  
      //return 100;
    } else {
      const pointsRequiredForCurrentLevel = levels[currentLevel].step;
      const pointsRequiredForNextLevel = levels[nextLevel].step;
      const pointsProgress = currentPoints - pointsRequiredForCurrentLevel;
      const pointsToNextLevel = pointsRequiredForNextLevel - pointsRequiredForCurrentLevel;
      if (currentPoints >= pointsRequiredForNextLevel) {
        setNextLevelPopup(true)
       /*  const oldData = { ...userData };
        const val = levels[currentLevel].bonus;
        oldData.balance = val + userData.balance;
        setUserData(oldData); */
        
      }
      /* console.log(currentPoints,pointsRequiredForNextLevel,pointsProgress,(pointsProgress / pointsToNextLevel) * 100) */
      return [(pointsProgress / pointsToNextLevel) * 100, levels[currentLevel].name,levels[nextLevel].step,levels[currentLevel].step,levels[currentLevel]];
    }
  }
  function calcul_level2(currentPoints) {
    let currentLevel = 0;
    let nextLevel = 0;
    for (let i = 0; i < levels.length; i++) {
      if (currentPoints < levels[i].step) {
        nextLevel = i;
        currentLevel = i - 1;
        break;
      }
    }
  
    if (currentLevel === -1) {
      const progress = (currentPoints / levels[0].step) * 100;
      return [progress, "Pup", levels[0].step, 0];
      // User is below the first level
      //return (currentPoints / levels[0].step) * 100;
    } else if (nextLevel === 0) {
      // User is at or above the last level
      return [100, levels[currentLevel].name, levels[currentLevel].step, levels[currentLevel].step];
  
      //return 100;
    } else {
      const pointsRequiredForCurrentLevel = levels[currentLevel].step;
      const pointsRequiredForNextLevel = levels[nextLevel].step;
      const pointsProgress = currentPoints - pointsRequiredForCurrentLevel;
      const pointsToNextLevel = pointsRequiredForNextLevel - pointsRequiredForCurrentLevel;
      if (currentPoints >= pointsRequiredForNextLevel) {
        setNextLevelPopup(true)
       /*  const oldData = { ...userData };
        const val = levels[currentLevel].bonus;
        oldData.balance = val + userData.balance;
        setUserData(oldData); */
        
      }
      /* console.log(currentPoints,pointsRequiredForNextLevel,pointsProgress,(pointsProgress / pointsToNextLevel) * 100) */
      return [(pointsProgress / pointsToNextLevel) * 100, levels[currentLevel].name,levels[nextLevel].step,levels[currentLevel].step,levels[currentLevel]];
    }
  }
  return (
   <div style={{backgroundColor:'#131313', zIndex:10000}}>
     {/* <Modal
        visible={levelPopup}
        bodyStyle={{
          border:'solid 1px #062321',
          backgroundColor: "#060B18",
          borderRadius:15
        }}
        showCloseButton
        onClose={()=>{setLevelPopup(false)}}
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginLeft: 0, marginRight: 0 }}>
              <div style={{ display: "flex", justifyContent:"space-between",width:'100%',alignItems:'center', flexDirection: "row", padding: 0, marginTop: 10 }}>
                <div className="touchable" onClick={()=>{ref.current?.swipePrev()}}>
                  <img style={{width:20,height:20}} src='https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722269747/_2_nht3mr.png'/>
                </div>
                <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',width:'80%'}}>
                    <Swiper allowTouchMove={false} indicator={false} ref={ref} loop>
                      {items}
                    </Swiper>
                    
                </div>
                <div className="touchable" onClick={()=>{ref.current?.swipeNext()}}>
                  <img style={{width:20,height:20}} src='https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722269747/__vob8wf.png'/>
                </div>
              </div>
              
              
            </div>
          </>
        }
        closeOnAction={true}
      /> */}

      {/* <Modal
        visible={nextLevelPopup}
        bodyStyle={{
          border:'solid 1px #062321',
          backgroundColor: "#060B18",
          borderRadius:15
        }}
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginLeft: 10, marginRight: 10 }}>
              <span style={{ color: "#fff", fontSize: 17, textAlign: 'center', fontWeight: 500, alignContent: "center" }}>
                Well done you've just taken it to the next level!
              </span>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: 0, marginTop: 10 }}>
                <img className="coinShadowN" style={{ width: 45, height: 45 }} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png" alt="" />
                <img className="" style={{ width: 115, height: 115, position: 'absolute', marginLeft: -70, marginTop: -30 }} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721144593/Ajouter_un_titre_70_dwtvdb.png" alt="" />
                <span className="fontRobot" style={{ color: "#fff", fontSize: 35, fontWeight: 900, alignContent: "center", marginLeft: 10 }}>
                {format_number(levels[oldLvl].bonus)}
                </span>
              </div>
              
            </div>
          </>
        }
        closeOnAction
        onClose={()=>{setNextLevelPopup(false)}}
        
        actions={[
          {
            key: 'confirm',
            text: 'Continue',
            style: {
              backgroundColor: '#4CC37D', borderRadius: 8, paddingTop: 8, paddingBottom: 8, color: '#fff',
              paddingLeft:20,
              paddingRight:20,fontWeight:800,fontSize:16,
              boxShadow: '0 0 5px #37825E, 0 0 7px #37825E'
            }
          },
        ]}
      /> */}
        {/* <div className="header" >
      <div className="avatar-container">
        <div
          className="avatar"
          style={{ backgroundImage: `url('https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720204555/icons8-loup-100_jbkvom.png')` }} // Replace with actual image URL
        ></div>
        <div className="cont-username">
        <div className="health-fill">
        <div className="username">{tlgmInfo.firstName}</div>
        </div>
        </div>
      </div>
      <div className="stats">
        <div className="stat-item">
        <span style={{marginRight:5}}>{userData.sheep}</span>
          <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720396483/icons8-mouton-48_ejb3ry.png" alt="Sheep" />
          
        </div>
        <div className="stat-item">
        <span style={{marginRight:5,padding:0}}>{userData.balance}</span>
 
            <img  src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720395059/icons8-pile-de-pie%CC%80ces-48_m2ymaq.png" alt="Coin" />

        </div>
      </div>
    </div> */}
 


      
      <div className="bkgFlat" style={{paddingLeft:10,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'space-between',alignContent:'center',alignItems:'center',paddingBottom:0,paddingTop:10}}>
        <div onClick={()=>{/* setLevelPopup(true) */}} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBottom:0,marginLeft:0}}>
          <div style={{backgroundColor:'#21212C',borderRadius:500,justifyContent:'center',display:'flex',flexDirection:'row',alignItems:'center'}}>
            <p style={{color:'#fff',margin:7,fontSize:12}}>{userData.username.substring(0, 2).toUpperCase()}</p>
          </div>
          {/* <Avatar className="touchable" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720204555/icons8-loup-100_jbkvom.png"} style={{ '--size': '22px',backgroundColor:'#131313' }} /> */}
          <span className="touchable" style={{color:'#fff',fontWeight:'400',fontSize:14,paddingLeft:11,textOverflow:'ellipsis',maxWidth:'55vw',  overflow: 'hidden',alignItems:'center'}}>{userData.username ? userData.username : tlgmInfo.firstName}</span>
        </div>

        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:0,marginRight:5,borderRadius:10,padding:'5px 12px 5px 8px'}}>
             {/*  <img style={{widows:25,height:25,padding:'3px 5px 2px 5px'}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png" />
            <div style={{display:'flex',flexDirection:'column',alignContent:'center',marginLeft:3}}>
              <span style={{color:'#6F688B',textAlign:'left',fontSize:10,fontWeight:'400'}}>{translate('Profit per day', langContext,'ENGLISH')}</span>
              <span style={{color:'#EDF8EF',textAlign:'left',fontSize:12,fontWeight:'500'}}>{format_number(userData.pph)}</span>
            </div> */}

            <div style={{display:'flex',flexDirection:'column',alignContent:'center',marginLeft:0,alignItems:'center'}}>
              {/* <span style={{color:'#6F688B',textAlign:'left',fontSize:10,fontWeight:'400',marginTop:-3}}>Silver</span> */}
              <span style={{color:'#EDF8EF',textAlign:'right',fontSize:14,fontWeight:'500',alignItems:'center'}}>{format_number(userData.sheep)}</span>
            </div>
            <img style={{widows:23,height:23,padding:'3px 5px 2px 5px',marginLeft:-2}} src={silver2Img} />

          </div>
          <Link to={'/settings'} style={{textDecoration:'none'}} className="touchable">
            <img  style={{width:23,height:23,marginTop:5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721208157/settings_w108hi.png" />
          </Link>
        </div>
      </div>

      {/* <div className="bkgFlat" style={{paddingLeft:10,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'space-between',alignContent:'center',alignItems:'center',paddingBottom:0,paddingTop:10}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBottom:0,marginLeft:2}}>
          <Avatar className="touchable" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720204555/icons8-loup-100_jbkvom.png"} style={{ '--size': '22px',backgroundColor:'#000' }} />
          <span className="touchable" style={{color:'#fff',fontWeight:'400',fontSize:15,paddingLeft:6}}>{tlgmInfo.firstName}</span>
        </div>

        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:0,marginRight:14,backgroundColor:'#201B30',border:'solid 1px #322D46',borderRadius:10,padding:'5px 12px 5px 8px'}}>
            <div style={{backgroundColor:'#52563B',borderRadius:5,alignContent:'center'}}>
              <img style={{widows:18,height:18,padding:'3px 5px 2px 5px'}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720395059/icons8-pile-de-pie%CC%80ces-48_m2ymaq.png" />
            </div>
            <div style={{display:'flex',flexDirection:'column',alignContent:'center',marginLeft:12}}>
              <span style={{color:'#A3A5B4',textAlign:'center',fontSize:12,fontWeight:'400'}}>PPD</span>
              <span style={{color:'#E4E8EE',textAlign:'center',fontSize:12,fontWeight:'500'}}>+2,43k</span>
            </div>

            <div style={{backgroundColor:'#68545F',borderRadius:5,alignContent:'center',marginLeft:14}}>
              <img style={{widows:18,height:18,padding:'3px 5px 2px 5px'}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720396483/icons8-mouton-48_ejb3ry.png" />
            </div>
            <div style={{display:'flex',flexDirection:'column',alignContent:'center',marginLeft:12}}>
              <span style={{color:'#A3A5B4',textAlign:'center',fontSize:12,fontWeight:'400'}}>Sheeps</span>
              <span style={{color:'#E4E8EE',textAlign:'center',fontSize:12,fontWeight:'500'}}>15</span>
            </div>
          </div>
          <img className="touchable" style={{width:30,height:30,paddingRight:5}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720451953/27_udxjuo.png" />
        </div>
      </div> */}
     




      <div style={{paddingLeft:10,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'space-between',alignContent:'center'}}>
        {/* LEVEL */}
       {/*  <div style={{display:'flex',flexDirection:'column',width:"30%"}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'flex',justifyContent:'space-between',marginTop:-15}}>
            <div  style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:0}}>
              <p style={{color:'#Fff',fontWeight:600}}>Bronze</p>
              <RightOutline color="#fff" fontSize={11}  style={{marginLeft:'2px'}}/>
            </div>
            <div>
              <p style={{color:'#Fff',marginRight:3,fontWeight:600}}>1/10</p>
            </div>
            
          </div>
          <ProgressBar
                percent={40}
                style={{
                  marginTop:-5,
                  width:"100%",
                  '--fill-color':
                    'linear-gradient(to right, var(--adm-color-primary), var(--adm-color-success))',
                }}
          />
        </div> */}

        

      </div>
      
    </div>
  );
}

export default Header;
