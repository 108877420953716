import React, { useState, useEffect } from 'react';
import './LoadingPage.css';
import { ProgressBar, Space } from 'antd-mobile';
import vikexLogo1 from "../../images/vikexLogo1.png";

const LoadingPage = ({ page }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setProgress(prev => {
        if (prev < 80) return prev + 3.5; // Fast until 80%
        if (prev < 95) return prev + 0.5; // Slow after 80%
        clearInterval(interval);
        return prev;
      });
    }, 100); // Adjust interval speed if necessary
  }, []);

  return (
    <div className="loading-page">
      <img src={vikexLogo1} style={{ width: 100, height: 100, marginBottom: 50,marginTop:-50 }} />
      <ProgressBar
        percent={progress}
        style={{
          '--track-color': '#202630',
          '--fill-color': 'linear-gradient(to right, var(--adm-color-primary) -50%, #4285F4)',
          '--track-width': '6px',
          width: '75%',
          paddingLeft: 10, alignItems: 'center'
        }}
      />
    </div>
  );
};

export default LoadingPage;

