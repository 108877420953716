import { useState, useEffect ,useContext,useRef} from "react";
import "../../App.css";
import Clicker from "../../Components/Clicker/Clicker";
import Footer from "../../Components/Footer/Footer";
import Referal from "../../Components/Referal/Referal";
import Recomp from "../../Components/Recomp/Recomp";
import { Context } from "../../Context/Context";
import { Tabs,Swiper } from 'antd-mobile'
import {update_task,get_tasks,format_number} from '../../Hooks/firebase_functions'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import HeaderEarn from "../../Components/HeaderEarn/HeaderEarn";
import Header from "../../Components/Header/Header";
import { Button, Space , Toast} from 'antd-mobile'
import { Badge } from 'antd-mobile'
import { levels } from "../../db/db";
import { useLocation } from 'react-router-dom';
import {languages,languagesC,translate,translateC} from '../../Hooks/translate'
import clockImg from '../../images/clockImg.png'
import silver2Img from '../../images/silver2Img.png'
import Countdown from './Countdown'
import ListFriends from "../../Components/ListFriends/ListFriends";
import { useNavigate } from 'react-router-dom';

const tele = window.Telegram.WebApp;

function Friends() {
  const {value, value2, value3,value7,value10} = useContext(Context)
  //const initialIndex = location.state?.initialIndex || 0;
  const location = useLocation();
  const [friends, setFriends] = value7;

  const initialIndex = location.state?.initialIndex || 0;

  const [langContext, setLangContext] = value
  const [userData, setUserData] = value2
  const [chargesData, setChargesData] = value3
  const [tasks, setTasks] = value10

  const [moreBonus, setMoreBonus] = useState(false);
  const [visibleMore, setVisibleMore] = useState(false);
  const [shared, setShared] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const swiperRef = useRef(null)
  //const [activeIndex, setActiveIndex] = useState(0)
  const [activeIndex, setActiveIndex] = useState(initialIndex); 
  const referralLink = "https://t.me/share/url?url=https://t.me/WolferaBot?start=referral_user_id="+userData.userId
  const navigate = useNavigate();

  
  useEffect(() => {
    console.log("userData.sheep",userData.sheep)
    if (!userData.sheep || userData.sheep == -1 ) {
      navigate('/setusername');
    }
  }, [userData, navigate]);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
    }
  }, []);

  useEffect(() => {
    // Swiper should move to the initial index on mount
    swiperRef.current?.swipeTo(initialIndex);
  }, [initialIndex]);


  const handleInviteClick = () => {
    window.open(referralLink, '_blank');
  };
  
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check this out!',
          text: 'Here is an awesome link for you to check out:',
          url: referralLink,
        });
        setShared(true); // Assume the share was successful
      } catch (error) {
        console.error('Error sharing content:', error);
        setShared(false);
      }
    } else {
      console.log('Web Share API not supported in this browser');
      setShared(false);
    }
  };

  useEffect(() => {
    const fn = async () => {
      const rep = await get_tasks(userData.userId)
      if(rep.length>0){
        setTasks(rep[0].tasks)
      }else{
        setTasks([])
      }
      
    }
    fn()
  },[]);

  const tabItems = [
    { key: 'friends', title: 'Friends' },
    { key: 'tasks', title: 'Tasks' },
  ]

  
  return (
    <div className="blocked" style={{display: 'flex', flexDirection: 'column',backgroundColor:'#131313'}}>
      
{/*       <h1 className="heading" style={{color:"#fff",marginBottom:0}}>Invitez des amis!</h1>
      <p className="heading" style={{color:"#fff",marginTop:5}}>Vous et vos amis recevrez des bonus</p> */}
    
    <div className="main-content">
    <Header/>
      <div>
        <p className="" style={{color:'#fff',fontWeight:400,textAlign:'center',fontSize:24,paddingTop:10,paddingBottom:0,margin:0}}>Friends</p>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:3}}>
          <p className="" style={{color:'#fff',fontWeight:400,textAlign:'center',fontSize:29,paddingTop:0,paddingBottom:0,margin:0}}>{userData.sheep}</p>
          <img style={{height:35,width:35,marginLeft:5}} src={silver2Img}/>
        </div>  
        <p className="" style={{color:'#fff',fontWeight:400,textAlign:'center',fontSize:13,paddingTop:5,paddingBottom:0,margin:0}}>Total balance</p>

      </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',marginTop:20,marginLeft: 15}}>
          <p style={{color:'#fff',fontSize:14,fontWeight:500,textAlign:'center',marginTop:0,marginBottom:0}}>Total daily reward </p>
          <p style={{color:'#fff',fontWeight:'500',fontSize:13,paddingLeft:10,margin:0,alignItems:'center'}}>+{friends &&friends.length >=0 ? friends.reduce((sum, user) => sum + Math.floor(user.balance/10), 0) : 0}</p>
          <img style={{height:20,width:20,marginLeft:2}} src={silver2Img}/>
      </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',marginTop:-2,marginLeft: 15}}>
        <p style={{color:'#919191',fontSize:11,fontWeight:500,textAlign:'center',marginTop:0,marginBottom:0}}>Earn 10% Daily on your Friend's Balance</p>
      </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:5,marginLeft: 15,marginRight:15}}>
        <p style={{color:'#fff',fontSize:16,fontWeight:500,textAlign:'center',marginTop:0,marginBottom:0}}>{friends &&friends.length >=0 ?'Loaded Friends ('+ friends.length+')' : 'Friends (0)' }</p>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <p style={{color:'#fff',fontSize:12,fontWeight:500,textAlign:'center',marginTop:0,marginBottom:0}}>Next update</p>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around'}}>
            <img style={{height:14,width:14,marginLeft:0,marginRight:5}} src={clockImg}/>
            <Countdown/>
          </div>
        </div>
      </div>
      <div style={{}}>   
       
        {/* {!moreBonus ?
        <div className="touchable" onClick={()=>{setMoreBonus(true)}}>
          <p style={{fontWeight:700,color:'#fff',marginBottom:0,fontSize:14,textAlign:'center'}}>More Bonuses</p>
        </div>
        :
        null
        } */}
        {moreBonus ?
        <div style={{marginLeft:15,marginRight:15}}>
          <p style={{fontWeight:700,color:'#fff',marginBottom:0,fontSize:17,textAlign:'left',marginLeft:5}}>Bonus for the next level</p>
          <div style={{display:'flex',flexDirection:'row',marginLeft:10,justifyContent:'flex-start',alignItems:'center',borderRadius:8,marginTop:8,padding:'5px 0px 3px 0px'}}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',width:'40%'}}>
              <span style={{fontWeight:500,color:'#7B7B7D',marginBottom:0,fontSize:14}}>Level</span>
            </div>
            <div style={{display:'flex',flexDirection:'row',width:'30%'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                <span style={{fontWeight:500,color:'#7B7B7D',marginBottom:0,fontSize:14}}>Friend</span>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',width:'30%'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                <span style={{fontWeight:500,color:'#7B7B7D',marginBottom:0,fontSize:14}}>Premium</span>

              </div>
            </div>
          </div>
          {levels.map((item,index)=>{
            return(
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',backgroundColor:'#141A25',borderRadius:15,marginTop:8,padding:'5px 0px 5px 0px'}}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',width:'40%'}}>
                  <div style={{/* backgroundColor:'#fff', */width:50,height:50,borderRadius:8,display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img style={{whidth:50,height:50}} src={item.img}/>
                  </div>
                  <span style={{fontWeight:500,color:'#fff',marginBottom:0,fontSize:14}}>{item.name}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',width:'30%'}}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                    
                    <img style={{whidth:22,height:22}} src={'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png'}/>
                    <span style={{fontWeight:500,color:'#FCB71B',marginBottom:0,fontSize:14,fontWeight:900}}>+</span>
                    <span style={{fontWeight:500,color:'#FCB71B',marginBottom:0,fontSize:14,fontWeight:900}}>{item.referral_price}</span>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',width:'30%'}}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                    
                    <img style={{whidth:22,height:22}} src={'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721773599/Ajouter_un_titre_79_2_ooz89n.png'}/>
                    <span style={{fontWeight:500,color:'#FCB71B',marginBottom:0,fontSize:14,fontWeight:900}}>+</span>
                    <span style={{fontWeight:500,color:'#FCB71B',marginBottom:0,fontSize:14,fontWeight:900}}>{item.referral_premium_price}</span>
                  </div>
                </div>
              </div>
            )
          })}
          <div className="touchable" onClick={()=>{setMoreBonus(false)}}>
          <p style={{fontWeight:700,color:'#fff',marginBottom:0,fontSize:14,textAlign:'center'}}>Less Bonus</p>
        </div>
        </div>
        :null
        }
        <div style={{marginTop:0}} >
        <ListFriends/>
        </div>
      </div>

      


        {activeIndex == 0 ||activeIndex == 1  ?
        <div className={activeIndex == 0 ? 'visible bottom-btn' : 'hidden bottom-btn'} style={{position:'fixed',bottom:90}}>
          <div   className="card-referal centered-elt"  style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
            <div className="touchable invite-btgn" onClick={handleInviteClick} id="shareButton"  fill='solid'  style={{borderRadius:7,padding:5,flex:4,marginRight:10,width:'100%',backgroundColor:'#4285F4',border:'solid 0px #BBB9CC',marginLeft:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <p  style={{color:'#fff',textAlign:'center',fontSize:16,fontWeight:'500',marginTop:8,marginBottom:8}}>Invite a friend</p>
              <p style={{color:'#fff',fontWeight:'500',fontSize:13,paddingLeft:10,margin:0,alignItems:'center'}}>+10</p>
          <img style={{height:20,width:20,marginLeft:5}} src={silver2Img}/>
            </div>
          </div>   
        </div>
        :null}
    </div>
    <Footer/>
    </div>
  );
}
export default Friends;
