import lvl0 from '../images/lvl0.png'
import lvl1 from '../images/lvl1.png'
import lvl2 from '../images/lvl2.png'
import lvl3 from '../images/lvl3.png'
import lvl4 from '../images/lvl4.png'
import lvl5 from '../images/lvl5.png'
import lvl6 from '../images/lvl6.png'
import lvl7 from '../images/lvl7.png'
import lvl8 from '../images/lvl8.png'
 

export const levels = [
  {
    level:0,
    bonus: 1000,
    name:"Pup",
    step: 5000,
    referral_price:20000,
    referral_premium_price:25000,
    img:lvl0
  },
  {
    level:1,
    bonus: 3500,
    name:"Juvenile",
    step: 35000,
    referral_price:30000,
    referral_premium_price:50000,
    img:lvl1
  },
  {
    level:2,
    bonus: 10000,
    name:"Yearling",
    step: 100000,
    referral_price:40000,
    referral_premium_price:75000,
    img:lvl2
  },
  {
    level:3,
    bonus: 25000,
    name:"Subordinate",
    step: 250000,
    referral_price:60000,
    referral_premium_price:100000,
    img:lvl3
  },
  {
    level:4,
    bonus: 100000,
    name:"Beta",
    step: 1000000,
    referral_price:100000,
    referral_premium_price:150000,
    img:lvl4
  },
  {
    level:5,
    bonus: 300000,
    name:"Alpha",
    step: 3000000,
    referral_price:250000,
    referral_premium_price:500000,
    img:lvl5
  },
  {
    level:6,
    bonus: 1000000,
    name:"Disperser",
    step: 10000000,
    referral_price:500000,
    referral_premium_price:1000000,
    img:lvl6
  },
  {
    level:7,
    bonus: 5000000,
    name:"Lone Wolf",
    step: 50000000,
    referral_price:1000000,
    referral_premium_price:2000000,
    img:lvl7
  },
  {
    level:8,
    bonus: 1000,
    name:10000000,
    step: 100000000,
    referral_price:2000000,
    referral_premium_price:4000000,
    img:lvl8
  },
]
export const levelsLife = [
  {
    level:0,
    max:200,
    cost:2000
  },
  {
    level:1,
    max:500,
    cost:10000
  },
  {
    level:2,
    max:1000,
    cost:50000
  },
  {
    level:3,
    max:2000,
    cost:250000
  },
  {
    level:4,
    max:4000,
    cost:250000
  },
  {
    level:5,
    max:8000,
    cost:250000
  },
  {
    level:6,
    max:16000,
    cost:250000
  }
]

export const pah_levels = [
  {
    "card": "Formation Continue",
    "level": 1,
    "price": 744,
    "profit per day": 110,
    "price ratio": 0.1478494624,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 2,
    "price": 2320,
    "profit per day": 172,
    "price ratio": 0.07413793103,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 3,
    "price": 4144,
    "profit per day": 454,
    "price ratio": 0.1095559846,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 4,
    "price": 5789,
    "profit per day": 453,
    "price ratio": 0.07825185697,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 5,
    "price": 9019,
    "profit per day": 477,
    "price ratio": 0.05288834682,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 6,
    "price": 11710,
    "profit per day": 929,
    "price ratio": 0.07933390265,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 7,
    "price": 6987,
    "profit per day": 1537,
    "price ratio": 0.2199799628,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 8,
    "price": 21971,
    "profit per day": 1881,
    "price ratio": 0.08561285331,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 9,
    "price": 26357,
    "profit per day": 1097,
    "price ratio": 0.04162082179,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 10,
    "price": 27158,
    "profit per day": 2372,
    "price ratio": 0.08734074674,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 11,
    "price": 35293,
    "profit per day": 2384,
    "price ratio": 0.06754880571,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 12,
    "price": 38270,
    "profit per day": 2225,
    "price ratio": 0.05813953488,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 13,
    "price": 33515,
    "profit per day": 4156,
    "price ratio": 0.1240041772,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 14,
    "price": 35026,
    "profit per day": 2790,
    "price ratio": 0.07965511334,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 15,
    "price": 52590,
    "profit per day": 5499,
    "price ratio": 0.1045636052,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 16,
    "price": 63108,
    "profit per day": 3829,
    "price ratio": 0.06067376561,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 17,
    "price": 39287,
    "profit per day": 5864,
    "price ratio": 0.1492605697,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 18,
    "price": 38874,
    "profit per day": 7670,
    "price ratio": 0.1973041107,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 19,
    "price": 99748,
    "profit per day": 8420,
    "price ratio": 0.08441272005,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 20,
    "price": 63486,
    "profit per day": 6363,
    "price ratio": 0.1002268217,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 21,
    "price": 74247,
    "profit per day": 6952,
    "price ratio": 0.0936334128,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 22,
    "price": 102278,
    "profit per day": 9465,
    "price ratio": 0.09254189562,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 23,
    "price": 114865,
    "profit per day": 6310,
    "price ratio": 0.05493405302,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 24,
    "price": 59811,
    "profit per day": 9282,
    "price ratio": 0.1551888449,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 25,
    "price": 140096,
    "profit per day": 13415,
    "price ratio": 0.09575576747,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 26,
    "price": 60816,
    "profit per day": 8326,
    "price ratio": 0.1369047619,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 27,
    "price": 169594,
    "profit per day": 12082,
    "price ratio": 0.07124072786,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 28,
    "price": 171209,
    "profit per day": 9401,
    "price ratio": 0.05490949658,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 29,
    "price": 120217,
    "profit per day": 17297,
    "price ratio": 0.143881481,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
  {
    "card": "Formation Continue",
    "level": 30,
    "price": 222913,
    "profit per day": 19323,
    "price ratio": 0.08668404265,
    "unlock": "Unlock at Level 5 of Nutrition Équilibrée"
  },
    {
      "card": "Planification et Objectifs",
      "level": 1,
      "price": 1003,
      "profit per day": 68,
      "price ratio": 0.06779661017,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 2,
      "price": 2069,
      "profit per day": 253,
      "price ratio": 0.1222812953,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 3,
      "price": 3348,
      "profit per day": 265,
      "price ratio": 0.07915173238,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 4,
      "price": 5514,
      "profit per day": 593,
      "price ratio": 0.1075444324,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 5,
      "price": 10143,
      "profit per day": 839,
      "price ratio": 0.08271714483,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 6,
      "price": 9937,
      "profit per day": 1049,
      "price ratio": 0.1055650599,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 7,
      "price": 15158,
      "profit per day": 1315,
      "price ratio": 0.08675286977,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 8,
      "price": 18175,
      "profit per day": 1265,
      "price ratio": 0.06960110041,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 9,
      "price": 19928,
      "profit per day": 1576,
      "price ratio": 0.07908470494,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 10,
      "price": 19809,
      "profit per day": 1507,
      "price ratio": 0.07607653087,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 11,
      "price": 32244,
      "profit per day": 2782,
      "price ratio": 0.08627961791,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 12,
      "price": 41762,
      "profit per day": 2731,
      "price ratio": 0.06539437766,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 13,
      "price": 38979,
      "profit per day": 2606,
      "price ratio": 0.06685651248,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 14,
      "price": 27232,
      "profit per day": 4110,
      "price ratio": 0.1509253819,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 15,
      "price": 27988,
      "profit per day": 5678,
      "price ratio": 0.2028726597,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 16,
      "price": 67193,
      "profit per day": 3415,
      "price ratio": 0.05082374652,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 17,
      "price": 72539,
      "profit per day": 7093,
      "price ratio": 0.09778188285,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 18,
      "price": 65577,
      "profit per day": 6665,
      "price ratio": 0.1016362444,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 19,
      "price": 97309,
      "profit per day": 6897,
      "price ratio": 0.07087730837,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 20,
      "price": 113472,
      "profit per day": 7594,
      "price ratio": 0.06692399887,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 21,
      "price": 66421,
      "profit per day": 7987,
      "price ratio": 0.1202481143,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 22,
      "price": 46631,
      "profit per day": 5644,
      "price ratio": 0.1210353627,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 23,
      "price": 63683,
      "profit per day": 5602,
      "price ratio": 0.08796696136,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 24,
      "price": 145004,
      "profit per day": 12360,
      "price ratio": 0.08523902789,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 25,
      "price": 119774,
      "profit per day": 10155,
      "price ratio": 0.08478467781,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 26,
      "price": 176979,
      "profit per day": 9771,
      "price ratio": 0.05520994016,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 27,
      "price": 145228,
      "profit per day": 12271,
      "price ratio": 0.08449472554,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
    {
      "card": "Planification et Objectifs",
      "level": 28,
      "price": 160788,
      "profit per day": 17165,
      "price ratio": 0.1067554793,
      "unlock": "Unlock at Level 10 of Sommeil Réparateur"
    },
      {
        "card": "Planification et Objectifs",
        "level": 29,
        "price": 87448,
        "profit per day": 18317,
        "price ratio": 0.2094616229,
        "unlock": "Unlock at Level 10 of Sommeil Réparateur"
      },
      {
        "card": "Planification et Objectifs",
        "level": 30,
        "price": 137006,
        "profit per day": 9691,
        "price ratio": 0.07073412843,
        "unlock": "Unlock at Level 10 of Sommeil Réparateur"
      },
      {
        "card": "Éducation Financière",
        "level": 1,
        "price": 751,
        "profit per day": 109,
        "price ratio": 0.1451398136,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 2,
        "price": 1896,
        "profit per day": 201,
        "price ratio": 0.1060126582,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 3,
        "price": 3109,
        "profit per day": 372,
        "price ratio": 0.1196526214,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 4,
        "price": 5930,
        "profit per day": 557,
        "price ratio": 0.09392917369,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 5,
        "price": 7781,
        "profit per day": 868,
        "price ratio": 0.1115537849,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 6,
        "price": 6830,
        "profit per day": 782,
        "price ratio": 0.1144948755,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 7,
        "price": 13327,
        "profit per day": 1738,
        "price ratio": 0.1304119457,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 8,
        "price": 11155,
        "profit per day": 1527,
        "price ratio": 0.1368892873,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 9,
        "price": 16444,
        "profit per day": 2399,
        "price ratio": 0.1458890781,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 10,
        "price": 14987,
        "profit per day": 1730,
        "price ratio": 0.1154333756,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 11,
        "price": 35303,
        "profit per day": 2160,
        "price ratio": 0.06118460188,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 12,
        "price": 20783,
        "profit per day": 3319,
        "price ratio": 0.15969783,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 13,
        "price": 38507,
        "profit per day": 2737,
        "price ratio": 0.07107798582,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 14,
        "price": 51561,
        "profit per day": 3801,
        "price ratio": 0.07371850817,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 15,
        "price": 53145,
        "profit per day": 5574,
        "price ratio": 0.1048828676,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 16,
        "price": 55474,
        "profit per day": 5101,
        "price ratio": 0.09195298698,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 17,
        "price": 35904,
        "profit per day": 5840,
        "price ratio": 0.1626559715,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 18,
        "price": 49486,
        "profit per day": 3632,
        "price ratio": 0.07339449541,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 19,
        "price": 100773,
        "profit per day": 7094,
        "price ratio": 0.07039584016,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 20,
        "price": 96162,
        "profit per day": 7330,
        "price ratio": 0.07622553607,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 21,
        "price": 47624,
        "profit per day": 6390,
        "price ratio": 0.1341760457,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 22,
        "price": 54689,
        "profit per day": 7529,
        "price ratio": 0.1376693668,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 23,
        "price": 48765,
        "profit per day": 5677,
        "price ratio": 0.1164154619,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 24,
        "price": 117289,
        "profit per day": 7900,
        "price ratio": 0.06735499493,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 25,
        "price": 133129,
        "profit per day": 14402,
        "price ratio": 0.1081807871,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
      {
        "card": "Éducation Financière",
        "level": 26,
        "price": 140521,
        "profit per day": 7430,
        "price ratio": 0.0528746593,
        "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
      },
        {
          "card": "Éducation Financière",
          "level": 27,
          "price": 161722,
          "profit per day": 15032,
          "price ratio": 0.09294962961,
          "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
        },
        {
          "card": "Éducation Financière",
          "level": 28,
          "price": 159540,
          "profit per day": 11915,
          "price ratio": 0.07468346496,
          "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
        },
        {
          "card": "Éducation Financière",
          "level": 29,
          "price": 150331,
          "profit per day": 10398,
          "price ratio": 0.06916737067,
          "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
        },
        {
          "card": "Éducation Financière",
          "level": 30,
          "price": 132909,
          "profit per day": 15538,
          "price ratio": 0.1169070567,
          "unlock": "Unlock at Level 15 of Équilibre Travail-Vie Personnelle"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 1,
          "price": 1747,
          "profit per day": 210,
          "price ratio": 0.1202060675,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 2,
          "price": 4199,
          "profit per day": 578,
          "price ratio": 0.1376518219,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 3,
          "price": 6903,
          "profit per day": 870,
          "price ratio": 0.1260321599,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 4,
          "price": 9614,
          "profit per day": 1394,
          "price ratio": 0.1449968796,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 5,
          "price": 14369,
          "profit per day": 1829,
          "price ratio": 0.1272879115,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 6,
          "price": 19182,
          "profit per day": 2813,
          "price ratio": 0.1466478991,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 7,
          "price": 33071,
          "profit per day": 3404,
          "price ratio": 0.1029300596,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 8,
          "price": 24585,
          "profit per day": 3681,
          "price ratio": 0.1497254423,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 9,
          "price": 45855,
          "profit per day": 3164,
          "price ratio": 0.06900010904,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 10,
          "price": 28742,
          "profit per day": 6978,
          "price ratio": 0.2427805998,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 11,
          "price": 71353,
          "profit per day": 5598,
          "price ratio": 0.0784550054,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 12,
          "price": 54667,
          "profit per day": 6409,
          "price ratio": 0.11723709,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 13,
          "price": 60241,
          "profit per day": 7751,
          "price ratio": 0.1286665228,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 14,
          "price": 105803,
          "profit per day": 6497,
          "price ratio": 0.06140657637,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 15,
          "price": 58010,
          "profit per day": 13892,
          "price ratio": 0.2394759524,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 16,
          "price": 131231,
          "profit per day": 7301,
          "price ratio": 0.05563472045,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 17,
          "price": 136145,
          "profit per day": 17599,
          "price ratio": 0.1292665908,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 18,
          "price": 129565,
          "profit per day": 19217,
          "price ratio": 0.1483193764,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 19,
          "price": 127538,
          "profit per day": 16212,
          "price ratio": 0.1271150559,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 20,
          "price": 127245,
          "profit per day": 16886,
          "price ratio": 0.1327046249,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 21,
          "price": 144218,
          "profit per day": 16364,
          "price ratio": 0.1134671123,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 22,
          "price": 273188,
          "profit per day": 25379,
          "price ratio": 0.09289939529,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
        {
          "card": "Équilibre Travail-Vie Personnelle",
          "level": 23,
          "price": 283475,
          "profit per day": 24190,
          "price ratio": 0.08533380369,
          "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
        },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 24,
            "price": 251434,
            "profit per day": 19089,
            "price ratio": 0.0759205199,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 25,
            "price": 315289,
            "profit per day": 36656,
            "price ratio": 0.1162615886,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 26,
            "price": 325359,
            "profit per day": 36571,
            "price ratio": 0.1124019929,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 27,
            "price": 218675,
            "profit per day": 29114,
            "price ratio": 0.1331382188,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 28,
            "price": 283632,
            "profit per day": 24343,
            "price ratio": 0.08582599989,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 29,
            "price": 344763,
            "profit per day": 29425,
            "price ratio": 0.08534848577,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Équilibre Travail-Vie Personnelle",
            "level": 30,
            "price": 296103,
            "profit per day": 30641,
            "price ratio": 0.1034808833,
            "unlock": "Unlock at Level 20 of Mindfulness et Méditation"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 1,
            "price": 2033,
            "profit per day": 276,
            "price ratio": 0.1357599606,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 2,
            "price": 3099,
            "profit per day": 577,
            "price ratio": 0.1861890933,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 3,
            "price": 7438,
            "profit per day": 1003,
            "price ratio": 0.1348480774,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 4,
            "price": 10507,
            "profit per day": 1037,
            "price ratio": 0.09869610736,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 5,
            "price": 20230,
            "profit per day": 2018,
            "price ratio": 0.09975284231,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 6,
            "price": 18608,
            "profit per day": 2287,
            "price ratio": 0.1229041273,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 7,
            "price": 33823,
            "profit per day": 3640,
            "price ratio": 0.1076190758,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 8,
            "price": 42582,
            "profit per day": 2482,
            "price ratio": 0.05828753934,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 9,
            "price": 28060,
            "profit per day": 3814,
            "price ratio": 0.1359230221,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 10,
            "price": 50847,
            "profit per day": 5938,
            "price ratio": 0.1167817177,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 11,
            "price": 28026,
            "profit per day": 8440,
            "price ratio": 0.3011489331,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 12,
            "price": 68754,
            "profit per day": 7976,
            "price ratio": 0.1160077959,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 13,
            "price": 71247,
            "profit per day": 6040,
            "price ratio": 0.08477549932,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 14,
            "price": 61875,
            "profit per day": 7476,
            "price ratio": 0.1208242424,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 15,
            "price": 113035,
            "profit per day": 9693,
            "price ratio": 0.08575220065,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 16,
            "price": 100755,
            "profit per day": 12889,
            "price ratio": 0.1279241725,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 17,
            "price": 149987,
            "profit per day": 15907,
            "price ratio": 0.1060558582,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 18,
            "price": 145957,
            "profit per day": 9767,
            "price ratio": 0.0669169687,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 19,
            "price": 114890,
            "profit per day": 17299,
            "price ratio": 0.1505701105,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 20,
            "price": 173116,
            "profit per day": 12600,
            "price ratio": 0.07278356709,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
          {
            "card": "Mindfulness et Méditation",
            "level": 21,
            "price": 116678,
            "profit per day": 15505,
            "price ratio": 0.132887091,
            "unlock": "Unlock at Level 25 of Gestion des Risques"
          },
            {
              "card": "Mindfulness et Méditation",
              "level": 22,
              "price": 111836,
              "profit per day": 15703,
              "price ratio": 0.1404109589,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 23,
              "price": 151375,
              "profit per day": 24047,
              "price ratio": 0.1588571429,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 24,
              "price": 275969,
              "profit per day": 26426,
              "price ratio": 0.09575713214,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 25,
              "price": 185538,
              "profit per day": 37110,
              "price ratio": 0.2000129354,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 26,
              "price": 211412,
              "profit per day": 26827,
              "price ratio": 0.1268944052,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 27,
              "price": 168389,
              "profit per day": 24353,
              "price ratio": 0.1446234612,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 28,
              "price": 318268,
              "profit per day": 37315,
              "price ratio": 0.1172439579,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 29,
              "price": 201887,
              "profit per day": 40911,
              "price ratio": 0.2026430627,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Mindfulness et Méditation",
              "level": 30,
              "price": 392185,
              "profit per day": 24722,
              "price ratio": 0.06303657713,
              "unlock": "Unlock at Level 25 of Gestion des Risques"
            },
            {
              "card": "Gestion des Risques",
              "level": 1,
              "price": 1658,
              "profit per day": 182,
              "price ratio": 0.1097708082,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 2,
              "price": 3627,
              "profit per day": 671,
              "price ratio": 0.1850013785,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 3,
              "price": 8582,
              "profit per day": 946,
              "price ratio": 0.1102307155,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 4,
              "price": 13075,
              "profit per day": 1036,
              "price ratio": 0.07923518164,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 5,
              "price": 17843,
              "profit per day": 2103,
              "price ratio": 0.1178613462,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 6,
              "price": 16101,
              "profit per day": 2013,
              "price ratio": 0.1250232905,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 7,
              "price": 24074,
              "profit per day": 3240,
              "price ratio": 0.1345850295,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 8,
              "price": 29761,
              "profit per day": 4524,
              "price ratio": 0.1520110211,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 9,
              "price": 26052,
              "profit per day": 5836,
              "price ratio": 0.2240135114,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 10,
              "price": 29505,
              "profit per day": 3712,
              "price ratio": 0.1258091849,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 11,
              "price": 53293,
              "profit per day": 6894,
              "price ratio": 0.1293603287,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 12,
              "price": 33747,
              "profit per day": 5368,
              "price ratio": 0.1590659911,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 13,
              "price": 70770,
              "profit per day": 5392,
              "price ratio": 0.07619047619,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 14,
              "price": 60701,
              "profit per day": 12589,
              "price ratio": 0.2073936179,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 15,
              "price": 50133,
              "profit per day": 11977,
              "price ratio": 0.238904514,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 16,
              "price": 96926,
              "profit per day": 8926,
              "price ratio": 0.09209087345,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 17,
              "price": 60605,
              "profit per day": 11114,
              "price ratio": 0.1833842092,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 18,
              "price": 141878,
              "profit per day": 16867,
              "price ratio": 0.1188838298,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 19,
              "price": 171520,
              "profit per day": 18875,
              "price ratio": 0.1100454757,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
            {
              "card": "Gestion des Risques",
              "level": 20,
              "price": 185433,
              "profit per day": 17789,
              "price ratio": 0.0959322235,
              "unlock": "Unlock at Level 30 of Planification et Objectifs"
            },
              {
                "card": "Gestion des Risques",
                "level": 21,
                "price": 76262,
                "profit per day": 24908,
                "price ratio": 0.326610894,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 22,
                "price": 186131,
                "profit per day": 23960,
                "price ratio": 0.1287265421,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 23,
                "price": 128941,
                "profit per day": 24737,
                "price ratio": 0.1918474341,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 24,
                "price": 302126,
                "profit per day": 16312,
                "price ratio": 0.0539907191,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 25,
                "price": 208137,
                "profit per day": 37342,
                "price ratio": 0.1794106766,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 26,
                "price": 205617,
                "profit per day": 28091,
                "price ratio": 0.1366180812,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 27,
                "price": 300117,
                "profit per day": 30145,
                "price ratio": 0.1004441601,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 28,
                "price": 288798,
                "profit per day": 37481,
                "price ratio": 0.1297827547,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 29,
                "price": 175869,
                "profit per day": 46665,
                "price ratio": 0.2653395425,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Gestion des Risques",
                "level": 30,
                "price": 253042,
                "profit per day": 20667,
                "price ratio": 0.08167418847,
                "unlock": "Unlock at Level 30 of Planification et Objectifs"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 1,
                "price": 4670,
                "profit per day": 707,
                "price ratio": 0.151391863,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 2,
                "price": 7255,
                "profit per day": 1333,
                "price ratio": 0.1837353549,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 3,
                "price": 15525,
                "profit per day": 2238,
                "price ratio": 0.1441545894,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 4,
                "price": 17129,
                "profit per day": 3486,
                "price ratio": 0.2035145076,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 5,
                "price": 38417,
                "profit per day": 5113,
                "price ratio": 0.1330921207,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 6,
                "price": 45938,
                "profit per day": 6457,
                "price ratio": 0.1405590143,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 7,
                "price": 65948,
                "profit per day": 8950,
                "price ratio": 0.135712986,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 8,
                "price": 66749,
                "profit per day": 7827,
                "price ratio": 0.1172601837,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 9,
                "price": 92906,
                "profit per day": 10247,
                "price ratio": 0.1102942759,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 10,
                "price": 91745,
                "profit per day": 11716,
                "price ratio": 0.1277017821,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 11,
                "price": 141146,
                "profit per day": 18454,
                "price ratio": 0.1307440523,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 12,
                "price": 80491,
                "profit per day": 17646,
                "price ratio": 0.2192294791,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 13,
                "price": 154239,
                "profit per day": 28096,
                "price ratio": 0.1821588574,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 14,
                "price": 114015,
                "profit per day": 32844,
                "price ratio": 0.2880673596,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 15,
                "price": 235684,
                "profit per day": 21140,
                "price ratio": 0.08969637311,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 16,
                "price": 172901,
                "profit per day": 32995,
                "price ratio": 0.1908317476,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 17,
                "price": 200488,
                "profit per day": 32814,
                "price ratio": 0.1636706436,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 18,
                "price": 119720,
                "profit per day": 48926,
                "price ratio": 0.4086702305,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 19,
                "price": 338013,
                "profit per day": 33841,
                "price ratio": 0.1001174511,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 20,
                "price": 270708,
                "profit per day": 49169,
                "price ratio": 0.1816311302,
                "unlock": "Always Unlocked"
              },
              {
                "card": "Nutrition Équilibrée",
                "level": 21,
                "price": 266887,
                "profit per day": 54551,
                "price ratio": 0.2043973667,
                "unlock": "Always Unlocked"
              },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 22,
                  "price": 459692,
                  "profit per day": 68619,
                  "price ratio": 0.1492716863,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 23,
                  "price": 413296,
                  "profit per day": 62104,
                  "price ratio": 0.1502651852,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 24,
                  "price": 236360,
                  "profit per day": 70038,
                  "price ratio": 0.2963191741,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 25,
                  "price": 281259,
                  "profit per day": 90303,
                  "price ratio": 0.3210670592,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 26,
                  "price": 322265,
                  "profit per day": 77514,
                  "price ratio": 0.2405287574,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 27,
                  "price": 756867,
                  "profit per day": 62433,
                  "price ratio": 0.08248873316,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 28,
                  "price": 815984,
                  "profit per day": 69525,
                  "price ratio": 0.08520387655,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 29,
                  "price": 484131,
                  "profit per day": 111732,
                  "price ratio": 0.2307887741,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Nutrition Équilibrée",
                  "level": 30,
                  "price": 742303,
                  "profit per day": 95584,
                  "price ratio": 0.1287668243,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 1,
                  "price": 3045,
                  "profit per day": 694,
                  "price ratio": 0.2279146141,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 2,
                  "price": 9066,
                  "profit per day": 1443,
                  "price ratio": 0.1591661152,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 3,
                  "price": 13705,
                  "profit per day": 2504,
                  "price ratio": 0.1827070412,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 4,
                  "price": 29820,
                  "profit per day": 3023,
                  "price ratio": 0.1013749162,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 5,
                  "price": 29479,
                  "profit per day": 4954,
                  "price ratio": 0.1680518335,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 6,
                  "price": 30712,
                  "profit per day": 5311,
                  "price ratio": 0.1729291482,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 7,
                  "price": 48898,
                  "profit per day": 7523,
                  "price ratio": 0.1538508732,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 8,
                  "price": 61760,
                  "profit per day": 7492,
                  "price ratio": 0.1213082902,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 9,
                  "price": 78571,
                  "profit per day": 8928,
                  "price ratio": 0.1136297107,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 10,
                  "price": 92595,
                  "profit per day": 12914,
                  "price ratio": 0.1394675738,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 11,
                  "price": 136008,
                  "profit per day": 18447,
                  "price ratio": 0.1356317275,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 12,
                  "price": 151983,
                  "profit per day": 17050,
                  "price ratio": 0.1121835995,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 13,
                  "price": 170314,
                  "profit per day": 20877,
                  "price ratio": 0.1225794709,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 14,
                  "price": 213915,
                  "profit per day": 30687,
                  "price ratio": 0.1434541757,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 15,
                  "price": 207983,
                  "profit per day": 32928,
                  "price ratio": 0.158320632,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 16,
                  "price": 195128,
                  "profit per day": 18419,
                  "price ratio": 0.09439444877,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 17,
                  "price": 253865,
                  "profit per day": 45365,
                  "price ratio": 0.1786973391,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 18,
                  "price": 244056,
                  "profit per day": 27688,
                  "price ratio": 0.1134493723,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 19,
                  "price": 386186,
                  "profit per day": 39172,
                  "price ratio": 0.1014329882,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 20,
                  "price": 203443,
                  "profit per day": 44734,
                  "price ratio": 0.2198846851,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 21,
                  "price": 319155,
                  "profit per day": 66594,
                  "price ratio": 0.2086572355,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 22,
                  "price": 376732,
                  "profit per day": 41757,
                  "price ratio": 0.1108400667,
                  "unlock": "Always Unlocked"
                },
                {
                  "card": "Sommeil Réparateur",
                  "level": 23,
                  "price": 552758,
                  "profit per day": 54165,
                  "price ratio": 0.09799044066,
                  "unlock": "Always Unlocked"
                },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 24,
                    "price": 587968,
                    "profit per day": 50692,
                    "price ratio": 0.08621557636,
                    "unlock": "Always Unlocked"
                  },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 25,
                    "price": 191671,
                    "profit per day": 72175,
                    "price ratio": 0.3765567039,
                    "unlock": "Always Unlocked"
                  },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 26,
                    "price": 460478,
                    "profit per day": 77154,
                    "price ratio": 0.1675519786,
                    "unlock": "Always Unlocked"
                  },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 27,
                    "price": 682950,
                    "profit per day": 53903,
                    "price ratio": 0.07892671499,
                    "unlock": "Always Unlocked"
                  },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 28,
                    "price": 507644,
                    "profit per day": 54006,
                    "price ratio": 0.1063855773,
                    "unlock": "Always Unlocked"
                  },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 29,
                    "price": 667958,
                    "profit per day": 66806,
                    "price ratio": 0.1000152704,
                    "unlock": "Always Unlocked"
                  },
                  {
                    "card": "Sommeil Réparateur",
                    "level": 30,
                    "price": 675134,
                    "profit per day": 57690,
                    "price ratio": 0.08544970332,
                    "unlock": "Always Unlocked"
                  }
                ]

export const pah = [
  {
    name: 'Continuing education',
    id: 'Formation Continue',
    image: 'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879685/Ajouter_un_titre_119_1_quukpe.png'
  },
  {
    id: 'Planification et Objectifs',
    name:'Planning and Objectives',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879685/Ajouter_un_titre_121_1_mva1dp.png',
  },
  {
    id: 'Éducation Financière',
    name:'Financial education',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879686/Ajouter_un_titre_120_1_p4a55x.png',
  },
  {
    id: 'Équilibre Travail-Vie Personnelle',
    name:'Personal work life balance',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879685/Ajouter_un_titre_122_1_rpgema.png',
  },
  {
    id: 'Sommeil Réparateur',
    name:'Restorative sleep',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879684/Ajouter_un_titre_116_1_avhb9g.png',
  },
  {
    id: 'Gestion des Risques',
    name:'Risk management',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879684/Ajouter_un_titre_117_1_gxta1b.png',
  },
  {
    id: 'Mindfulness et Méditation',
    name:'Mindfull and meditation',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879684/Ajouter_un_titre_118_1_pqjbsx.png',
  },
  {
    id: 'Nutrition Équilibrée',
    name:'Balanced nutrition',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879684/Ajouter_un_titre_114_1_n8at7q.png',
  }
]


export const capman = [
  {
    name: 'Bull Market',
    id: 'Bull Market',
    image: 'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879684/Ajouter_un_titre_110_1_bcdrht.png'
  },
  {
    id: 'FOMO (Fear of Missing Out)',
    name:'FOMO (Fear of Missing Out)',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879684/Ajouter_un_titre_111_1_pdtbon.png',
  },
  {
    id: 'CEX (Centralized Exchange)',
    name:'CEX (Centralized Exchange)',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879688/CEX_new25u.png',
  },
  {
    id: 'DEX (Decentralized Exchange)',
    name:'DEX (Decentralized Exchange)',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879689/dex_knmcz3.png',
  },
  {
    id: 'Algorithmic Trading',
    name:'Algorithmic Trading',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879686/Ajouter_un_titre_129_1_pema96.png',
  },
  {
    id: 'Atomic Swaps',
    name:'Atomic Swaps',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879687/atomic_swap_omovlk.png',
  },
  {
    id: 'Yield Farming',
    name:'Yield Farming',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879688/Ajouter_un_titre_130_1_fj8v9v.png',
  },
  {
    id: 'Decentralized Autonomous Organization (DAO)',
    name:'Decentralized Autonomous Organization (DAO)',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879688/dao_ufebm1.png',
  },
  {
    id: 'HODL',
    name:'HODL',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879691/hodl_csxwx1.png',
  },
  {
    id: 'Liquidity Mining',
    name:'Liquidity Mining',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879691/mining_hzokna.png',
  },
  {
    id: 'Zero-Knowledge Proofs (ZKP)',
    name:'Zero-Knowledge Proofs (ZKP)',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879693/zkp_l20xfx.png',
  },
  {
    id: 'Delegated Proof of Stake (DPoS)',
    name:'Delegated Proof of Stake (DPoS)',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879689/dpos_wlep5a.png',
  },
  {
    id: 'Whale',
    name:'Whale',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879692/whale_wypexy.png',
  },
  {
    id: 'Fork',
    name:'Fork',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879690/fork_m0cm4m.png',
  },
  {
    id: 'Hashrate',
    name:'Hashrate',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879691/hashrate_i4efby.png',
  },
  {
    id: 'Sharding',
    name:'Sharding',
    image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722879692/shrading_xrh6kj.png',
  },
]

export const capman_levels = [
  {
    "card": "Bear Market",
    "level": 1,
    "price": 794,
    "profit per day": 64,
    "unlock": "None"
  },
  {
    "card": "Bear Market",
    "level": 2,
    "price": 1853,
    "profit per day": 130,
    "unlock": "Bear Market Level 1"
  },
  {
    "card": "Bear Market",
    "level": 3,
    "price": 2627,
    "profit per day": 255,
    "unlock": "Bear Market Level 2"
  },
  {
    "card": "Bear Market",
    "level": 4,
    "price": 4089,
    "profit per day": 381,
    "unlock": "Bear Market Level 3"
  },
  {
    "card": "Bear Market",
    "level": 5,
    "price": 5654,
    "profit per day": 497,
    "unlock": "Bear Market Level 4"
  },
  {
    "card": "Bear Market",
    "level": 6,
    "price": 9684,
    "profit per day": 911,
    "unlock": "Bear Market Level 5"
  },
  {
    "card": "Bear Market",
    "level": 7,
    "price": 10894,
    "profit per day": 1117,
    "unlock": "Bear Market Level 6"
  },
  {
    "card": "Bear Market",
    "level": 8,
    "price": 10853,
    "profit per day": 902,
    "unlock": "Bear Market Level 7"
  },
  {
    "card": "Bear Market",
    "level": 9,
    "price": 14336,
    "profit per day": 1183,
    "unlock": "Bear Market Level 8"
  },
  {
    "card": "Bear Market",
    "level": 10,
    "price": 19487,
    "profit per day": 1966,
    "unlock": "Bear Market Level 9"
  },
  {
    "card": "Bear Market",
    "level": 11,
    "price": 19858,
    "profit per day": 1932,
    "unlock": "Bear Market Level 10"
  },
  {
    "card": "Bear Market",
    "level": 12,
    "price": 26743,
    "profit per day": 2014,
    "unlock": "Bear Market Level 11"
  },
  {
    "card": "Bear Market",
    "level": 13,
    "price": 26798,
    "profit per day": 2047,
    "unlock": "Bear Market Level 12"
  },
  {
    "card": "Bear Market",
    "level": 14,
    "price": 25324,
    "profit per day": 1977,
    "unlock": "Bear Market Level 13"
  },
  {
    "card": "Bear Market",
    "level": 15,
    "price": 33921,
    "profit per day": 3825,
    "unlock": "Bear Market Level 14"
  },
  {
    "card": "Bear Market",
    "level": 16,
    "price": 44732,
    "profit per day": 4710,
    "unlock": "Bear Market Level 15"
  },
  {
    "card": "Bear Market",
    "level": 17,
    "price": 48578,
    "profit per day": 3122,
    "unlock": "Bear Market Level 16"
  },
  {
    "card": "Bear Market",
    "level": 18,
    "price": 36156,
    "profit per day": 6158,
    "unlock": "Bear Market Level 17"
  },
  {
    "card": "Bear Market",
    "level": 19,
    "price": 66817,
    "profit per day": 5626,
    "unlock": "Bear Market Level 18"
  },
  {
    "card": "Bear Market",
    "level": 20,
    "price": 33504,
    "profit per day": 4039,
    "unlock": "Bear Market Level 19"
  },
  {
    "card": "Bear Market",
    "level": 21,
    "price": 69746,
    "profit per day": 4905,
    "unlock": "Bear Market Level 20"
  },
  {
    "card": "Bear Market",
    "level": 22,
    "price": 43692,
    "profit per day": 6938,
    "unlock": "Bear Market Level 21"
  },
  {
    "card": "Bear Market",
    "level": 23,
    "price": 56302,
    "profit per day": 8370,
    "unlock": "Bear Market Level 22"
  },
  {
    "card": "Bear Market",
    "level": 24,
    "price": 58142,
    "profit per day": 6228,
    "unlock": "Bear Market Level 23"
  },
  {
    "card": "Bear Market",
    "level": 25,
    "price": 99787,
    "profit per day": 7694,
    "unlock": "Bear Market Level 24"
  },
  {
    "card": "Bear Market",
    "level": 26,
    "price": 80889,
    "profit per day": 11707,
    "unlock": "Bear Market Level 25"
  },
  {
    "card": "Bear Market",
    "level": 27,
    "price": 66010,
    "profit per day": 7598,
    "unlock": "Bear Market Level 26"
  },
  {
    "card": "Bear Market",
    "level": 28,
    "price": 119687,
    "profit per day": 8637,
    "unlock": "Bear Market Level 27"
  },
  {
    "card": "Bear Market",
    "level": 29,
    "price": 124377,
    "profit per day": 11633,
    "unlock": "Bear Market Level 28"
  },
  {
    "card": "Bear Market",
    "level": 30,
    "price": 116954,
    "profit per day": 8315,
    "unlock": "Bear Market Level 29"
  },
  {
    "card": "Bull Market",
    "level": 1,
    "price": 702,
    "profit per day": 58,
    "unlock": "Bear Market Level 5"
  },
  {
    "card": "Bull Market",
    "level": 2,
    "price": 1772,
    "profit per day": 187,
    "unlock": "Bull Market Level 1"
  },
  {
    "card": "Bull Market",
    "level": 3,
    "price": 2468,
    "profit per day": 266,
    "unlock": "Bull Market Level 2"
  },
  {
    "card": "Bull Market",
    "level": 4,
    "price": 4370,
    "profit per day": 481,
    "unlock": "Bull Market Level 3"
  },
  {
    "card": "Bull Market",
    "level": 5,
    "price": 5016,
    "profit per day": 484,
    "unlock": "Bull Market Level 4"
  },
  {
    "card": "Bull Market",
    "level": 6,
    "price": 6959,
    "profit per day": 521,
    "unlock": "Bull Market Level 5"
  },
  {
    "card": "Bull Market",
    "level": 7,
    "price": 7250,
    "profit per day": 849,
    "unlock": "Bull Market Level 6"
  },
  {
    "card": "Bull Market",
    "level": 8,
    "price": 11501,
    "profit per day": 1226,
    "unlock": "Bull Market Level 7"
  },
  {
    "card": "Bull Market",
    "level": 9,
    "price": 17559,
    "profit per day": 1076,
    "unlock": "Bull Market Level 8"
  },
  {
    "card": "Bull Market",
    "level": 10,
    "price": 20470,
    "profit per day": 2130,
    "unlock": "Bull Market Level 9"
  },
  {
    "card": "Bull Market",
    "level": 11,
    "price": 19111,
    "profit per day": 1723,
    "unlock": "Bull Market Level 10"
  },
  {
    "card": "Bull Market",
    "level": 12,
    "price": 16833,
    "profit per day": 1517,
    "unlock": "Bull Market Level 11"
  },
    {
      "card": "Bull Market",
      "level": 13,
      "price": 26090,
      "profit per day": 2115,
      "unlock": "Bull Market Level 12"
    },
    {
      "card": "Bull Market",
      "level": 14,
      "price": 29887,
      "profit per day": 2766,
      "unlock": "Bull Market Level 13"
    },
    {
      "card": "Bull Market",
      "level": 15,
      "price": 34593,
      "profit per day": 3848,
      "unlock": "Bull Market Level 14"
    },
    {
      "card": "Bull Market",
      "level": 16,
      "price": 29340,
      "profit per day": 4720,
      "unlock": "Bull Market Level 15"
    },
    {
      "card": "Bull Market",
      "level": 17,
      "price": 40121,
      "profit per day": 2818,
      "unlock": "Bull Market Level 16"
    },
    {
      "card": "Bull Market",
      "level": 18,
      "price": 55021,
      "profit per day": 5200,
      "unlock": "Bull Market Level 17"
    },
    {
      "card": "Bull Market",
      "level": 19,
      "price": 38260,
      "profit per day": 3715,
      "unlock": "Bull Market Level 18"
    },
    {
      "card": "Bull Market",
      "level": 20,
      "price": 58686,
      "profit per day": 4581,
      "unlock": "Bull Market Level 19"
    },
    {
      "card": "Bull Market",
      "level": 21,
      "price": 46792,
      "profit per day": 4059,
      "unlock": "Bull Market Level 20"
    },
    {
      "card": "Bull Market",
      "level": 22,
      "price": 69063,
      "profit per day": 6903,
      "unlock": "Bull Market Level 21"
    },
    {
      "card": "Bull Market",
      "level": 23,
      "price": 75345,
      "profit per day": 5441,
      "unlock": "Bull Market Level 22"
    },
    {
      "card": "Bull Market",
      "level": 24,
      "price": 93762,
      "profit per day": 8902,
      "unlock": "Bull Market Level 23"
    },
    {
      "card": "Bull Market",
      "level": 25,
      "price": 85827,
      "profit per day": 8282,
      "unlock": "Bull Market Level 24"
    },
    {
      "card": "Bull Market",
      "level": 26,
      "price": 80412,
      "profit per day": 6225,
      "unlock": "Bull Market Level 25"
    },
    {
      "card": "Bull Market",
      "level": 27,
      "price": 79334,
      "profit per day": 10254,
      "unlock": "Bull Market Level 26"
    },
    {
      "card": "Bull Market",
      "level": 28,
      "price": 66820,
      "profit per day": 9226,
      "unlock": "Bull Market Level 27"
    },
    {
      "card": "Bull Market",
      "level": 29,
      "price": 90253,
      "profit per day": 14025,
      "unlock": "Bull Market Level 28"
    },
    {
      "card": "Bull Market",
      "level": 30,
      "price": 89264,
      "profit per day": 11784,
      "unlock": "Bull Market Level 29"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 1,
      "price": 673,
      "profit per day": 72,
      "unlock": "Bull Market Level 3"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 2,
      "price": 1497,
      "profit per day": 203,
      "unlock": "FOMO (Fear of Missing Out) Level 1"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 3,
      "price": 2992,
      "profit per day": 310,
      "unlock": "FOMO (Fear of Missing Out) Level 2"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 4,
      "price": 3759,
      "profit per day": 382,
      "unlock": "FOMO (Fear of Missing Out) Level 3"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 5,
      "price": 6323,
      "profit per day": 501,
      "unlock": "FOMO (Fear of Missing Out) Level 4"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 6,
      "price": 9338,
      "profit per day": 945,
      "unlock": "FOMO (Fear of Missing Out) Level 5"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 7,
      "price": 10645,
      "profit per day": 909,
      "unlock": "FOMO (Fear of Missing Out) Level 6"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 8,
      "price": 11578,
      "profit per day": 1045,
      "unlock": "FOMO (Fear of Missing Out) Level 7"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 9,
      "price": 16367,
      "profit per day": 1104,
      "unlock": "FOMO (Fear of Missing Out) Level 8"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 10,
      "price": 12986,
      "profit per day": 1555,
      "unlock": "FOMO (Fear of Missing Out) Level 9"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 11,
      "price": 13320,
      "profit per day": 1779,
      "unlock": "FOMO (Fear of Missing Out) Level 10"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 12,
      "price": 19184,
      "profit per day": 2070,
      "unlock": "FOMO (Fear of Missing Out) Level 11"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 13,
      "price": 26661,
      "profit per day": 2308,
      "unlock": "FOMO (Fear of Missing Out) Level 12"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 14,
      "price": 21948,
      "profit per day": 2286,
      "unlock": "FOMO (Fear of Missing Out) Level 13"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 15,
      "price": 42186,
      "profit per day": 4014,
      "unlock": "FOMO (Fear of Missing Out) Level 14"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 16,
      "price": 35075,
      "profit per day": 2358,
      "unlock": "FOMO (Fear of Missing Out) Level 15"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 17,
      "price": 48780,
      "profit per day": 4910,
      "unlock": "FOMO (Fear of Missing Out) Level 16"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 18,
      "price": 45797,
      "profit per day": 5954,
      "unlock": "FOMO (Fear of Missing Out) Level 17"
    },
    {
      "card": "FOMO (Fear of Missing Out)",
      "level": 19,
      "price": 52263,
      "profit per day": 6000,
      "unlock": "FOMO (Fear of Missing Out) Level 18"
    },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 20,
        "price": 58105,
        "profit per day": 6494,
        "unlock": "FOMO (Fear of Missing Out) Level 19"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 21,
        "price": 72238,
        "profit per day": 3934,
        "unlock": "FOMO (Fear of Missing Out) Level 20"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 22,
        "price": 60510,
        "profit per day": 7941,
        "unlock": "FOMO (Fear of Missing Out) Level 21"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 23,
        "price": 67200,
        "profit per day": 6812,
        "unlock": "FOMO (Fear of Missing Out) Level 22"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 24,
        "price": 57421,
        "profit per day": 7903,
        "unlock": "FOMO (Fear of Missing Out) Level 23"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 25,
        "price": 59179,
        "profit per day": 8367,
        "unlock": "FOMO (Fear of Missing Out) Level 24"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 26,
        "price": 114711,
        "profit per day": 9196,
        "unlock": "FOMO (Fear of Missing Out) Level 25"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 27,
        "price": 121085,
        "profit per day": 10055,
        "unlock": "FOMO (Fear of Missing Out) Level 26"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 28,
        "price": 76537,
        "profit per day": 11257,
        "unlock": "FOMO (Fear of Missing Out) Level 27"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 29,
        "price": 124804,
        "profit per day": 13364,
        "unlock": "FOMO (Fear of Missing Out) Level 28"
      },
      {
        "card": "FOMO (Fear of Missing Out)",
        "level": 30,
        "price": 97793,
        "profit per day": 12583,
        "unlock": "FOMO (Fear of Missing Out) Level 29"
      },
      {
        "card": "HODL",
        "level": 1,
        "price": 791,
        "profit per day": 76,
        "unlock": "FOMO (Fear of Missing Out) Level 5"
      },
      {
        "card": "HODL",
        "level": 2,
        "price": 1828,
        "profit per day": 137,
        "unlock": "HODL Level 1"
      },
      {
        "card": "HODL",
        "level": 3,
        "price": 2715,
        "profit per day": 295,
        "unlock": "HODL Level 2"
      },
      {
        "card": "HODL",
        "level": 4,
        "price": 4041,
        "profit per day": 472,
        "unlock": "HODL Level 3"
      },
      {
        "card": "HODL",
        "level": 5,
        "price": 6905,
        "profit per day": 474,
        "unlock": "HODL Level 4"
      },
      {
        "card": "HODL",
        "level": 6,
        "price": 7644,
        "profit per day": 895,
        "unlock": "HODL Level 5"
      },
      {
        "card": "HODL",
        "level": 7,
        "price": 11821,
        "profit per day": 948,
        "unlock": "HODL Level 6"
      },
      {
        "card": "HODL",
        "level": 8,
        "price": 8872,
        "profit per day": 1230,
        "unlock": "HODL Level 7"
      },
      {
        "card": "HODL",
        "level": 9,
        "price": 15880,
        "profit per day": 1101,
        "unlock": "HODL Level 8"
      },
      {
        "card": "HODL",
        "level": 10,
        "price": 20253,
        "profit per day": 2036,
        "unlock": "HODL Level 9"
      },
      {
        "card": "HODL",
        "level": 11,
        "price": 16122,
        "profit per day": 1626,
        "unlock": "HODL Level 10"
      },
      {
        "card": "HODL",
        "level": 12,
        "price": 16144,
        "profit per day": 1407,
        "unlock": "HODL Level 11"
      },
      {
        "card": "HODL",
        "level": 13,
        "price": 27237,
        "profit per day": 1857,
        "unlock": "HODL Level 12"
      },
      {
        "card": "HODL",
        "level": 14,
        "price": 23171,
        "profit per day": 3669,
        "unlock": "HODL Level 13"
      },
      {
        "card": "HODL",
        "level": 15,
        "price": 38609,
        "profit per day": 3516,
        "unlock": "HODL Level 14"
      },
      {
        "card": "HODL",
        "level": 16,
        "price": 44103,
        "profit per day": 3233,
        "unlock": "HODL Level 15"
      },
      {
        "card": "HODL",
        "level": 17,
        "price": 30748,
        "profit per day": 4865,
        "unlock": "HODL Level 16"
      },
      {
        "card": "HODL",
        "level": 18,
        "price": 55587,
        "profit per day": 3753,
        "unlock": "HODL Level 17"
      },
      {
        "card": "HODL",
        "level": 19,
        "price": 40316,
        "profit per day": 4332,
        "unlock": "HODL Level 18"
      },
      {
        "card": "HODL",
        "level": 20,
        "price": 38925,
        "profit per day": 3995,
        "unlock": "HODL Level 19"
      },
      {
        "card": "HODL",
        "level": 21,
        "price": 61627,
        "profit per day": 4418,
        "unlock": "HODL Level 20"
      },
      {
        "card": "HODL",
        "level": 22,
        "price": 60453,
        "profit per day": 7487,
        "unlock": "HODL Level 21"
      },
      {
        "card": "HODL",
        "level": 23,
        "price": 46879,
        "profit per day": 7208,
        "unlock": "HODL Level 22"
      },
      {
        "card": "HODL",
        "level": 24,
        "price": 99527,
        "profit per day": 5893,
        "unlock": "HODL Level 23"
      },
      {
        "card": "HODL",
        "level": 25,
        "price": 99938,
        "profit per day": 7880,
        "unlock": "HODL Level 24"
      },
      {
        "card": "HODL",
        "level": 26,
        "price": 90979,
        "profit per day": 6268,
        "unlock": "HODL Level 25"
      },
      {
        "card": "HODL",
        "level": 27,
        "price": 57314,
        "profit per day": 8028,
        "unlock": "HODL Level 26"
      },
        {
          "card": "HODL",
          "level": 28,
          "price": 99649,
          "profit per day": 9333,
          "unlock": "HODL Level 27"
        },
        {
          "card": "HODL",
          "level": 29,
          "price": 83961,
          "profit per day": 8810,
          "unlock": "HODL Level 28"
        },
        {
          "card": "HODL",
          "level": 30,
          "price": 122150,
          "profit per day": 10087,
          "unlock": "HODL Level 29"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 1,
          "price": 1361,
          "profit per day": 213,
          "unlock": "None"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 2,
          "price": 3567,
          "profit per day": 398,
          "unlock": "CEX (Centralized Exchange) Level 1"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 3,
          "price": 5579,
          "profit per day": 611,
          "unlock": "CEX (Centralized Exchange) Level 2"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 4,
          "price": 8627,
          "profit per day": 942,
          "unlock": "CEX (Centralized Exchange) Level 3"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 5,
          "price": 12864,
          "profit per day": 1144,
          "unlock": "CEX (Centralized Exchange) Level 4"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 6,
          "price": 12634,
          "profit per day": 1603,
          "unlock": "CEX (Centralized Exchange) Level 5"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 7,
          "price": 19429,
          "profit per day": 2175,
          "unlock": "CEX (Centralized Exchange) Level 6"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 8,
          "price": 19542,
          "profit per day": 2185,
          "unlock": "CEX (Centralized Exchange) Level 7"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 9,
          "price": 27030,
          "profit per day": 3368,
          "unlock": "CEX (Centralized Exchange) Level 8"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 10,
          "price": 44813,
          "profit per day": 4164,
          "unlock": "CEX (Centralized Exchange) Level 9"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 11,
          "price": 44561,
          "profit per day": 4067,
          "unlock": "CEX (Centralized Exchange) Level 10"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 12,
          "price": 41672,
          "profit per day": 5625,
          "unlock": "CEX (Centralized Exchange) Level 11"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 13,
          "price": 44837,
          "profit per day": 6061,
          "unlock": "CEX (Centralized Exchange) Level 12"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 14,
          "price": 62757,
          "profit per day": 8576,
          "unlock": "CEX (Centralized Exchange) Level 13"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 15,
          "price": 45247,
          "profit per day": 8128,
          "unlock": "CEX (Centralized Exchange) Level 14"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 16,
          "price": 79251,
          "profit per day": 11525,
          "unlock": "CEX (Centralized Exchange) Level 15"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 17,
          "price": 97210,
          "profit per day": 12478,
          "unlock": "CEX (Centralized Exchange) Level 16"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 18,
          "price": 67385,
          "profit per day": 12700,
          "unlock": "CEX (Centralized Exchange) Level 17"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 19,
          "price": 84647,
          "profit per day": 9748,
          "unlock": "CEX (Centralized Exchange) Level 18"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 20,
          "price": 122303,
          "profit per day": 9247,
          "unlock": "CEX (Centralized Exchange) Level 19"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 21,
          "price": 127521,
          "profit per day": 17123,
          "unlock": "CEX (Centralized Exchange) Level 20"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 22,
          "price": 131296,
          "profit per day": 14387,
          "unlock": "CEX (Centralized Exchange) Level 21"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 23,
          "price": 183531,
          "profit per day": 21517,
          "unlock": "CEX (Centralized Exchange) Level 22"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 24,
          "price": 101623,
          "profit per day": 10719,
          "unlock": "CEX (Centralized Exchange) Level 23"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 25,
          "price": 163203,
          "profit per day": 19580,
          "unlock": "CEX (Centralized Exchange) Level 24"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 26,
          "price": 164267,
          "profit per day": 23001,
          "unlock": "CEX (Centralized Exchange) Level 25"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 27,
          "price": 192089,
          "profit per day": 12973,
          "unlock": "CEX (Centralized Exchange) Level 26"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 28,
          "price": 205870,
          "profit per day": 32404,
          "unlock": "CEX (Centralized Exchange) Level 27"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 29,
          "price": 148040,
          "profit per day": 28824,
          "unlock": "CEX (Centralized Exchange) Level 28"
        },
        {
          "card": "CEX (Centralized Exchange)",
          "level": 30,
          "price": 175779,
          "profit per day": 20934,
          "unlock": "CEX (Centralized Exchange) Level 29"
        },
        {
          "card": "DEX (Decentralized Exchange)",
          "level": 1,
          "price": 1395,
          "profit per day": 140,
          "unlock": "CEX (Centralized Exchange) Level 5"
        },
        {
          "card": "DEX (Decentralized Exchange)",
          "level": 2,
          "price": 2963,
          "profit per day": 456,
          "unlock": "DEX (Decentralized Exchange) Level 1"
        },
        {
          "card": "DEX (Decentralized Exchange)",
          "level": 3,
          "price": 5424,
          "profit per day": 504,
          "unlock": "DEX (Decentralized Exchange) Level 2"
        },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 4,
            "price": 9805,
            "profit per day": 1035,
            "unlock": "DEX (Decentralized Exchange) Level 3"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 5,
            "price": 10387,
            "profit per day": 1213,
            "unlock": "DEX (Decentralized Exchange) Level 4"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 6,
            "price": 17341,
            "profit per day": 1606,
            "unlock": "DEX (Decentralized Exchange) Level 5"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 7,
            "price": 16482,
            "profit per day": 1954,
            "unlock": "DEX (Decentralized Exchange) Level 6"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 8,
            "price": 19292,
            "profit per day": 2530,
            "unlock": "DEX (Decentralized Exchange) Level 7"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 9,
            "price": 23203,
            "profit per day": 3811,
            "unlock": "DEX (Decentralized Exchange) Level 8"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 10,
            "price": 29735,
            "profit per day": 2811,
            "unlock": "DEX (Decentralized Exchange) Level 9"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 11,
            "price": 29810,
            "profit per day": 4906,
            "unlock": "DEX (Decentralized Exchange) Level 10"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 12,
            "price": 55880,
            "profit per day": 5417,
            "unlock": "DEX (Decentralized Exchange) Level 11"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 13,
            "price": 61287,
            "profit per day": 6386,
            "unlock": "DEX (Decentralized Exchange) Level 12"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 14,
            "price": 66494,
            "profit per day": 5409,
            "unlock": "DEX (Decentralized Exchange) Level 13"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 15,
            "price": 82685,
            "profit per day": 8877,
            "unlock": "DEX (Decentralized Exchange) Level 14"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 16,
            "price": 65210,
            "profit per day": 10509,
            "unlock": "DEX (Decentralized Exchange) Level 15"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 17,
            "price": 106907,
            "profit per day": 7000,
            "unlock": "DEX (Decentralized Exchange) Level 16"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 18,
            "price": 111943,
            "profit per day": 11844,
            "unlock": "DEX (Decentralized Exchange) Level 17"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 19,
            "price": 107102,
            "profit per day": 8585,
            "unlock": "DEX (Decentralized Exchange) Level 18"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 20,
            "price": 143844,
            "profit per day": 15611,
            "unlock": "DEX (Decentralized Exchange) Level 19"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 21,
            "price": 118195,
            "profit per day": 10805,
            "unlock": "DEX (Decentralized Exchange) Level 20"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 22,
            "price": 127868,
            "profit per day": 16634,
            "unlock": "DEX (Decentralized Exchange) Level 21"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 23,
            "price": 109901,
            "profit per day": 19245,
            "unlock": "DEX (Decentralized Exchange) Level 22"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 24,
            "price": 164161,
            "profit per day": 19775,
            "unlock": "DEX (Decentralized Exchange) Level 23"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 25,
            "price": 158118,
            "profit per day": 20748,
            "unlock": "DEX (Decentralized Exchange) Level 24"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 26,
            "price": 98920,
            "profit per day": 19513,
            "unlock": "DEX (Decentralized Exchange) Level 25"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 27,
            "price": 184743,
            "profit per day": 18288,
            "unlock": "DEX (Decentralized Exchange) Level 26"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 28,
            "price": 153666,
            "profit per day": 24567,
            "unlock": "DEX (Decentralized Exchange) Level 27"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 29,
            "price": 210441,
            "profit per day": 35329,
            "unlock": "DEX (Decentralized Exchange) Level 28"
          },
          {
            "card": "DEX (Decentralized Exchange)",
            "level": 30,
            "price": 240721,
            "profit per day": 27264,
            "unlock": "DEX (Decentralized Exchange) Level 29"
          },
          {
            "card": "Whale",
            "level": 1,
            "price": 1481,
            "profit per day": 184,
            "unlock": "DEX (Decentralized Exchange) Level 7"
          },
          {
            "card": "Whale",
            "level": 2,
            "price": 3090,
            "profit per day": 329,
            "unlock": "Whale Level 1"
          },
          {
            "card": "Whale",
            "level": 3,
            "price": 6524,
            "profit per day": 614,
            "unlock": "Whale Level 2"
          },
          {
            "card": "Whale",
            "level": 4,
            "price": 7171,
            "profit per day": 1205,
            "unlock": "Whale Level 3"
          },
          {
            "card": "Whale",
            "level": 5,
            "price": 11067,
            "profit per day": 1520,
            "unlock": "Whale Level 4"
          },
          {
            "card": "Whale",
            "level": 6,
            "price": 14412,
            "profit per day": 2131,
            "unlock": "Whale Level 5"
          },
          {
            "card": "Whale",
            "level": 7,
            "price": 15249,
            "profit per day": 1758,
            "unlock": "Whale Level 6"
          },
          {
            "card": "Whale",
            "level": 8,
            "price": 17081,
            "profit per day": 1950,
            "unlock": "Whale Level 7"
          },
          {
            "card": "Whale",
            "level": 9,
            "price": 36656,
            "profit per day": 4038,
            "unlock": "Whale Level 8"
          },
          {
            "card": "Whale",
            "level": 10,
            "price": 38371,
            "profit per day": 3557,
            "unlock": "Whale Level 9"
          },
            {
              "card": "Whale",
              "level": 11,
              "price": 49416,
              "profit per day": 5859,
              "unlock": "Whale Level 10"
            },
            {
              "card": "Whale",
              "level": 12,
              "price": 37533,
              "profit per day": 5243,
              "unlock": "Whale Level 11"
            },
            {
              "card": "Whale",
              "level": 13,
              "price": 43128,
              "profit per day": 6202,
              "unlock": "Whale Level 12"
            },
            {
              "card": "Whale",
              "level": 14,
              "price": 52245,
              "profit per day": 4683,
              "unlock": "Whale Level 13"
            },
            {
              "card": "Whale",
              "level": 15,
              "price": 59321,
              "profit per day": 5319,
              "unlock": "Whale Level 14"
            },
            {
              "card": "Whale",
              "level": 16,
              "price": 92721,
              "profit per day": 6648,
              "unlock": "Whale Level 15"
            },
            {
              "card": "Whale",
              "level": 17,
              "price": 69334,
              "profit per day": 11348,
              "unlock": "Whale Level 16"
            },
            {
              "card": "Whale",
              "level": 18,
              "price": 80802,
              "profit per day": 9143,
              "unlock": "Whale Level 17"
            },
            {
              "card": "Whale",
              "level": 19,
              "price": 108565,
              "profit per day": 11461,
              "unlock": "Whale Level 18"
            },
            {
              "card": "Whale",
              "level": 20,
              "price": 108848,
              "profit per day": 7757,
              "unlock": "Whale Level 19"
            },
            {
              "card": "Whale",
              "level": 21,
              "price": 141753,
              "profit per day": 16727,
              "unlock": "Whale Level 20"
            },
            {
              "card": "Whale",
              "level": 22,
              "price": 115999,
              "profit per day": 14641,
              "unlock": "Whale Level 21"
            },
            {
              "card": "Whale",
              "level": 23,
              "price": 113567,
              "profit per day": 15300,
              "unlock": "Whale Level 22"
            },
            {
              "card": "Whale",
              "level": 24,
              "price": 191788,
              "profit per day": 19982,
              "unlock": "Whale Level 23"
            },
            {
              "card": "Whale",
              "level": 25,
              "price": 106942,
              "profit per day": 12301,
              "unlock": "Whale Level 24"
            },
            {
              "card": "Whale",
              "level": 26,
              "price": 216010,
              "profit per day": 18485,
              "unlock": "Whale Level 25"
            },
            {
              "card": "Whale",
              "level": 27,
              "price": 182310,
              "profit per day": 26692,
              "unlock": "Whale Level 26"
            },
            {
              "card": "Whale",
              "level": 28,
              "price": 230787,
              "profit per day": 16550,
              "unlock": "Whale Level 27"
            },
            {
              "card": "Whale",
              "level": 29,
              "price": 226242,
              "profit per day": 23071,
              "unlock": "Whale Level 28"
            },
            {
              "card": "Whale",
              "level": 30,
              "price": 209996,
              "profit per day": 37001,
              "unlock": "Whale Level 29"
            },
            {
              "card": "Fork",
              "level": 1,
              "price": 1615,
              "profit per day": 148,
              "unlock": "Whale Level 3"
            },
            {
              "card": "Fork",
              "level": 2,
              "price": 3364,
              "profit per day": 423,
              "unlock": "Fork Level 1"
            },
            {
              "card": "Fork",
              "level": 3,
              "price": 6379,
              "profit per day": 558,
              "unlock": "Fork Level 2"
            },
            {
              "card": "Fork",
              "level": 4,
              "price": 10515,
              "profit per day": 845,
              "unlock": "Fork Level 3"
            },
            {
              "card": "Fork",
              "level": 5,
              "price": 11371,
              "profit per day": 1168,
              "unlock": "Fork Level 4"
            },
            {
              "card": "Fork",
              "level": 6,
              "price": 16370,
              "profit per day": 1625,
              "unlock": "Fork Level 5"
            },
            {
              "card": "Fork",
              "level": 7,
              "price": 21361,
              "profit per day": 2392,
              "unlock": "Fork Level 6"
            },
            {
              "card": "Fork",
              "level": 8,
              "price": 17460,
              "profit per day": 3105,
              "unlock": "Fork Level 7"
            },
            {
              "card": "Fork",
              "level": 9,
              "price": 20314,
              "profit per day": 4282,
              "unlock": "Fork Level 8"
            },
            {
              "card": "Fork",
              "level": 10,
              "price": 25591,
              "profit per day": 4159,
              "unlock": "Fork Level 9"
            },
            {
              "card": "Fork",
              "level": 11,
              "price": 29353,
              "profit per day": 6112,
              "unlock": "Fork Level 10"
            },
            {
              "card": "Fork",
              "level": 12,
              "price": 43889,
              "profit per day": 5152,
              "unlock": "Fork Level 11"
            },
            {
              "card": "Fork",
              "level": 13,
              "price": 42339,
              "profit per day": 6087,
              "unlock": "Fork Level 12"
            },
            {
              "card": "Fork",
              "level": 14,
              "price": 44656,
              "profit per day": 6322,
              "unlock": "Fork Level 13"
            },
            {
              "card": "Fork",
              "level": 15,
              "price": 82658,
              "profit per day": 6510,
              "unlock": "Fork Level 14"
            },
            {
              "card": "Fork",
              "level": 16,
              "price": 77094,
              "profit per day": 8878,
              "unlock": "Fork Level 15"
            },
            {
              "card": "Fork",
              "level": 17,
              "price": 107782,
              "profit per day": 9420,
              "unlock": "Fork Level 16"
            },
            {
              "card": "Fork",
              "level": 18,
              "price": 83724,
              "profit per day": 11158,
              "unlock": "Fork Level 17"
            },
            {
              "card": "Fork",
              "level": 19,
              "price": 102886,
              "profit per day": 16215,
              "unlock": "Fork Level 18"
            },
            {
              "card": "Fork",
              "level": 20,
              "price": 116489,
              "profit per day": 10774,
              "unlock": "Fork Level 19"
            },
            {
              "card": "Fork",
              "level": 21,
              "price": 82732,
              "profit per day": 11855,
              "unlock": "Fork Level 20"
            },
            {
              "card": "Fork",
              "level": 22,
              "price": 128999,
              "profit per day": 15544,
              "unlock": "Fork Level 21"
            },
            {
              "card": "Fork",
              "level": 23,
              "price": 90364,
              "profit per day": 10354,
              "unlock": "Fork Level 22"
            },
              {
                "card": "Fork",
                "level": 24,
                "price": 130232,
                "profit per day": 10696,
                "unlock": "Fork Level 23"
              },
              {
                "card": "Fork",
                "level": 25,
                "price": 102544,
                "profit per day": 16943,
                "unlock": "Fork Level 24"
              },
              {
                "card": "Fork",
                "level": 26,
                "price": 201151,
                "profit per day": 26292,
                "unlock": "Fork Level 25"
              },
              {
                "card": "Fork",
                "level": 27,
                "price": 114955,
                "profit per day": 20072,
                "unlock": "Fork Level 26"
              },
              {
                "card": "Fork",
                "level": 28,
                "price": 163142,
                "profit per day": 27216,
                "unlock": "Fork Level 27"
              },
              {
                "card": "Fork",
                "level": 29,
                "price": 143101,
                "profit per day": 21692,
                "unlock": "Fork Level 28"
              },
              {
                "card": "Fork",
                "level": 30,
                "price": 286562,
                "profit per day": 35080,
                "unlock": "Fork Level 29"
              },
              {
                "card": "Algorithmic Trading",
                "level": 1,
                "price": 2921,
                "profit per day": 419,
                "unlock": "None"
              },
              {
                "card": "Algorithmic Trading",
                "level": 2,
                "price": 6596,
                "profit per day": 975,
                "unlock": "Algorithmic Trading Level 1"
              },
              {
                "card": "Algorithmic Trading",
                "level": 3,
                "price": 11469,
                "profit per day": 1900,
                "unlock": "Algorithmic Trading Level 2"
              },
              {
                "card": "Algorithmic Trading",
                "level": 4,
                "price": 16380,
                "profit per day": 2543,
                "unlock": "Algorithmic Trading Level 3"
              },
              {
                "card": "Algorithmic Trading",
                "level": 5,
                "price": 24490,
                "profit per day": 3271,
                "unlock": "Algorithmic Trading Level 4"
              },
              {
                "card": "Algorithmic Trading",
                "level": 6,
                "price": 32967,
                "profit per day": 5042,
                "unlock": "Algorithmic Trading Level 5"
              },
              {
                "card": "Algorithmic Trading",
                "level": 7,
                "price": 47970,
                "profit per day": 6253,
                "unlock": "Algorithmic Trading Level 6"
              },
              {
                "card": "Algorithmic Trading",
                "level": 8,
                "price": 34613,
                "profit per day": 8117,
                "unlock": "Algorithmic Trading Level 7"
              },
              {
                "card": "Algorithmic Trading",
                "level": 9,
                "price": 60517,
                "profit per day": 8807,
                "unlock": "Algorithmic Trading Level 8"
              },
              {
                "card": "Algorithmic Trading",
                "level": 10,
                "price": 60052,
                "profit per day": 7808,
                "unlock": "Algorithmic Trading Level 9"
              },
              {
                "card": "Algorithmic Trading",
                "level": 11,
                "price": 90890,
                "profit per day": 8062,
                "unlock": "Algorithmic Trading Level 10"
              },
              {
                "card": "Algorithmic Trading",
                "level": 12,
                "price": 68589,
                "profit per day": 17263,
                "unlock": "Algorithmic Trading Level 11"
              },
              {
                "card": "Algorithmic Trading",
                "level": 13,
                "price": 123823,
                "profit per day": 17888,
                "unlock": "Algorithmic Trading Level 12"
              },
              {
                "card": "Algorithmic Trading",
                "level": 14,
                "price": 153031,
                "profit per day": 12212,
                "unlock": "Algorithmic Trading Level 13"
              },
              {
                "card": "Algorithmic Trading",
                "level": 15,
                "price": 165850,
                "profit per day": 19230,
                "unlock": "Algorithmic Trading Level 14"
              },
              {
                "card": "Algorithmic Trading",
                "level": 16,
                "price": 115493,
                "profit per day": 28809,
                "unlock": "Algorithmic Trading Level 15"
              },
              {
                "card": "Algorithmic Trading",
                "level": 17,
                "price": 188583,
                "profit per day": 18363,
                "unlock": "Algorithmic Trading Level 16"
              },
              {
                "card": "Algorithmic Trading",
                "level": 18,
                "price": 243029,
                "profit per day": 28419,
                "unlock": "Algorithmic Trading Level 17"
              },
              {
                "card": "Algorithmic Trading",
                "level": 19,
                "price": 169624,
                "profit per day": 21504,
                "unlock": "Algorithmic Trading Level 18"
              },
              {
                "card": "Algorithmic Trading",
                "level": 20,
                "price": 262326,
                "profit per day": 37166,
                "unlock": "Algorithmic Trading Level 19"
              },
              {
                "card": "Algorithmic Trading",
                "level": 21,
                "price": 267989,
                "profit per day": 26164,
                "unlock": "Algorithmic Trading Level 20"
              },
              {
                "card": "Algorithmic Trading",
                "level": 22,
                "price": 299341,
                "profit per day": 50377,
                "unlock": "Algorithmic Trading Level 21"
              },
              {
                "card": "Algorithmic Trading",
                "level": 23,
                "price": 342488,
                "profit per day": 46099,
                "unlock": "Algorithmic Trading Level 22"
              },
              {
                "card": "Algorithmic Trading",
                "level": 24,
                "price": 187673,
                "profit per day": 40865,
                "unlock": "Algorithmic Trading Level 23"
              },
              {
                "card": "Algorithmic Trading",
                "level": 25,
                "price": 374053,
                "profit per day": 36385,
                "unlock": "Algorithmic Trading Level 24"
              },
              {
                "card": "Algorithmic Trading",
                "level": 26,
                "price": 453392,
                "profit per day": 43418,
                "unlock": "Algorithmic Trading Level 25"
              },
              {
                "card": "Algorithmic Trading",
                "level": 27,
                "price": 466507,
                "profit per day": 44752,
                "unlock": "Algorithmic Trading Level 26"
              },
              {
                "card": "Algorithmic Trading",
                "level": 28,
                "price": 455527,
                "profit per day": 47480,
                "unlock": "Algorithmic Trading Level 27"
              },
              {
                "card": "Algorithmic Trading",
                "level": 29,
                "price": 357570,
                "profit per day": 86664,
                "unlock": "Algorithmic Trading Level 28"
              },
              {
                "card": "Algorithmic Trading",
                "level": 30,
                "price": 252569,
                "profit per day": 51112,
                "unlock": "Algorithmic Trading Level 29"
              },
              {
                "card": "Atomic Swaps",
                "level": 1,
                "price": 2972,
                "profit per day": 443,
                "unlock": "Algorithmic Trading Level 5"
              },
              {
                "card": "Atomic Swaps",
                "level": 2,
                "price": 7268,
                "profit per day": 818,
                "unlock": "Atomic Swaps Level 1"
              },
              {
                "card": "Atomic Swaps",
                "level": 3,
                "price": 12709,
                "profit per day": 1591,
                "unlock": "Atomic Swaps Level 2"
              },
              {
                "card": "Atomic Swaps",
                "level": 4,
                "price": 16777,
                "profit per day": 2721,
                "unlock": "Atomic Swaps Level 3"
              },
                {
                  "card": "Atomic Swaps",
                  "level": 5,
                  "price": 25865,
                  "profit per day": 2950,
                  "unlock": "Atomic Swaps Level 4"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 6,
                  "price": 26230,
                  "profit per day": 5364,
                  "unlock": "Atomic Swaps Level 5"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 7,
                  "price": 39915,
                  "profit per day": 4244,
                  "unlock": "Atomic Swaps Level 6"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 8,
                  "price": 42039,
                  "profit per day": 7128,
                  "unlock": "Atomic Swaps Level 7"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 9,
                  "price": 46683,
                  "profit per day": 7780,
                  "unlock": "Atomic Swaps Level 8"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 10,
                  "price": 67703,
                  "profit per day": 8324,
                  "unlock": "Atomic Swaps Level 9"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 11,
                  "price": 61121,
                  "profit per day": 15624,
                  "unlock": "Atomic Swaps Level 10"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 12,
                  "price": 88720,
                  "profit per day": 12819,
                  "unlock": "Atomic Swaps Level 11"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 13,
                  "price": 115689,
                  "profit per day": 18233,
                  "unlock": "Atomic Swaps Level 12"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 14,
                  "price": 83568,
                  "profit per day": 17968,
                  "unlock": "Atomic Swaps Level 13"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 15,
                  "price": 130719,
                  "profit per day": 15210,
                  "unlock": "Atomic Swaps Level 14"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 16,
                  "price": 160328,
                  "profit per day": 28851,
                  "unlock": "Atomic Swaps Level 15"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 17,
                  "price": 102862,
                  "profit per day": 14633,
                  "unlock": "Atomic Swaps Level 16"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 18,
                  "price": 226355,
                  "profit per day": 22851,
                  "unlock": "Atomic Swaps Level 17"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 19,
                  "price": 134764,
                  "profit per day": 38568,
                  "unlock": "Atomic Swaps Level 18"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 20,
                  "price": 275358,
                  "profit per day": 27234,
                  "unlock": "Atomic Swaps Level 19"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 21,
                  "price": 208422,
                  "profit per day": 46278,
                  "unlock": "Atomic Swaps Level 20"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 22,
                  "price": 242896,
                  "profit per day": 23612,
                  "unlock": "Atomic Swaps Level 21"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 23,
                  "price": 274675,
                  "profit per day": 33231,
                  "unlock": "Atomic Swaps Level 22"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 24,
                  "price": 392034,
                  "profit per day": 51126,
                  "unlock": "Atomic Swaps Level 23"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 25,
                  "price": 414204,
                  "profit per day": 55732,
                  "unlock": "Atomic Swaps Level 24"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 26,
                  "price": 328119,
                  "profit per day": 61054,
                  "unlock": "Atomic Swaps Level 25"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 27,
                  "price": 423116,
                  "profit per day": 47432,
                  "unlock": "Atomic Swaps Level 26"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 28,
                  "price": 532801,
                  "profit per day": 65973,
                  "unlock": "Atomic Swaps Level 27"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 29,
                  "price": 341389,
                  "profit per day": 65420,
                  "unlock": "Atomic Swaps Level 28"
                },
                {
                  "card": "Atomic Swaps",
                  "level": 30,
                  "price": 498056,
                  "profit per day": 86966,
                  "unlock": "Atomic Swaps Level 29"
                },
                {
                  "card": "Hashrate",
                  "level": 1,
                  "price": 2912,
                  "profit per day": 403,
                  "unlock": "Atomic Swaps Level 7"
                },
                {
                  "card": "Hashrate",
                  "level": 2,
                  "price": 8319,
                  "profit per day": 893,
                  "unlock": "Hashrate Level 1"
                },
                {
                  "card": "Hashrate",
                  "level": 3,
                  "price": 13439,
                  "profit per day": 1672,
                  "unlock": "Hashrate Level 2"
                },
                {
                  "card": "Hashrate",
                  "level": 4,
                  "price": 18069,
                  "profit per day": 2495,
                  "unlock": "Hashrate Level 3"
                },
                {
                  "card": "Hashrate",
                  "level": 5,
                  "price": 18313,
                  "profit per day": 3299,
                  "unlock": "Hashrate Level 4"
                },
                {
                  "card": "Hashrate",
                  "level": 6,
                  "price": 23542,
                  "profit per day": 4169,
                  "unlock": "Hashrate Level 5"
                },
                {
                  "card": "Hashrate",
                  "level": 7,
                  "price": 44188,
                  "profit per day": 4920,
                  "unlock": "Hashrate Level 6"
                },
                {
                  "card": "Hashrate",
                  "level": 8,
                  "price": 35319,
                  "profit per day": 6056,
                  "unlock": "Hashrate Level 7"
                },
                {
                  "card": "Hashrate",
                  "level": 9,
                  "price": 60972,
                  "profit per day": 10990,
                  "unlock": "Hashrate Level 8"
                },
                {
                  "card": "Hashrate",
                  "level": 10,
                  "price": 63690,
                  "profit per day": 8025,
                  "unlock": "Hashrate Level 9"
                },
                {
                  "card": "Hashrate",
                  "level": 11,
                  "price": 72299,
                  "profit per day": 13877,
                  "unlock": "Hashrate Level 10"
                },
                {
                  "card": "Hashrate",
                  "level": 12,
                  "price": 103319,
                  "profit per day": 10028,
                  "unlock": "Hashrate Level 11"
                },
                {
                  "card": "Hashrate",
                  "level": 13,
                  "price": 118286,
                  "profit per day": 17334,
                  "unlock": "Hashrate Level 12"
                },
                {
                  "card": "Hashrate",
                  "level": 14,
                  "price": 106995,
                  "profit per day": 17271,
                  "unlock": "Hashrate Level 13"
                },
                {
                  "card": "Hashrate",
                  "level": 15,
                  "price": 135679,
                  "profit per day": 23530,
                  "unlock": "Hashrate Level 14"
                },
                  {
                    "card": "Hashrate",
                    "level": 16,
                    "price": 191256,
                    "profit per day": 27160,
                    "unlock": "Hashrate Level 15"
                  },
                  {
                    "card": "Hashrate",
                    "level": 17,
                    "price": 202158,
                    "profit per day": 15676,
                    "unlock": "Hashrate Level 16"
                  },
                  {
                    "card": "Hashrate",
                    "level": 18,
                    "price": 186163,
                    "profit per day": 31643,
                    "unlock": "Hashrate Level 17"
                  },
                  {
                    "card": "Hashrate",
                    "level": 19,
                    "price": 162420,
                    "profit per day": 32755,
                    "unlock": "Hashrate Level 18"
                  },
                  {
                    "card": "Hashrate",
                    "level": 20,
                    "price": 164115,
                    "profit per day": 34684,
                    "unlock": "Hashrate Level 19"
                  },
                  {
                    "card": "Hashrate",
                    "level": 21,
                    "price": 262537,
                    "profit per day": 48384,
                    "unlock": "Hashrate Level 20"
                  },
                  {
                    "card": "Hashrate",
                    "level": 22,
                    "price": 236055,
                    "profit per day": 48945,
                    "unlock": "Hashrate Level 21"
                  },
                  {
                    "card": "Hashrate",
                    "level": 23,
                    "price": 202499,
                    "profit per day": 28350,
                    "unlock": "Hashrate Level 22"
                  },
                  {
                    "card": "Hashrate",
                    "level": 24,
                    "price": 274588,
                    "profit per day": 57284,
                    "unlock": "Hashrate Level 23"
                  },
                  {
                    "card": "Hashrate",
                    "level": 25,
                    "price": 371731,
                    "profit per day": 30610,
                    "unlock": "Hashrate Level 24"
                  },
                  {
                    "card": "Hashrate",
                    "level": 26,
                    "price": 303606,
                    "profit per day": 58599,
                    "unlock": "Hashrate Level 25"
                  },
                  {
                    "card": "Hashrate",
                    "level": 27,
                    "price": 467106,
                    "profit per day": 67983,
                    "unlock": "Hashrate Level 26"
                  },
                  {
                    "card": "Hashrate",
                    "level": 28,
                    "price": 314411,
                    "profit per day": 76433,
                    "unlock": "Hashrate Level 27"
                  },
                  {
                    "card": "Hashrate",
                    "level": 29,
                    "price": 574309,
                    "profit per day": 44056,
                    "unlock": "Hashrate Level 28"
                  },
                  {
                    "card": "Hashrate",
                    "level": 30,
                    "price": 267988,
                    "profit per day": 59849,
                    "unlock": "Hashrate Level 29"
                  },
                  {
                    "card": "Sharding",
                    "level": 1,
                    "price": 3224,
                    "profit per day": 505,
                    "unlock": "Hashrate Level 5"
                  },
                  {
                    "card": "Sharding",
                    "level": 2,
                    "price": 6038,
                    "profit per day": 1009,
                    "unlock": "Sharding Level 1"
                  },
                  {
                    "card": "Sharding",
                    "level": 3,
                    "price": 12569,
                    "profit per day": 1442,
                    "unlock": "Sharding Level 2"
                  },
                  {
                    "card": "Sharding",
                    "level": 4,
                    "price": 19756,
                    "profit per day": 2842,
                    "unlock": "Sharding Level 3"
                  },
                  {
                    "card": "Sharding",
                    "level": 5,
                    "price": 24075,
                    "profit per day": 4090,
                    "unlock": "Sharding Level 4"
                  },
                  {
                    "card": "Sharding",
                    "level": 6,
                    "price": 38373,
                    "profit per day": 4497,
                    "unlock": "Sharding Level 5"
                  },
                  {
                    "card": "Sharding",
                    "level": 7,
                    "price": 37038,
                    "profit per day": 6002,
                    "unlock": "Sharding Level 6"
                  },
                  {
                    "card": "Sharding",
                    "level": 8,
                    "price": 48490,
                    "profit per day": 6323,
                    "unlock": "Sharding Level 7"
                  },
                  {
                    "card": "Sharding",
                    "level": 9,
                    "price": 56717,
                    "profit per day": 8638,
                    "unlock": "Sharding Level 8"
                  },
                  {
                    "card": "Sharding",
                    "level": 10,
                    "price": 47962,
                    "profit per day": 7654,
                    "unlock": "Sharding Level 9"
                  },
                  {
                    "card": "Sharding",
                    "level": 11,
                    "price": 68848,
                    "profit per day": 9165,
                    "unlock": "Sharding Level 10"
                  },
                  {
                    "card": "Sharding",
                    "level": 12,
                    "price": 109788,
                    "profit per day": 16226,
                    "unlock": "Sharding Level 11"
                  },
                  {
                    "card": "Sharding",
                    "level": 13,
                    "price": 108665,
                    "profit per day": 11990,
                    "unlock": "Sharding Level 12"
                  },
                  {
                    "card": "Sharding",
                    "level": 14,
                    "price": 99202,
                    "profit per day": 15848,
                    "unlock": "Sharding Level 13"
                  },
                  {
                    "card": "Sharding",
                    "level": 15,
                    "price": 95172,
                    "profit per day": 20130,
                    "unlock": "Sharding Level 14"
                  },
                  {
                    "card": "Sharding",
                    "level": 16,
                    "price": 133571,
                    "profit per day": 24770,
                    "unlock": "Sharding Level 15"
                  },
                  {
                    "card": "Sharding",
                    "level": 17,
                    "price": 105323,
                    "profit per day": 25889,
                    "unlock": "Sharding Level 16"
                  },
                  {
                    "card": "Sharding",
                    "level": 18,
                    "price": 239201,
                    "profit per day": 34516,
                    "unlock": "Sharding Level 17"
                  },
                  {
                    "card": "Sharding",
                    "level": 19,
                    "price": 149675,
                    "profit per day": 21182,
                    "unlock": "Sharding Level 18"
                  },
                  {
                    "card": "Sharding",
                    "level": 20,
                    "price": 140098,
                    "profit per day": 20298,
                    "unlock": "Sharding Level 19"
                  },
                  {
                    "card": "Sharding",
                    "level": 21,
                    "price": 166082,
                    "profit per day": 34673,
                    "unlock": "Sharding Level 20"
                  },
                  {
                    "card": "Sharding",
                    "level": 22,
                    "price": 201617,
                    "profit per day": 43620,
                    "unlock": "Sharding Level 21"
                  },
                  {
                    "card": "Sharding",
                    "level": 23,
                    "price": 201100,
                    "profit per day": 27078,
                    "unlock": "Sharding Level 22"
                  },
                  {
                    "card": "Sharding",
                    "level": 24,
                    "price": 387081,
                    "profit per day": 47840,
                    "unlock": "Sharding Level 23"
                  },
                  {
                    "card": "Sharding",
                    "level": 25,
                    "price": 188203,
                    "profit per day": 40166,
                    "unlock": "Sharding Level 24"
                  },
                  {
                    "card": "Sharding",
                    "level": 26,
                    "price": 346230,
                    "profit per day": 61843,
                    "unlock": "Sharding Level 25"
                  },
                  {
                    "card": "Sharding",
                    "level": 27,
                    "price": 402977,
                    "profit per day": 34136,
                    "unlock": "Sharding Level 26"
                  },
                    {
                      "card": "Sharding",
                      "level": 28,
                      "price": 233711,
                      "profit per day": 43233,
                      "unlock": "Sharding Level 27"
                    },
                    {
                      "card": "Sharding",
                      "level": 29,
                      "price": 498204,
                      "profit per day": 72733,
                      "unlock": "Sharding Level 28"
                    },
                    {
                      "card": "Sharding",
                      "level": 30,
                      "price": 510819,
                      "profit per day": 78928,
                      "unlock": "Sharding Level 29"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 1,
                      "price": 3897,
                      "profit per day": 880,
                      "unlock": "None"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 2,
                      "price": 8786,
                      "profit per day": 1622,
                      "unlock": "Yield Farming Level 1"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 3,
                      "price": 19702,
                      "profit per day": 3419,
                      "unlock": "Yield Farming Level 2"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 4,
                      "price": 29429,
                      "profit per day": 3851,
                      "unlock": "Yield Farming Level 3"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 5,
                      "price": 40727,
                      "profit per day": 7205,
                      "unlock": "Yield Farming Level 4"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 6,
                      "price": 36569,
                      "profit per day": 7281,
                      "unlock": "Yield Farming Level 5"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 7,
                      "price": 50492,
                      "profit per day": 12592,
                      "unlock": "Yield Farming Level 6"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 8,
                      "price": 64334,
                      "profit per day": 8711,
                      "unlock": "Yield Farming Level 7"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 9,
                      "price": 86567,
                      "profit per day": 18636,
                      "unlock": "Yield Farming Level 8"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 10,
                      "price": 92553,
                      "profit per day": 13741,
                      "unlock": "Yield Farming Level 9"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 11,
                      "price": 107613,
                      "profit per day": 18392,
                      "unlock": "Yield Farming Level 10"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 12,
                      "price": 117084,
                      "profit per day": 15377,
                      "unlock": "Yield Farming Level 11"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 13,
                      "price": 139996,
                      "profit per day": 33370,
                      "unlock": "Yield Farming Level 12"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 14,
                      "price": 201952,
                      "profit per day": 23423,
                      "unlock": "Yield Farming Level 13"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 15,
                      "price": 134886,
                      "profit per day": 45612,
                      "unlock": "Yield Farming Level 14"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 16,
                      "price": 259657,
                      "profit per day": 28558,
                      "unlock": "Yield Farming Level 15"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 17,
                      "price": 219795,
                      "profit per day": 34886,
                      "unlock": "Yield Farming Level 16"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 18,
                      "price": 215586,
                      "profit per day": 59020,
                      "unlock": "Yield Farming Level 17"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 19,
                      "price": 299725,
                      "profit per day": 58900,
                      "unlock": "Yield Farming Level 18"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 20,
                      "price": 326808,
                      "profit per day": 48407,
                      "unlock": "Yield Farming Level 19"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 21,
                      "price": 471416,
                      "profit per day": 87177,
                      "unlock": "Yield Farming Level 20"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 22,
                      "price": 403572,
                      "profit per day": 87732,
                      "unlock": "Yield Farming Level 21"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 23,
                      "price": 501716,
                      "profit per day": 58668,
                      "unlock": "Yield Farming Level 22"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 24,
                      "price": 404801,
                      "profit per day": 68114,
                      "unlock": "Yield Farming Level 23"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 25,
                      "price": 567013,
                      "profit per day": 58155,
                      "unlock": "Yield Farming Level 24"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 26,
                      "price": 630397,
                      "profit per day": 72307,
                      "unlock": "Yield Farming Level 25"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 27,
                      "price": 343845,
                      "profit per day": 83511,
                      "unlock": "Yield Farming Level 26"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 28,
                      "price": 428220,
                      "profit per day": 86814,
                      "unlock": "Yield Farming Level 27"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 29,
                      "price": 801476,
                      "profit per day": 67722,
                      "unlock": "Yield Farming Level 28"
                    },
                    {
                      "card": "Yield Farming",
                      "level": 30,
                      "price": 808253,
                      "profit per day": 153638,
                      "unlock": "Yield Farming Level 29"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 1,
                      "price": 4364,
                      "profit per day": 822,
                      "unlock": "Yield Farming Level 5"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 2,
                      "price": 9786,
                      "profit per day": 1736,
                      "unlock": "Decentralized Autonomous Organization (DAO) Level 1"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 3,
                      "price": 13488,
                      "profit per day": 2639,
                      "unlock": "Decentralized Autonomous Organization (DAO) Level 2"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 4,
                      "price": 30172,
                      "profit per day": 3978,
                      "unlock": "Decentralized Autonomous Organization (DAO) Level 3"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 5,
                      "price": 37724,
                      "profit per day": 6485,
                      "unlock": "Decentralized Autonomous Organization (DAO) Level 4"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 6,
                      "price": 50557,
                      "profit per day": 8052,
                      "unlock": "Decentralized Autonomous Organization (DAO) Level 5"
                    },
                    {
                      "card": "Decentralized Autonomous Organization (DAO)",
                      "level": 7,
                      "price": 57397,
                      "profit per day": 9275,
                      "unlock": "Decentralized Autonomous Organization (DAO) Level 6"
                    },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 8,
                        "price": 63133,
                        "profit per day": 16396,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 7"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 9,
                        "price": 95424,
                        "profit per day": 12932,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 8"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 10,
                        "price": 76875,
                        "profit per day": 20128,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 9"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 11,
                        "price": 91545,
                        "profit per day": 16458,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 10"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 12,
                        "price": 150163,
                        "profit per day": 16450,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 11"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 13,
                        "price": 122020,
                        "profit per day": 35733,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 12"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 14,
                        "price": 191600,
                        "profit per day": 27565,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 13"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 15,
                        "price": 239713,
                        "profit per day": 42608,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 14"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 16,
                        "price": 198810,
                        "profit per day": 37010,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 15"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 17,
                        "price": 240829,
                        "profit per day": 34220,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 16"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 18,
                        "price": 302214,
                        "profit per day": 58350,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 17"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 19,
                        "price": 284628,
                        "profit per day": 66513,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 18"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 20,
                        "price": 217770,
                        "profit per day": 77493,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 19"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 21,
                        "price": 297712,
                        "profit per day": 84473,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 20"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 22,
                        "price": 328161,
                        "profit per day": 84753,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 21"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 23,
                        "price": 543035,
                        "profit per day": 81072,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 22"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 24,
                        "price": 525746,
                        "profit per day": 86235,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 23"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 25,
                        "price": 463801,
                        "profit per day": 110232,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 24"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 26,
                        "price": 490887,
                        "profit per day": 63815,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 25"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 27,
                        "price": 578571,
                        "profit per day": 114156,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 26"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 28,
                        "price": 387089,
                        "profit per day": 131950,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 27"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 29,
                        "price": 605289,
                        "profit per day": 146201,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 28"
                      },
                      {
                        "card": "Decentralized Autonomous Organization (DAO)",
                        "level": 30,
                        "price": 390235,
                        "profit per day": 160352,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 29"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 1,
                        "price": 4331,
                        "profit per day": 628,
                        "unlock": "Decentralized Autonomous Organization (DAO) Level 5"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 2,
                        "price": 9433,
                        "profit per day": 1707,
                        "unlock": "Liquidity Mining Level 1"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 3,
                        "price": 18399,
                        "profit per day": 3125,
                        "unlock": "Liquidity Mining Level 2"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 4,
                        "price": 26956,
                        "profit per day": 4689,
                        "unlock": "Liquidity Mining Level 3"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 5,
                        "price": 37194,
                        "profit per day": 4686,
                        "unlock": "Liquidity Mining Level 4"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 6,
                        "price": 35596,
                        "profit per day": 7775,
                        "unlock": "Liquidity Mining Level 5"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 7,
                        "price": 46087,
                        "profit per day": 7893,
                        "unlock": "Liquidity Mining Level 6"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 8,
                        "price": 63007,
                        "profit per day": 10290,
                        "unlock": "Liquidity Mining Level 7"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 9,
                        "price": 70257,
                        "profit per day": 10039,
                        "unlock": "Liquidity Mining Level 8"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 10,
                        "price": 88658,
                        "profit per day": 18216,
                        "unlock": "Liquidity Mining Level 9"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 11,
                        "price": 136541,
                        "profit per day": 24418,
                        "unlock": "Liquidity Mining Level 10"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 12,
                        "price": 104068,
                        "profit per day": 26521,
                        "unlock": "Liquidity Mining Level 11"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 13,
                        "price": 172986,
                        "profit per day": 28941,
                        "unlock": "Liquidity Mining Level 12"
                      },
                      {
                        "card": "Liquidity Mining",
                        "level": 14,
                        "price": 162476,
                        "profit per day": 22248,
                        "unlock": "Liquidity Mining Level 13"
                      },
                        {
                          "card": "Liquidity Mining",
                          "level": 15,
                          "price": 170266,
                          "profit per day": 30208,
                          "unlock": "Liquidity Mining Level 14"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 16,
                          "price": 290066,
                          "profit per day": 48425,
                          "unlock": "Liquidity Mining Level 15"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 17,
                          "price": 155448,
                          "profit per day": 32682,
                          "unlock": "Liquidity Mining Level 16"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 18,
                          "price": 309747,
                          "profit per day": 36965,
                          "unlock": "Liquidity Mining Level 17"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 19,
                          "price": 341698,
                          "profit per day": 31785,
                          "unlock": "Liquidity Mining Level 18"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 20,
                          "price": 208104,
                          "profit per day": 49250,
                          "unlock": "Liquidity Mining Level 19"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 21,
                          "price": 304012,
                          "profit per day": 66971,
                          "unlock": "Liquidity Mining Level 20"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 22,
                          "price": 294309,
                          "profit per day": 64861,
                          "unlock": "Liquidity Mining Level 21"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 23,
                          "price": 343784,
                          "profit per day": 94141,
                          "unlock": "Liquidity Mining Level 22"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 24,
                          "price": 553887,
                          "profit per day": 90913,
                          "unlock": "Liquidity Mining Level 23"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 25,
                          "price": 481910,
                          "profit per day": 101251,
                          "unlock": "Liquidity Mining Level 24"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 26,
                          "price": 656898,
                          "profit per day": 95287,
                          "unlock": "Liquidity Mining Level 25"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 27,
                          "price": 352629,
                          "profit per day": 129308,
                          "unlock": "Liquidity Mining Level 26"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 28,
                          "price": 773859,
                          "profit per day": 98194,
                          "unlock": "Liquidity Mining Level 27"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 29,
                          "price": 844562,
                          "profit per day": 157807,
                          "unlock": "Liquidity Mining Level 28"
                        },
                        {
                          "card": "Liquidity Mining",
                          "level": 30,
                          "price": 685689,
                          "profit per day": 114937,
                          "unlock": "Liquidity Mining Level 29"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 1,
                          "price": 5102,
                          "profit per day": 846,
                          "unlock": "Liquidity Mining Level 7"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 2,
                          "price": 9536,
                          "profit per day": 1759,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 1"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 3,
                          "price": 19309,
                          "profit per day": 3778,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 2"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 4,
                          "price": 22138,
                          "profit per day": 4586,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 3"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 5,
                          "price": 34059,
                          "profit per day": 5837,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 4"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 6,
                          "price": 49918,
                          "profit per day": 9431,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 5"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 7,
                          "price": 72597,
                          "profit per day": 10675,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 6"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 8,
                          "price": 86889,
                          "profit per day": 12863,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 7"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 9,
                          "price": 72892,
                          "profit per day": 10828,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 8"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 10,
                          "price": 104666,
                          "profit per day": 18937,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 9"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 11,
                          "price": 87216,
                          "profit per day": 19011,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 10"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 12,
                          "price": 122137,
                          "profit per day": 27180,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 11"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 13,
                          "price": 111472,
                          "profit per day": 26425,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 12"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 14,
                          "price": 185131,
                          "profit per day": 37642,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 13"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 15,
                          "price": 153539,
                          "profit per day": 29933,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 14"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 16,
                          "price": 265619,
                          "profit per day": 45960,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 15"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 17,
                          "price": 248541,
                          "profit per day": 41159,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 16"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 18,
                          "price": 226985,
                          "profit per day": 49294,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 17"
                        },
                        {
                          "card": "Zero-Knowledge Proofs (ZKP)",
                          "level": 19,
                          "price": 191539,
                          "profit per day": 48779,
                          "unlock": "Zero-Knowledge Proofs (ZKP) Level 18"
                        },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 20,
                            "price": 280546,
                            "profit per day": 36622,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 19"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 21,
                            "price": 382338,
                            "profit per day": 50973,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 20"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 22,
                            "price": 267456,
                            "profit per day": 76916,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 21"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 23,
                            "price": 460470,
                            "profit per day": 62626,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 22"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 24,
                            "price": 566572,
                            "profit per day": 66763,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 23"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 25,
                            "price": 473039,
                            "profit per day": 112707,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 24"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 26,
                            "price": 499979,
                            "profit per day": 56569,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 25"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 27,
                            "price": 339049,
                            "profit per day": 136629,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 26"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 28,
                            "price": 377327,
                            "profit per day": 109378,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 27"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 29,
                            "price": 401773,
                            "profit per day": 127905,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 28"
                          },
                          {
                            "card": "Zero-Knowledge Proofs (ZKP)",
                            "level": 30,
                            "price": 656151,
                            "profit per day": 135007,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 29"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 1,
                            "price": 4679,
                            "profit per day": 794,
                            "unlock": "Zero-Knowledge Proofs (ZKP) Level 5"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 2,
                            "price": 10201,
                            "profit per day": 1999,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 1"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 3,
                            "price": 16867,
                            "profit per day": 3346,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 2"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 4,
                            "price": 29972,
                            "profit per day": 4374,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 3"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 5,
                            "price": 34982,
                            "profit per day": 5842,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 4"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 6,
                            "price": 53701,
                            "profit per day": 8080,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 5"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 7,
                            "price": 41423,
                            "profit per day": 8323,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 6"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 8,
                            "price": 64876,
                            "profit per day": 15771,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 7"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 9,
                            "price": 77869,
                            "profit per day": 11238,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 8"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 10,
                            "price": 77154,
                            "profit per day": 18193,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 9"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 11,
                            "price": 130223,
                            "profit per day": 16424,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 10"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 12,
                            "price": 156154,
                            "profit per day": 29309,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 11"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 13,
                            "price": 149027,
                            "profit per day": 25366,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 12"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 14,
                            "price": 186244,
                            "profit per day": 32732,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 13"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 15,
                            "price": 191572,
                            "profit per day": 22760,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 14"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 16,
                            "price": 255357,
                            "profit per day": 39465,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 15"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 17,
                            "price": 214322,
                            "profit per day": 55734,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 16"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 18,
                            "price": 198658,
                            "profit per day": 64993,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 17"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 19,
                            "price": 305671,
                            "profit per day": 57478,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 18"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 20,
                            "price": 222812,
                            "profit per day": 46062,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 19"
                          },
                          {
                            "card": "Delegated Proof of Stake (DPoS)",
                            "level": 21,
                            "price": 324490,
                            "profit per day": 72137,
                            "unlock": "Delegated Proof of Stake (DPoS) Level 20"
                          },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 22,
                              "price": 407678,
                              "profit per day": 94445,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 21"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 23,
                              "price": 342304,
                              "profit per day": 52085,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 22"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 24,
                              "price": 517289,
                              "profit per day": 85880,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 23"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 25,
                              "price": 602135,
                              "profit per day": 91506,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 24"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 26,
                              "price": 346991,
                              "profit per day": 120902,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 25"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 27,
                              "price": 533676,
                              "profit per day": 75622,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 26"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 28,
                              "price": 589488,
                              "profit per day": 118599,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 27"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 29,
                              "price": 844923,
                              "profit per day": 151619,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 28"
                            },
                            {
                              "card": "Delegated Proof of Stake (DPoS)",
                              "level": 30,
                              "price": 500130,
                              "profit per day": 68692,
                              "unlock": "Delegated Proof of Stake (DPoS) Level 29"
                            }
                          ]

export const dailyfun = [
{
  name: 'Eat sheep',
  id: 'Eat sheep',
  image: 'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723029022/337_mkhmqb.png'
},
{
  id: 'Clean Wolf',
  name:'Clean Wolf',
  image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723028986/336_jbwfgd.png',
},
{
  id: 'Join Meute',
  name:'Join Meute',
  image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723029023/Ajouter_un_titre_164_1_xyc7mf.png',
},
{
  id: 'Buy new fur',
  name:'Buy new fur',
  image:'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723029059/Ajouter_un_titre_165_1_v6w0vb.png',
},

]