import React, { useState,useEffect ,useContext} from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
import { translate} from "../../Hooks/translate";
import { Context } from "../../Context/Context";
import socialImg from "../../images/socialImg.png"
import walletImg from "../../images/walletImg.png"
import silver2Img from "../../images/silver2Img.png"
import soonImg from "../../images/soonImg.png"
function Footer() {
  const {value} = useContext(Context)
  const [langContext,setLangContext] = value
  const [count, setCount] = useState(0);
  const [pageSelected, setPageSelected] = React.useState(''); 
  const [refresh, setRefresh] = React.useState(false); 

  //const router = useRouter()
  const url = window.location
  const [urlF, setUrlF] = React.useState(""); 
  useEffect(()=>{
    const urlC = url.pathname.replace('/','') == '' ? 'friends':url.pathname.replace('/','')
    //setUrlF(url.pathname.replace('/','') == '' ? 'home':url.pathname.replace('/',''))
    setPageSelected(urlC.includes('/') ? urlC.replace('/',''): urlC)
    console.log("url",urlC)
  },[])
  const menuPages=[
    /* {
      imgAct:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721820319/Group_53_vv08p1.png",
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721119909/Capture_d_e%CC%81cran_2024-07-16_a%CC%80_10.51.07_mekdte.png",
      title:"home",
      name:"Tap"
    }, */
    /* {
      imgAct:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723025892/16_jt11kk.png",
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721119909/Capture_d_e%CC%81cran_2024-07-16_a%CC%80_10.51.07_mekdte.png",
      title:"earn",
      name:"Mining"
    }, */
    /* {
      imgAct:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722430968/frd3_fxv2qr.png",
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721118667/milostudio_An_icon_of_a_minimalism_A_stack_of_coins_flat_desi_46344966-09ef-4e27-99d5-b4e70857f866_2_3_nhuepf.png",
      title:"friends",
      name:'Friends'
    }, */
    {
      imgAct:socialImg,
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721118667/milostudio_An_icon_of_a_minimalism_A_stack_of_coins_flat_desi_46344966-09ef-4e27-99d5-b4e70857f866_2_3_nhuepf.png",
      title:"friends",
      name:'Soon'
    },
    {
      imgAct:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723025893/17_k1heyz.png",
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721118666/milostudio_An_icon_of_a_minimalism_pickaxe_flat_design_orange_b993fbf0-9d75-4e64-a423-bd7266c0bb45_0_copie02_3_r8rrfi.png",
      title:"leaderboard",
      name:'Soon'
    },
    {
      imgAct:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1723026000/Ajouter_un_titre_163_txfnnm.png",
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721120575/Capture_d_e%CC%81cran_2024-07-16_a%CC%80_10.50.31_2_t3zuho.png",
      title:"trade",
      name:'Soon'
    },
    {
      imgAct:walletImg,
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721118667/milostudio_An_icon_of_a_minimalism_A_stack_of_coins_flat_desi_46344966-09ef-4e27-99d5-b4e70857f866_2_3_nhuepf.png",
      title:"comingsoon",
      name:'Soon'
    }, 
    

    
    
    {
      imgAct:silver2Img,
      imgInact:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700102/icons8-anne%CC%81e-du-singe-60_1_gmpwji.png",
      title:"airdrop",
      name:'Soon'
    }
  ]

  return (
   
    <div className='bkFoot' style={{backgroundColor:'yellow'}}>
     {/*  {pageSelected !== 'airdrop' && pageSelected !== 'trade' &&
      <div style={{position:'fixed',bottom:110,right:0,cursor:'pointer',width:'20%',borderRadius:13}} onClick={()=>{setPageSelected('airdrop')}} className={pageSelected !== "airdrop" ? "bkgSelect":"bkgNone"} >
        <Link to={"/airdrop"} style={{textDecoration:'none'}}>
          <div className={pageSelected !== "airdrop" ? "iconSelected2" : "iconUnselected"} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:3,marginBottom:2,marginLeft:-5,marginRight:-5}}>
            <img   style={{height:45,marginTop:-2,marginBottom:0,padding: 5}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1722430597/2024-07-25_09.54.21_6_bs8uav.png"}/>
            <p style={{textAlign:'center',marginTop:-4,fontSize:12,marginBottom:4,fontWeight:'500',color:pageSelected == "airdrop" ?'#fff':'#E4EAE4'}}>{translate("Airdrop", langContext,'ENGLISH')}</p>
          </div>
        </Link>
      </div>
      } */}
      <div className="footer-nav-area" id="footerNav" style={{backgroundColor:'#131313'}}>
      <div className="suha-footer-nav" style={{marginTop:2,marginBottom:15,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
        {menuPages.map((item,i)=>{
          return(
            <div onClick={()=>{setPageSelected(item.title)}} className={pageSelected == item.title ? "bkgSelect":"bkgNone"} style={{cursor:'pointer',width:'20%',marginLeft:i==0?5:5,marginRight:i==menuPages.length-1?5:5}}>
              <Link to={"/"+item.title} style={{textDecoration:'none'}}>
                <div className={pageSelected == item.title ||(pageSelected !== 'trade' && item.title == 'trade') ? "iconSelected" : "iconUnselected"} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:3,marginBottom:9,marginLeft:0,marginRight:0, backgroundColor: item.title == 'trade' && pageSelected == 'trade'? '#4285F4':(item.title == 'trade' && pageSelected != 'trade' ? '#274372' : '#131313'),borderRadius:5}}>
                  <img style={{height:37,marginTop:-2,marginBottom:0,padding:i !==4 ? 5:8}} src={soonImg}/>
                  {/* {item.title =='trade' && (
                  <p  style={{textAlign:'center',marginTop:-7,fontSize:12,marginBottom:2,fontWeight:'500',color:pageSelected == item.title ?'#fff':'#fff'}}>1v1</p>)} */}
                  <p  style={{textAlign:'center',marginTop:-2,fontSize:12,marginBottom:2,fontWeight:'500',color:pageSelected == item.title ?'#fff':'#fff'}}>{translate(item.name, langContext,'ENGLISH')}</p>
                </div>
              </Link>
            </div>
          )
        })}
        </div>
      </div>
       {/* <div className="footer-nav-area" id="footerNav" style={{backgroundColor:'#272A2F',borderRadius:13}}>
        <div className="suha-footer-nav" style={{marginTop:2,marginBottom:2,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <div onCdivck={()=>{setPageSelected("home");setRefresh(!refresh);console.log('kkkk',pageSelected,refresh)}} style={{backgroundColor:pageSelected == "home" || urlF == "home" ?"#4E4F51":"transparent",width:60,borderRadius:13,marginLeft:5,display:'flex',justifyContent:'center',alignSelf:'center'}}>
                <div style={{paddingLeft:15,paddingRight:15,paddingTop:5,paddingBottom:5}} >
                  <div href="/home">
                    <img className="ti" style={{height:20,display:'flex',justifyContent:'center',alignContent:'center'}} src={pageSelected == "home" || urlF == "home" ?"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700105/icons8-anne%CC%81e-du-singe-60_mhqsu7.png" :"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700102/icons8-anne%CC%81e-du-singe-60_1_gmpwji.png"}/>
                    Home
                  </div>
                </div>
              </div>
              <div onCdivck={()=>{setPageSelected("cart");console.log('l')}} style={{backgroundColor:pageSelected == "cart" || urlF == "cart" ?"#4E4F51":"transparent",width:60,borderRadius:13}}>
                <div style={{paddingLeft:15,paddingRight:15,paddingTop:5,paddingBottom:5}} ><div href="/cart"><img className="ti" style={{height:20}} src={pageSelected == "cart" || urlF == "cart" ?"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700105/icons8-anne%CC%81e-du-singe-60_mhqsu7.png" :"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700102/icons8-anne%CC%81e-du-singe-60_1_gmpwji.png"}/>Cart</div></div>
              </div>
              <div style={{backgroundColor:pageSelected == "settings" || urlF == "settings" ?"#4E4F51":"transparent",width:60,borderRadius:13}}>
                <div style={{paddingLeft:15,paddingRight:15,paddingTop:5,paddingBottom:5}} onCdivck={()=>{setPageSelected("settings")}}><div href="/settings"><img className="ti" style={{height:20}} src={pageSelected == "settings" || urlF == "settings" ?"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700105/icons8-anne%CC%81e-du-singe-60_mhqsu7.png" :"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700102/icons8-anne%CC%81e-du-singe-60_1_gmpwji.png"}/>Settings</div></div>
              </div>
              <div style={{backgroundColor:pageSelected == "pages" || urlF == "pages" ?"#4E4F51":"transparent",width:60,borderRadius:10,marginRight:13}}>
                <div style={{paddingLeft:15,paddingRight:15,paddingTop:5,paddingBottom:5}} onCdivck={()=>{setPageSelected("pages")}}><div href="/pages"><img className="ti" style={{height:20}} src={pageSelected == "pages" || urlF == "pages" ?"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700105/icons8-anne%CC%81e-du-singe-60_mhqsu7.png" :"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1719700102/icons8-anne%CC%81e-du-singe-60_1_gmpwji.png"}/>Pages</div></div>
              </div>
        </div>
      </div> */}
  </div>
  );
}

export default Footer;
