import React, { useState,useContext,useEffect } from "react";
import "./Market.css";
import { Context } from "../../Context/Context";
import { Button, Space } from 'antd-mobile'
import { List,FloatingPanel, Switch } from 'antd-mobile'
import { ResponsiveLine } from '@nivo/line';
import { Popup } from 'antd-mobile'
import { Divider } from 'antd-mobile'
import Wallet from "../Wallet/Wallet";
import RealTimeChart from "../Chart/Chart";
const data = [
  {
    "id": "japan",
    "color": "hsl(103, 70%, 50%)",
    "data": [
      { "x": "plane", "y": 185 },
      { "x": "helicopter", "y": 23 },
      { "x": "boat", "y": 88 },
      { "x": "train", "y": 178 },
      { "x": "subway", "y": 65 },
      { "x": "bus", "y": 131 },
      { "x": "car", "y": 267 },
      { "x": "moto", "y": 165 },
      { "x": "bicycle", "y": 112 },
      { "x": "horse", "y": 66 },
      { "x": "skateboard", "y": 167 },
      { "x": "others", "y": 24 }
    ]
  }
];
const data2 = [
  {
    "id": "japan",
    "color": "#FE3C30",
    "data": [
      { "x": "plane", "y": 85 },
      { "x": "helicopter", "y": 23 },
      { "x": "boat", "y": 8 },
      { "x": "train", "y": 0 },
      { "x": "subway", "y": 65 },
      { "x": "bus", "y": 12 },
      { "x": "car", "y": 167 },
      { "x": "moto", "y": 165 },
      { "x": "bicycle", "y": 112 },
      { "x": "horse", "y": 66 },
      { "x": "skateboard", "y": 167 },
      { "x": "others", "y": 24 }
    ]
  }
];
const data3 = [
  {
    "id": "japan",
    "color": "hsl(13, 100%, 50%)",
    "data": [
      { "x": "plane", "y": 185 },
      { "x": "helicopter", "y": 23 },
      { "x": "boat", "y": 88 },
      { "x": "train", "y": 78 },
      { "x": "subway", "y": 65 },
      { "x": "bus", "y": 1 },
      { "x": "car", "y": 367 },
      { "x": "moto", "y": 165 },
      { "x": "bicycle", "y": 212 },
      { "x": "horse", "y": 6 },
      { "x": "skateboard", "y": 167 },
      { "x": "others", "y": 24 }
    ]
  }
];
const anchors = [100, window.innerHeight * 0.4, window.innerHeight * 0.8]


function Referal() {
  
  const {value, value2, value3} = useContext(Context)
  const [userData, setUserData] = value2
  const [visibleChart, setVisibleChart] = useState(false)

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {

  }, []);
  const dataMarket = [
    {
      title: "BTC-USD",
      subtitle:'Bitcoin USD',
      data:data,
      price:58000
    },
    {
      title: "ETH-USD",
      subtitle:'Ethereum USD',
      data:data2,
      price:3192.36
    },
    {
      title: "EGLD-USD",
      subtitle:'MultiversX USD',
      data:data3,
      price:29.57
    }
  ]

  const dataWallet = [
    {
      title: "WBTC",
      full_title:'WBitcoin',
      possess:0.0645,
      data:data,
      price:58000,
      last_price:56000,
      logo:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720086120/icons8-bitcoin-48_r7ye2z.png",
      color:"#FCA23F"
    },
    {
      title: "WETH",
      full_title:'WEthereum',
      possess:1.7320,
      data:data2,
      price:3192.36,
      last_price:3002,
      logo:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720087132/icons8-ethereum-48_3_fl60hv.png",
      color:"#5E7BE2"
    },
    {
      title: "WMATIC",
      full_title:'WMatic',
      possess:3.6754,
      data:data3,
      price:29.57,
      last_price:23.10,
      logo:"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720086116/icons8-polygone-64_qah32j.png",
      color:"#fff"
    }
  ]
  const chartContent = (item) => {
    return(
      <div style={{ padding: 20}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <div  style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-end',marginTop:10,marginBottom:0}}>
            <p style={{color:'#fff',fontWeight:'700',fontSize:25,marginTop:0,marginBottom:0}}>{item.title}</p>
            <p style={{color:'#8E8E93',fontWeight:'500',fontSize:14,marginTop:0,marginLeft:20,alignSelf:'flex-end',marginBottom:0}}>{item.subtitle}</p>
          </div>
          <div className="touchable" onClick={()=>{setVisibleChart(false)}} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#313135',borderRadius:1000,width:45,height:45}}>
            <img style={{heigth:20,width:20,padding:0}} src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1720080885/icons8-effacer-50_fnjogs.png" />
          </div>
        </div>
        <Divider 
          style={{
            color: '#3E3E42',
            borderColor: '#3E3E42',
            marginTop:22,
            marginBottom:8,
          }}
        />
        <div>
          <p style={{color:'#fff',fontWeight:'700',fontSize:25,marginTop:0,marginBottom:0}}>{item.price}</p>
        </div>
        <div >
          <RealTimeChart/>
        </div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:50}}>
          <Button style={{backgroundColor:'#2BBF8A',borderWidth:0,color:'#fff',width:'45%',marginRight:15}} fill='solid'>
            <p style={{marginTop:8,marginBottom:8,fontSize:19,fontWeight:700}}>Buy</p>
          </Button>
          <Button style={{backgroundColor:'#EE4D6C',borderWidth:0,color:'#fff',width:'45%',marginLeft:15}} fill='solid'>
            <p style={{marginTop:8,marginBottom:8,fontSize:19,fontWeight:700}}>Sell</p>
          </Button>
        </div>

        
      </div>
    )
  }
  
  return (
    <div 
    style={{backgroundColor:'#10111A',width:'100%'}}
      onClick={async()=>{
    }}> 
      <div
       style={{
        marginTop:15,
        '--border-bottom':'solid 0px var(--adm-border-color)',
        '--border-inner':'solid 0px #2E2942',
        '--border-top':'solid 0px var(--adm-border-color)'
       }}>
        {dataWallet.map((item,index)=>{
          return(
            <div className="touchable" style={{marginBottom:15,maxWidth:"100%",marginLeft:20,marginRight:20}}>
              <div onClick={()=>{ setVisibleChart(true)}} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',backgroundColor:'#141A25' /* 212236 */,border:'solid 0px #322D46',padding:'10px 13px 10px 13px',borderRadius:8}}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                  <div style={{backgroundColor:item.color,borderRadius:100,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <img style={{width:37,heigth:37,padding:5}} src={item.logo} />
                  </div>
                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                    <p style={{color:'#fff',fontWeight:800,fontSize:13,marginTop:0,marginBottom:0,marginLeft:15}}>{item.full_title}</p>
                    <p style={{color:'#747981',fontWeight:'500',fontSize:13,marginLeft:15,marginTop:2,marginBottom:0}}>{item.title}</p>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                  <p style={{color:'#fff',fontWeight:800,fontSize:13,marginTop:0,marginBottom:0,marginLeft:15,textAlign:'right'}}>{item.price}$</p>
                  <p style={{color:(((item.price - item.last_price)/item.last_price)*100).toFixed(0) > 0 ?'#56D38D':'#fff',fontWeight:'500',fontSize:13,marginLeft:15,marginTop:2,marginBottom:0,textAlign:'right'}}>{(((item.price - item.last_price)/item.last_price)*100).toFixed(0) > 0 ? '+' : '-' }{(((item.price - item.last_price)/item.last_price)*100).toFixed(0)}%</p>
                </div>
               {/*  <div style={{display:'flex',flexDirection:'column'}}>
                    <p style={{color:'#fff',fontWeight:800,fontSize:20,marginTop:0,marginBottom:0,marginLeft:15,textAlign:'right'}}>{item.possess}</p>
                    <p style={{color:'#8E8E93',fontWeight:'500',fontSize:14,marginLeft:15,marginTop:0,marginBottom:0,textAlign:'right'}}>${(item.price*item.possess).toFixed(2)}</p>
                  </div> */}
               
              </div>
              {/* <div onClick={()=>{ setVisibleChart(true)}} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{marginTop:0,marginBottom:0}}>
                  <p style={{color:'#fff',fontWeight:800,fontSize:20,marginTop:0,marginBottom:2}}>{item.title}</p>
                  <p style={{color:'#8E8E93',fontWeight:400,fontSize:14,marginTop:0,marginBottom:0}}>{item.subtitle}</p>
                </div>
              </div> */}
                {/* <div style={{height:50,width:"40%",display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                  <div style={{width:'50%',height:"60%",marginRight:10}}>
                    <ResponsiveLine
                      data={item.data}
                      xScale={{ type: 'point' }}
                      yScale={{
                          type: 'linear',
                          min: 'auto',
                          max: 'auto',
                          stacked: true,
                          reverse: false
                      }}
                      enableArea = {true}
                      areaOpacity={0.1}
                      
                      yFormat=" >-.2f"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={null}
                      axisLeft={null}
                      enableGridX={false}
                      enableGridY={false}
                      colors={{ scheme: 'set1' }}
                      enablePoints={false}
                      pointSize={10}
                      pointColor={{ theme: 'background' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabel="data.yFormatted"
                      pointLabelYOffset={-12}
                      isInteractive={false}
                      enableTouchCrosshair={true}
                      useMesh={true}
                      legends={[]}
                    />
                  </div>
                  <p style={{width:"40%",color:"#fff",fontWeight:500,textAlign:'right'}}>{item.price}</p>
                </div> */}
              
              <Popup
              visible={visibleChart}
              onMaskClick={() => {
                setVisibleChart(false)
              }} 
              bodyStyle={{
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
 
                backgroundColor:'#1C1C1E'
              }}
            >
              {chartContent(item)}
            </Popup>
            </div>
          )
        })}
      
      </div>
 
     

     
    
  </div>
  );
}

export default Referal;
