import React, { useState, useEffect,CSSProperties } from 'react'
import MoonLoader from "react-spinners/ClipLoader";
import {get_users,create_user,add_count,update_count,update_max_count,update_count_var,get_trade} from "../Hooks/firebase_functions"
import { usePageVisibility } from '../Components/Visibility/Visibility';
import { SpinLoading } from 'antd-mobile'
import { Timestamp } from "firebase/firestore";
import LoadingPage from "../Components/LoadingPage/LoadingPage";

export const Context = React.createContext()

const languagesC = [
  {
    title: 'French',
    codeO: 'FR',
    codeW: 'FR'
  },
  {
    title: 'English',
    codeO: 'EN',
    codeW: 'ENGLISH'
  },
  {
    title: 'Russian',
    codeO: 'RU',
    codeW: 'RUSSIAN'
  },
  {
    title: 'Turkish',
    codeO: 'TR',
    codeW: 'TURKISH'
  },
  {
    title: 'Hindi',
    codeO: 'HI',
    codeW: 'HINDI'
  },
  {
    title: 'Farsi',
    codeO: 'FA',
    codeW: 'FARSI'
  },
  {
    title: 'Spanish',
    codeO: 'ES',
    codeW: 'Español'
  },
  {
    title: 'Portuguese',
    codeO: 'PT',
    codeW: 'Português'
  },
  {
    title: 'Indonesian',
    codeO: 'ID',
    codeW: 'Bahasa Indonesia'
  },
  {
    title: 'Vietnamese',
    codeO: 'VI',
    codeW: 'Vietnam'
  },
  {
    title: 'Thai',
    codeO: 'TH',
    codeW: 'Thailande'
  },
  {
    title: 'Uzbek',
    codeO: 'UZ',
    codeW: 'UZBEK'
  },
  {
    title: 'German',
    codeO: 'DE',
    codeW: 'DEUTSCH'
  },
  {
    title: 'Tagalog',
    codeO: 'TL',
    codeW: 'TAGALOG'
  }
];

export const ContextProvider = ({ children }) => {
  const [langContext, setLangContext] = useState()
  const [userData, setUserData] = useState()
  const [initData, setInitData] = useState()
  const [isFetch, setIsFetch] = useState(false)
  const [tlgmInfo, setTlgmInfo] = useState()
  const [ranking, setRanking] = useState()
  const [myRanking, setMyRanking] = useState()
  const [friends, setFriends] = useState();
  const [tasks, setTasks] = useState()
  const [currentLvl, setCurrentLvl] = useState()
  const [userArticles, setUserArticles] = useState()
  const [userTrades, setUserTrades] = useState()
  const [dailyBonus, setDailyBonus] = useState({'A':false,'B':false,'C':false})


  const [dateOpenApp, setDateOpenApp] = useState()
  const isPageVisible = usePageVisibility();
  
  function calcul_level(lvl) {
    setCurrentLvl(lvl) 
  }
  function getCodeWFromCodeO(codeO) {
    const language = languagesC.find(lang => lang.codeO === codeO);
    return language ? language.codeW : null; // Retourne null si le codeO n'est pas trouvé
  }
  useEffect(() => {
    setDateOpenApp(new Date())
    const initTelegram = () => {
      const telegramWebApp = window.Telegram.WebApp;
      if (telegramWebApp.initDataUnsafe && telegramWebApp.initDataUnsafe.user) {
        return {
          userId: telegramWebApp.initDataUnsafe.user.id,
          language: telegramWebApp.initDataUnsafe.user.language_code,
          firstName: telegramWebApp.initDataUnsafe.user.first_name,
          username: telegramWebApp.initDataUnsafe.user.username,
        };
      } else {
        console.log('User data is not available');
        return { userId: null, language: null, firstName: null, username: null };

      }
    };

    const startTime = Date.now();
    const fn = async (userID,username) => {
      const rep = await get_users(userID)
      if(rep.length > 0){
        if(!rep[0].lastCo){
          console.log("reffffffp",!rep,!rep[0].lastCof)
          const adapt = rep[0]
          adapt.lastCo = Timestamp.fromDate(new Date())
          adapt.lang = "EN"
          adapt.refs = 0
          adapt.username = ''
          adapt.sheep = -1
          setUserData(adapt)
        setInitData(adapt)
        }else{
          setUserData(rep[0])
          setInitData(rep[0])
        }
        
        //calcul_level(rep[0].max)
        setLangContext(getCodeWFromCodeO(rep[0].lang))
        setIsFetch(true)
      }else{
        await create_user(userID);
        const user = {
          userId: Number(userId),
          //balance: 0,
          //coef: 1,
          //credits: 0,
          lastCo: Timestamp.fromDate(new Date()),
          //dateMulti : Timestamp.fromDate(new Date()),
          lang:"EN",
          refs:0,
          username:'',
          friendsLoaded:[],
          //levelLife:1,
          //life:500,
          //max:0,
          //pph: 0,
          sheep: -1,
        }
        setUserData(user)
        //calcul_level(0)
        setLangContext('ENGLISH')
      }
      setIsFetch(true);
     /*  const elapsedTime = Date.now() - startTime;
      const remainingTime = 3000 - elapsedTime;
      if (remainingTime > 0) {
        setTimeout(() => {
          setIsFetch(true);
        }, remainingTime);
      } else {
        setIsFetch(true);
      } */  
    }
    const userDataTgm =  initTelegram();
    
      setTlgmInfo({userId:288599645 , language:'fr',firstName:'gregory',username:'greg'}) 
      const { userId, language , firstName, username} = {userId:288599645 , language:'fr',firstName:'gregory',username:'greg'};
      
      /* setTlgmInfo(userDataTgm);
      const { userId, username, firstName } = userDataTgm; */
      
      fn(userId);
    


  }, []);
  useEffect(() => {
    const fn = async (dt) => {
      await update_count_var(initData,dt)
      //await update_count(dt)
      window.Telegram.WebApp.close()
    }
    if (!isPageVisible) {
      const oldUSerData = {...userData};
      fn(oldUSerData)

      /* if(userData.balance > userData.max || !userData.max){
        const oldData = { ...userData };
        oldData.max = userData.balance;
        setUserData(oldData)
        update_max_count(userData)
      } */
    }
  }, [isPageVisible, userData]);



  return (
    <Context.Provider
      value={{
        value: [langContext, setLangContext],
        value2: [userData, setUserData],
        value3: [tlgmInfo, setTlgmInfo],
        value4: [dateOpenApp, setDateOpenApp],
        value5: [ranking, setRanking],
        value6:[myRanking, setMyRanking],
        value7: [friends, setFriends],
        value8: [currentLvl,setCurrentLvl],
        value10: [tasks, setTasks],
        value11:[userArticles, setUserArticles],
        value12:[dailyBonus, setDailyBonus] ,
        value12: [initData, setInitData],
        value13: [userTrades, setUserTrades],

      }}
    >
      {isFetch ? <>{children}</> : 
      null
        }
    </Context.Provider>
  )
}

/* <LoadingPage /><div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:250}}>
      <SpinLoading style={{ '--size': '24px' }} />
    </div> */