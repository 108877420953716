import { initializeApp} from 'firebase/app'
import { doc, getFirestore, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { addDoc, Timestamp } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, listAll } from 'firebase/storage'
import { getDatabase, set, push, child, onValue, onChildAdded } from 'firebase/database'
import { collection, query, where, getDocs, or, deleteDoc } from 'firebase/firestore'
import 'firebase/compat/auth'
import { generateRandomName } from './generateName'
import axios from 'axios'
import 'firebase/compat/firestore'
import { levels } from '../db/db'
import { getAnalytics } from "firebase/analytics";
import { startOfDay, startOfWeek, endOfDay, endOfWeek } from 'date-fns';

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth'
import { orderBy, limit, startAfter } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBItehsai8bEaOx8nJ3cXEcigzDv5qSpzs",
  authDomain: "telegrambot-14790.firebaseapp.com",
  projectId: "telegrambot-14790",
  storageBucket: "telegrambot-14790.appspot.com",
  messagingSenderId: "1092128549572",
  appId: "1:1092128549572:web:aa6649dc7412b8b1b2b791"
}

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
const analytics = getAnalytics(app);
const storage = getStorage(app)
const database = getDatabase(app)

export async function get_users (userID) {
  const user = []
  const q = query(collection(db, 'friends'), where('userId', '==', userID))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    user.push(data)
  })
  return user
}

export async function get_usersV0 (userID) {
  const user = []
  const q = query(collection(db, 'users'), where('userId', '==', userID))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    user.push(data)
  })
  return user
}
export const get_balance = async (userId) => {
  const userRef = doc(db, 'friends', userId);
  const userSnapshot = await getDoc(userRef);

  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    return userData;  // Assuming the field for balance is named 'balance'
  } else {
    console.log('No such user found');
    return null;
  }
}
export const get_balanceV0 = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    return userData;  // Assuming the field for balance is named 'balance'
  } else {
    console.log('No such user found');
    return null;
  }
}
export async function check_username (username, userData) {
  try {
      // Check if the document exists
      const q = query(collection(db, 'friends'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log("f")

          return false
      } else {
        console.log("t")
          /* const q2 = query(collection(db, 'users'), where('userId', '==', userId));
          const querySnapshot2 = await getDocs(q); */
          const docRef = doc(db, 'friends', userData.id);
          await updateDoc(docRef, { username: username });
          return true
      }
  } catch (error) {
      console.error("Error checking username", error);
  }
}

export async function check_usernameV0 (username, userData) {
  try {
      // Check if the document exists
      const q = query(collection(db, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log("f")

          return false
      } else {
        console.log("t")
          /* const q2 = query(collection(db, 'users'), where('userId', '==', userId));
          const querySnapshot2 = await getDocs(q); */
          const docRef = doc(db, 'users', userData.id);
          await updateDoc(docRef, { username: username });
          return true
      }
  } catch (error) {
      console.error("Error checking username", error);
  }
}
export async function countFriendsByUserIdr(userID) {
  try {
    const q = query(collection(db, 'friends'), where('sponsoredBy', '==', userID));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      return 0;
    }
    
    return querySnapshot.size;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return 0;
  }
}

export async function getTopSponsors(periodicity = 'all') {
  try {
    const friendsRef = collection(db, 'friends');
    let q;

    // Filter based on periodicity
    if (periodicity === 'day') {
      const startOfDay = Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0, 0)));
      q = query(friendsRef, where('date', '>=', startOfDay));
    } else if (periodicity === 'week') {
      const today = new Date();
      const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
      const startOfWeek = Timestamp.fromDate(new Date(firstDayOfWeek.setHours(0, 0, 0, 0)));
      q = query(friendsRef, where('date', '>=', startOfWeek));
    } else {
      q = friendsRef; // 'all' periodicity, no filter on date
    }

    // Group by 'sponsoredBy' and count occurrences
    const querySnapshot = await getDocs(q);
    const sponsorCount = {};

    querySnapshot.forEach((doc) => {
      const sponsorId = doc.data().sponsoredBy;
      if (sponsorCount[sponsorId]) {
        sponsorCount[sponsorId]++;
      } else {
        sponsorCount[sponsorId] = 1;
      }
    });

    if (periodicity === 'day') {
      sponsorCount[generateRandomName()] = 3;
      sponsorCount[generateRandomName()] = 4;
      sponsorCount[generateRandomName()] = 5;
      sponsorCount[generateRandomName()] = 6;
    } else if (periodicity === 'week') {
      sponsorCount[generateRandomName()] = 10;
      sponsorCount[generateRandomName()] = 11;
      sponsorCount[generateRandomName()] = 12;
    }
    // Convert the object to an array and sort it by occurrences
    const sortedSponsors = Object.entries(sponsorCount)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    return sortedSponsors;

  } catch (error) {
    console.error('Error fetching documents: ', error);
    return [];
  }
}

export async function hasSponsoredDocuments(userID) {
  try {
    const friendsRef = collection(db, 'friends');
    const q = query(friendsRef, where('sponsoredBy', '==', userID));
    const querySnapshot = await getDocs(q);
    
    // If there's at least one document, return true
    return !querySnapshot.empty;

  } catch (error) {
    console.error('Error checking sponsored documents: ', error);
    return false;
  }
}
export async function countFriendsByUserId(userID, periodicity = 'all') {
  try {
    let q = query(collection(db, 'friends'), where('sponsoredBy', '==', userID));

    const now = new Date();
    // Adjust query based on periodicity
    if (periodicity === 'day') {
      q = query(
        q,
        where('date', '>=', Timestamp.fromDate(startOfDay(now))),
        where('date', '<=', Timestamp.fromDate(endOfDay(now)))
      );
    } else if (periodicity === 'week') {
      q = query(
        q,
        where('date', '>=', Timestamp.fromDate(startOfWeek(now))),
        where('date', '<=', Timestamp.fromDate(endOfWeek(now)))
      );
    }

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return 0;
    }

    return querySnapshot.size;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return 0;
  }
}

export async function convertDatesInFriends() {
  try {
    const friendsRef = collection(db, 'friends');
    const querySnapshot = await getDocs(friendsRef);

    querySnapshot.forEach(async (document) => {
      const data = document.data();
      const dateISO = data.date;

      // Convert ISO date string to JavaScript Date object
      const dateObj = new Date(dateISO);

      // Convert JavaScript Date object to Firestore Timestamp
      const newTimestamp = Timestamp.fromDate(dateObj);

      // Update the document with the new Timestamp
      const docRef = doc(db, 'friends', document.id);
      await updateDoc(docRef, { date: newTimestamp });

    });
    
  } catch (error) {
    console.error('Error converting dates: ', error);
  }
}

export async function create_user(userId) {
  const docRef = await addDoc(collection(db, 'friends'), {
    userId: userId,
    date:  Timestamp.fromDate(new Date()),
    lastCo: Timestamp.fromDate(new Date()),
    refs:0,
    lang:"EN",
    username:'',
    friendsLoaded:[],
    sponsoredBy:null,
    sheep: -1,
  })
}
export async function create_userV0(userId) {
  const docRef = await addDoc(collection(db, 'users'), {
    userId: userId,
    //balance: 0,
    //coef: 1,
    //credits: 110,
    lastCo: Timestamp.fromDate(new Date()),
    refs:0,
    //dateMulti : Timestamp.fromDate(new Date()),
    lang:"EN",
    username:'',
    friendsLoaded:[],
    //levelLife:1,
    //life:500,
    //max:0,
    //pph: 0,
    sheep: -1,
  })
}

export async function add_wallet(userId,address) {
  const docRef = await addDoc(collection(db, 'wallet'), {
    userId: userId,
    wallet: address
  })
}

export async function get_trade (userID) {
  const trades = []
  const q = query(collection(db, 'trade'), where('userId', '==', userID)/* ,where('active','==',true) */)
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    trades.push(data)
  })
  return trades
}

export async function add_trade(userId,tradeData,currency) {
  const docRef = await addDoc(collection(db, 'trade'), {
    userId: userId,
    active:true,
    amount: tradeData.amount,
    currency: tradeData.currency,
    date: tradeData.date,
    price: tradeData.price,
    time: tradeData.time,
    type: tradeData.type,
    versusName:tradeData.versusName,
    versusLevel:tradeData.versusLevel,
    status: "In progress"

  })
}

export async function update_trade(data) {
  const usersRef = doc(db, 'trade', data.id)
  await updateDoc(usersRef, {
    status: data.status,
    active:false

  })
}

export async function update_trade_find( data) {
  try {
      // Check if the document exists
      const q = query(collection(db, 'trade'), where('userId', '==', data.userId), where('date','==',data.date));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        const data = querySnapshot.docs[0].data();
        let status = data.status || "In progress";

        await updateDoc(docRef, { status: data.status });

      } else {
        console.log("t")
        
      }
  } catch (error) {
      console.error("Error checking username", error);
  }
}
export async function update_trade_find2(data) {
  const usersRef = doc(db, 'trade', data.id)
  await updateDoc(usersRef, {
    status: data.status,
    active:false

  })
}
export async function check_wallet(userId) {

      const q = query(collection(db, 'wallet'), where('userId', '==', userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
          return true
      } else {
          return false
      }

}

/* export async function add_sponsor(data,sponsor,sponsored,rec1,rec2s) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    balance: data.balance + value
  })
} */
export function format_numberInt(num) {
  if (num < 1000) {
    return num.toString();
  }

  const units = ["", "k", "M", "B", "T"];
  const order = Math.floor(Math.log10(num) / 3);
  const unitName = units[order];
  const numStr = (num / Math.pow(1000, order)).toFixed(0);

  return `${numStr}${unitName}`;
}
export function format_number(num) {
  if (num < 1000) {
    return num.toString();
  }

  const units = ["", "k", "M", "B", "T"];
  const order = Math.floor(Math.log10(num) / 3);
  const unitName = units[order];
  let numStr = (num / Math.pow(1000, order)).toFixed(2);

  // Remove unnecessary '.00' or '.x0'
  numStr = numStr.replace(/\.00$/, "").replace(/(\.\d)0$/, "$1");

  return `${numStr}${unitName}`;
}
export function format_number_inv(formattedNum) {
  const units = {
    'k': 1e3,
    'M': 1e6,
    'B': 1e9,
    'T': 1e12
  };

  const unit = formattedNum.slice(-1);
  const number = parseFloat(formattedNum.slice(0, -1));

  if (units[unit]) {
    return number * units[unit];
  } else {
    return parseFloat(formattedNum);
  }
}
export function calcul_level2(currentPoints) {
  let currentLevel = 0;
  let nextLevel = 0;
  for (let i = 0; i < levels.length; i++) {
    if (currentPoints < levels[i].step) {
      nextLevel = i;
      currentLevel = i - 1;
      break;
    }
  }

  if (currentLevel === -1) {
    const progress = (currentPoints / levels[0].step) * 100;
    return [progress, "Pup", levels[0].step, 0];
    // User is below the first level
    //return (currentPoints / levels[0].step) * 100;
  } else if (nextLevel === 0) {
    // User is at or above the last level
    return [100, levels[currentLevel].name, levels[currentLevel].step, levels[currentLevel].step];

    //return 100;
  } else {
    const pointsRequiredForCurrentLevel = levels[currentLevel].step;
    const pointsRequiredForNextLevel = levels[nextLevel].step;
    const pointsProgress = currentPoints - pointsRequiredForCurrentLevel;
    const pointsToNextLevel = pointsRequiredForNextLevel - pointsRequiredForCurrentLevel;
    if (currentPoints >= pointsRequiredForNextLevel) {
      
    }
    /* console.log(currentPoints,pointsRequiredForNextLevel,pointsProgress,(pointsProgress / pointsToNextLevel) * 100) */
    return [(pointsProgress / pointsToNextLevel) * 100, levels[currentLevel].name,levels[nextLevel].step,levels[currentLevel].step,levels[currentLevel]];
  }
}

export async function add_count(value,data) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    balance: data.balance + value
  })
}

export async function update_count(data) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    balance: data.balance,
    lastCo: Timestamp.fromDate(new Date()),
    life: data.life,
    sheep: data.sheep

  })
}

export async function update_count_var(init, data) {
  const usersRef = doc(db, 'friends', data.id);
  const updates = {};
  if (init.sheep !== data.sheep) {
    updates.sheep = data.sheep;
  }
  if (init.lang !== data.lang) {
    updates.lang = data.lang;
  }
  if(init.username !== data.username){
    updates.username = data.username
  }
  if(init.friendsLoaded !== data.firendsLoaded){
    updates.friendsLoaded = data.friendsLoaded
  }
  // Toujours mettre à jour `lastCo` car c'est une nouvelle date à chaque fois
  updates.lastCo = Timestamp.fromDate(new Date());
  // Ne pas faire d'appel API si aucun champ n'a changé
  if (Object.keys(updates).length > 1 || (Object.keys(updates).length === 1 && !('lastCo' in updates))) {
    await updateDoc(usersRef, updates);
  }
}
export async function update_count_varV0(init, data) {
  const usersRef = doc(db, 'users', data.id);
  const updates = {};

  // Comparer chaque champ et n'ajouter que ceux qui ont changé
/*   if (init.balance !== data.balance) {
    updates.balance = data.balance;
  }
  if (init.coef !== data.coef) {
    updates.coef = data.coef;
  }
  if (init.credits !== data.credits) {
    updates.credits = data.credits;
  }
  if (init.dateMulti !== data.dateMulti) {
    updates.dateMulti = data.dateMulti;
  }
  if (init.life !== data.life) {
    updates.life = data.life;
  } */
  if (init.sheep !== data.sheep) {
    updates.sheep = data.sheep;
  }
  if (init.lang !== data.lang) {
    updates.lang = data.lang;
  }
  if(init.username !== data.username){
    updates.username = data.username
  }
  if(init.friendsLoaded !== data.firendsLoaded){
    updates.friendsLoaded = data.friendsLoaded
  }
  // Toujours mettre à jour `lastCo` car c'est une nouvelle date à chaque fois
  updates.lastCo = Timestamp.fromDate(new Date());
  // Ne pas faire d'appel API si aucun champ n'a changé
  if (Object.keys(updates).length > 1 || (Object.keys(updates).length === 1 && !('lastCo' in updates))) {
    await updateDoc(usersRef, updates);
  }
}
export async function update_max_count(data,max) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    max: data.balance,
  })
}

export async function change_lang(data,lang) {
  const usersRef = doc(db, 'friends', data.id)
  await updateDoc(usersRef, {
    lang: lang,
  })
}
export async function change_langV0(data,lang) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    lang: lang,
  })
}

export async function updatelevel(data,level) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    max: level,
  })
}
export async function update_sheepZero(data,val) {
  const usersRef = doc(db, 'friends', data.id)
  await updateDoc(usersRef, {
    sheep: val,
  })
}
export async function update_sheepZeroV0(data,val) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    sheep: val,
  })
}

export async function update_credits_cloud(data,credits,date) {
  if(date){
    const usersRef = doc(db, 'users', data.id)
    await updateDoc(usersRef, {
      credits: credits,
      dateMulti : date
    })
  }else{
    const usersRef = doc(db, 'users', data.id)
    await updateDoc(usersRef, {
      credits: credits,
    })
  }

}

export async function update_pph(data,pph) {
  const usersRef = doc(db, 'users', data.id)
  await updateDoc(usersRef, {
    pph: pph,
  })
}
export async function buy_article(newArticle, userId) {
  try {
      // Check if the document exists
      const q = query(collection(db, 'shop'), where('userId', '==', userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
          // Document exists
          const docRef = querySnapshot.docs[0].ref;
          const data = querySnapshot.docs[0].data();
          let articles = data.articles || [];

          // Check if the article already exists
          const articleIndex = articles.findIndex(article => article.id === newArticle.id);

          if (articleIndex !== -1) {
              // Article exists, increment the level
              articles[articleIndex].level += 1;
          } else {
              // Article does not exist, add it to the array
              articles.push(newArticle);
          }

          await updateDoc(docRef, { articles });
      } else {
          // Document does not exist, create it
          const newDoc = {
              userId: userId,
              articles: [newArticle]
          };
          await setDoc(doc(collection(db, 'shop')), newDoc);
      }
  } catch (error) {
      console.error("Error buying article: ", error);
  }
}

export async function buy_article3(newArticle, userID) {
  const shopRef = collection(db, 'shop');
  const q = query(shopRef, where('userId', '==', userID));
  
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    // Le document existe, mettez-le à jour
    querySnapshot.forEach(async (docSnapshot) => {
      const oldArticles = docSnapshot.data().articles || [];
      const usersRef = doc(db, 'shop', docSnapshot.id);
      
      await updateDoc(usersRef, {
        articles: [...oldArticles, newArticle]
      });
    });
  } else {
    // Le document n'existe pas, créez-le
    const newDocRef = doc(collection(db, 'shop'));
    await setDoc(newDocRef, {
      userId: userID,
      articles: [newArticle]
    });
  }
}
export async function get_shop (userID) {
  const articles = []
  const q = query(collection(db, 'shop'), where('userId', '==', userID))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    articles.push(data)
  })
  return articles
}

export async function get_tasks (userID) {
  const tasks = []
  const q = query(collection(db, 'tasks'), where('userId', '==', userID))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    tasks.push(data)
  })
  return tasks
}

export async function update_task (newTask, userId) {
  try {
      // Check if the document exists
      const q = query(collection(db, 'tasks'), where('userId', '==', userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
          // Document exists
          const docRef = querySnapshot.docs[0].ref;
          const data = querySnapshot.docs[0].data();
          let tasks = data.tasks || [];

          // Check if the article already exists
          const taskIndex = tasks.findIndex(task => task.id === newTask.id);

          if (taskIndex !== -1) {
              // Article exists, increment the level
          } else {
              // Article does not exist, add it to the array
              tasks.push(newTask);
          }

          await updateDoc(docRef, { tasks });
      } else {

          // Document does not exist, create it
          const newDoc = {
              userId: userId,
              tasks: [newTask]
          };
          await setDoc(doc(collection(db, 'tasks')), newDoc);
      }
  } catch (error) {
      console.error("Error buying article: ", error);
  }
}


export async function get_friendsOld (userID) {
  const friends = []
  const q = query(collection(db, 'friends'), where('userId', '==', userID))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    friends.push(data)
  })
  return friends
}


export async function get_leaderboard () {
  const friends = []
  const q = query(collection(db, 'leaderboard'), orderBy('refs', 'desc'),limit(7))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    friends.push(data)
  })
  return friends
}

export async function get_leaderboard_user (userId) {
  const friends = []
  const q = query(collection(db, 'leaderboard'), where('userId','==' ,userId))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    friends.push(data)
  })
  return friends
}

export async function get_all_firends(userId) {
  const friends = []
  const q = query(collection(db, 'friends'), where('sponsoredBy','==' ,userId))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const data = doc.data()
    data.id = doc.id
    friends.push(data)
  })
  return friends
}

export async function get_friends(userID, lastVisible = null) {
  let q;

  if (!lastVisible) {
    // Première requête ou réinitialisation de la pagination
    q = query(
      collection(db, 'friends'),
      where('sponsoredBy', '==', userID),
      orderBy('sheep', 'desc'),
      limit(15)
    );
  } else {
    // Requête paginée pour charger plus d'amis
    q = query(
      collection(db, 'friends'),
      where('sponsoredBy', '==', userID),
      startAfter(lastVisible),
      orderBy('sheep', 'desc'),
      limit(15)
    );
  }


  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs ?? [];
  const lastVisibleDoc = docs.length > 0 ? docs[docs.length - 1] : null;

  return { docs, lastVisible: lastVisibleDoc };
}


export const getTelegramUserInfo2 = async (userId) => {
  const botToken="7476965298:AAGAjM78w0wBfuvKq89tUYmvbDVWiIrjhcM"
  const url = `https://api.telegram.org/bot${botToken}/getChat?chat_id=${userId}`;
  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.ok) {
      return data.result;
    } else {
      throw new Error(data.description);
    }
  } catch (error) {
    console.error('Error fetching Telegram user info:', error);
    return null;
  }
};

export const getTelegramUserInfo = async (userId) => {
  const botToken="7476965298:AAGAjM78w0wBfuvKq89tUYmvbDVWiIrjhcM"
  const url = `https://api.telegram.org/bot${botToken}/getChat?chat_id=${userId}`;
  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.ok) {
     
      //const { username } = data.result;
      return data.result;
    } else {
      throw new Error(data.description);
    }
  } catch (error) {
    console.error('Error fetching Telegram user info:', error);
    return null;
  }
};