const translations = {
  TR : [
     {
       "FR": "Tapez et gagnez des pièces",
       "ENGLISH": "Tap and earn coins",
       "RUSSIAN": "Нажимайте и зарабатывайте монеты",
       "TURKISH": "Dokunun ve madeni paraları kazanın",
       "HINDI": "टैप करें और सिक्के कमाएँ",
       "FARSI": "ضربه بزنید و سکه‌ها را کسب کنید",
       "Español": "Toca y gana monedas",
       "Português": "Toque e ganhe moedas",
       "Bahasa Indonesia": "Ketuk dan dapatkan koin",
       "Vietnam": "Nhấn và kiếm tiền xu",
       "Thailande": "แตะและรับเหรียญ",
       "UZBEK": "Bosing va tanga toping",
       "DEUTSCH": "Tippen und Münzen verdienen",
       "TAGALOG": "Pindutin at kumita ng mga barya"
     },
     {
       "FR": "Ces pièces seront précieuses lors du Airdrop.",
       "ENGLISH": "These coins will be valuable during the Airdrop.",
       "RUSSIAN": "Эти монеты будут ценными во время Airdrop.",
       "TURKISH": "Bu paralar Airdrop sırasında değerli olacak.",
       "HINDI": "ये सिक्के Airdrop के दौरान मूल्यवान होंगे।",
       "FARSI": "این سکه‌ها در زمان Airdrop باارزش خواهند بود.",
       "Español": "Estas monedas serán valiosas durante el Airdrop.",
       "Português": "Essas moedas serão valiosas durante o Airdrop.",
       "Bahasa Indonesia": "Koin ini akan berharga selama Airdrop.",
       "Vietnam": "Những đồng xu này sẽ có giá trị trong đợt Airdrop.",
       "Thailande": "เหรียญเหล่านี้จะมีค่าในช่วง Airdrop",
       "UZBEK": "Bu tangalar Airdrop paytida qimmatli bo‘ladi.",
       "DEUTSCH": "Diese Münzen werden während des Airdrops wertvoll sein.",
       "TAGALOG": "Ang mga baryang ito ay magiging mahalaga sa panahon ng Airdrop."
     },
     {
       "FR": "Invitez vos amis",
       "ENGLISH": "Invite your friends",
       "RUSSIAN": "Приглашайте друзей",
       "TURKISH": "Arkadaşlarınızı davet edin",
       "HINDI": "अपने दोस्तों को आमंत्रित करें",
       "FARSI": "دوستان خود را دعوت کنید",
       "Español": "Invita a tus amigos",
       "Português": "Convide seus amigos",
       "Bahasa Indonesia": "Undang teman-teman Anda",
       "Vietnam": "Mời bạn bè của bạn",
       "Thailande": "เชิญเพื่อนของคุณ",
       "UZBEK": "Do‘stlaringizni taklif qiling",
       "DEUTSCH": "Lade deine Freunde ein",
       "TAGALOG": "Imbitahan ang iyong mga kaibigan"
     },
     {
       "FR": "Invite le plus d'amis et reçois des bonus !",
       "ENGLISH": "Invite as many friends as possible and receive bonuses!",
       "RUSSIAN": "Пригласите как можно больше друзей и получайте бонусы!",
       "TURKISH": "En fazla arkadaşı davet edin ve bonuslar kazanın!",
       "HINDI": "जितना हो सके अधिक से अधिक दोस्तों को आमंत्रित करें और बोनस प्राप्त करें!",
       "FARSI": "تا جایی که می‌توانید دوستان بیشتری دعوت کنید و پاداش بگیرید!",
       "Español": "¡Invita a la mayor cantidad de amigos posible y recibe bonificaciones!",
       "Português": "Convide o maior número possível de amigos e receba bônus!",
       "Bahasa Indonesia": "Undang sebanyak mungkin teman dan dapatkan bonus!",
       "Vietnam": "Mời càng nhiều bạn càng tốt và nhận tiền thưởng!",
       "Thailande": "เชิญเพื่อนให้มากที่สุดเท่าที่จะทำได้และรับโบนัส!",
       "UZBEK": "Imkon qadar ko‘proq do‘stlaringizni taklif qiling va bonuslarni oling!",
       "DEUTSCH": "Lade so viele Freunde wie möglich ein und erhalte Boni!",
       "TAGALOG": "Imbitahan ang pinakamaraming kaibigan at makakuha ng mga bonus!"
     },
     {
       "FR": "Invitez vos amis et gagnez des pièces",
       "ENGLISH": "Invite your friends and earn coins",
       "RUSSIAN": "Приглашайте друзей и зарабатывайте монеты",
       "TURKISH": "Arkadaşlarınızı davet edin ve madeni paralar kazanın",
       "HINDI": "अपने दोस्तों को आमंत्रित करें और सिक्के कमाएँ",
       "FARSI": "دوستان خود را دعوت کنید و سکه کسب کنید",
       "Español": "Invita a tus amigos y gana monedas",
       "Português": "Convide seus amigos e ganhe moedas",
       "Bahasa Indonesia": "Undang teman-teman Anda dan dapatkan koin",
       "Vietnam": "Mời bạn bè của bạn và kiếm tiền xu",
       "Thailande": "เชิญเพื่อนของคุณและรับเหรียญ",
       "UZBEK": "Do‘stlaringizni taklif qiling va tanga toping",
       "DEUTSCH": "Lade deine Freunde ein und verdiene Münzen",
       "TAGALOG": "Imbitahan ang iyong mga kaibigan at kumita ng mga barya"
     },
     {
       "FR": "Plus vous invitez, plus vous gagnez de pièces surtout pour les premiums et bons joueurs.",
       "ENGLISH": "The more friends you invite, the more coins you earn, especially for premium and good players.",
       "RUSSIAN": "Чем больше друзей вы приглашаете, тем больше монет вы зарабатываете, особенно для премиум и хороших игроков.",
       "TURKISH": "Ne kadar çok davet ederseniz, o kadar çok madeni para kazanırsınız, özellikle premium ve iyi oyuncular için.",
       "HINDI": "जितने अधिक दोस्तों को आप आमंत्रित करेंगे, उतने अधिक सिक्के आप कमाएँगे, विशेष रूप से प्रीमियम और अच्छे खिलाड़ियों के लिए।",
       "FARSI": "هرچه بیشتر دوستان خود را دعوت کنید، سکه‌های بیشتری کسب خواهید کرد، به خصوص برای بازیکنان پریمیوم و خوب.",
       "Español": "Cuantos más amigos invites, más monedas ganarás, especialmente para jugadores premium y buenos jugadores.",
       "Português": "Quanto mais amigos você convidar, mais moedas você ganhará, especialmente para jogadores premium e bons jogadores.",
       "Bahasa Indonesia": "Semakin banyak teman yang Anda undang, semakin banyak koin yang Anda dapatkan, terutama untuk pemain premium dan pemain bagus.",
       "Vietnam": "Bạn mời càng nhiều bạn bè, bạn càng kiếm được nhiều tiền xu, đặc biệt đối với những người chơi cao cấp và giỏi.",
       "Thailande": "ยิ่งเชิญเพื่อนมาก ยิ่งได้รับเหรียญมาก โดยเฉพาะสำหรับผู้เล่นพรีเมียมและผู้เล่นดี",
       "UZBEK": "Qancha ko‘p do‘stlarni taklif qilsangiz, shuncha ko‘p tanga topasiz, ayniqsa premium va yaxshi o‘yinchilar uchun.",
       "DEUTSCH": "Je mehr Freunde du einlädst, desto mehr Münzen verdienst du, besonders für Premium- und gute Spieler.",
       "TAGALOG": "Mas marami kang imbitahan, mas marami kang kikitain na barya, lalo na para sa mga premium at magagaling na manlalaro."
     },
     {
       "FR": "Achetez et améliorez vos cartes",
       "ENGLISH": "Buy and upgrade your cards",
       "RUSSIAN": "Покупайте и улучшайте свои карты",
       "TURKISH": "Kartlarınızı satın alın ve geliştirin",
       "HINDI": "अपने कार्ड खरीदें और अपग्रेड करें",
       "FARSI": "کارت‌های خود را بخرید و ارتقاء دهید",
       "Español": "Compra y mejora tus cartas",
       "Português": "Compre e melhore seus cartões",
       "Bahasa Indonesia": "Beli dan tingkatkan kartu Anda",
       "Vietnam": "Mua và nâng cấp thẻ của bạn",
       "Thailande": "ซื้อและอัพเกรดการ์ดของคุณ",
       "UZBEK": "Kartalaringizni sotib oling va yangilang",
       "DEUTSCH": "Kaufe und verbessere deine Karten",
       "TAGALOG": "Bumili at i-upgrade ang iyong mga card"
     },
     {
       "FR": "Générez de meilleurs rendements et augmentez vos gains.",
       "ENGLISH": "Generate better returns and increase your earnings.",
       "RUSSIAN": "Получайте лучшие доходы и увеличивайте свои заработки.",
       "TURKISH": "Daha iyi getiriler elde edin ve kazancınızı artırın.",
       "HINDI": "बेहतर रिटर्न उत्पन्न करें और अपनी कमाई बढ़ाएँ।",
       "FARSI": "بازده بهتری تولید کنید و درآمد خود را افزایش دهید.",
       "Español": "Genera mejores rendimientos y aumenta tus ganancias.",
       "Português": "Gere melhores retornos e aumente seus ganhos.",
       "Bahasa Indonesia": "Hasilkan pengembalian yang lebih baik dan tingkatkan pendapatan Anda.",
       "Vietnam": "Tạo ra lợi nhuận tốt hơn và tăng thu nhập của bạn.",
       "Thailande": "สร้างผลตอบแทนที่ดีกว่าและเพิ่มรายได้ของคุณ",
       "UZBEK": "Yaxshi daromad oling va daromadingizni oshiring.",
       "DEUTSCH": "Erziele bessere Renditen und steigere deine Gewinne.",
       "TAGALOG": "Kumuha ng mas magagandang kita at pataasin ang iyong mga kita."
     },
     {
       "FR": "Soyez prêts pour le Airdrop",
       "ENGLISH": "Be ready for the Airdrop",
       "RUSSIAN": "Будьте готовы к Airdrop",
       "TURKISH": "Airdrop için hazır olun",
       "HINDI": "Airdrop के लिए तैयार रहें",
       "FARSI": "برای Airdrop آماده باشید",
       "Español": "Prepárate para el Airdrop",
       "Português": "Esteja pronto para o Airdrop",
       "Bahasa Indonesia": "Bersiaplah untuk Airdrop",
       "Vietnam": "Hãy sẵn sàng cho Airdrop",
       "Thailande": "เตรียมพร้อมสำหรับ Airdrop",
       "UZBEK": "Airdrop uchun tayyor bo‘ling",
       "DEUTSCH": "Sei bereit für den Airdrop",
       "TAGALOG": "Maging handa para sa Airdrop"
     },
     {
       "FR": "Vous êtes toujours Early et nous n'oublierons jamais les plus engagés, loyaux et forts.",
       "ENGLISH": "You are always early, and we will never forget the most engaged, loyal, and strong players.",
       "RUSSIAN": "Вы всегда на шаг впереди, и мы никогда не забудем самых преданных, лояльных и сильных.",
       "TURKISH": "Hâlâ erkendesiniz ve en sadık, bağlı ve güçlü oyuncuları asla unutmayacağız.",
       "HINDI": "आप हमेशा जल्दी हैं, और हम सबसे प्रतिबद्ध, वफादार और मजबूत खिलाड़ियों को कभी नहीं भूलेंगे।",
       "FARSI": "شما همیشه پیشرو هستید و ما هرگز بازیکنان متعهد، وفادار و قوی را فراموش نخواهیم کرد.",
       "Español": "Siempre estás a la vanguardia, y nunca olvidaremos a los jugadores más comprometidos, leales y fuertes.",
       "Português": "Você está sempre adiantado, e nunca esqueceremos os jogadores mais engajados, leais e fortes.",
       "Bahasa Indonesia": "Anda selalu lebih awal, dan kami tidak akan pernah melupakan pemain yang paling terlibat, setia, dan kuat.",
       "Vietnam": "Bạn luôn đi trước, và chúng tôi sẽ không bao giờ quên những người chơi tận tâm, trung thành và mạnh mẽ nhất.",
       "Thailande": "คุณมักจะอยู่ในช่วงแรก และเราจะไม่มีวันลืมผู้เล่นที่ทุ่มเท ซื่อสัตย์ และแข็งแกร่งที่สุด",
       "UZBEK": "Siz har doim oldindasiz va biz eng sodiq, bag‘ishlangan va kuchli o‘yinchilarni hech qachon unutmaymiz.",
       "DEUTSCH": "Du bist immer früh dran, und wir werden die engagiertesten, loyalsten und stärksten Spieler niemals vergessen.",
       "TAGALOG": "Palagi kang nauuna, at hinding-hindi namin makakalimutan ang pinaka-nakikibahagi, tapat, at malalakas na manlalaro."
     },
     {
       "FR": "Abonnez-vous pour suivre toute l'actualité",
       "ENGLISH": "Subscribe to follow all the news",
       "RUSSIAN": "Подпишитесь, чтобы следить за всеми новостями",
       "TURKISH": "Tüm güncellemeleri takip etmek için abone olun",
       "HINDI": "सभी समाचारों का पालन करने के लिए सदस्यता लें",
       "FARSI": "برای دنبال کردن تمام اخبار، مشترک شوید",
       "Español": "Suscríbete para seguir todas las noticias",
       "Português": "Inscreva-se para acompanhar todas as novidades",
       "Bahasa Indonesia": "Berlangganan untuk mengikuti semua berita",
       "Vietnam": "Đăng ký để theo dõi tất cả tin tức",
       "Thailande": "สมัครสมาชิกเพื่อติดตามข่าวสารทั้งหมด",
       "UZBEK": "Barcha yangiliklarni kuzatish uchun obuna bo‘ling",
       "DEUTSCH": "Abonniere, um alle Neuigkeiten zu verfolgen",
       "TAGALOG": "Mag-subscribe upang sundan ang lahat ng balita"
     },
     {
       "FR": "Nous rejoindre",
       "ENGLISH": "Join us",
       "RUSSIAN": "Присоединяйтесь к нам",
       "TURKISH": "Bize katılın",
       "HINDI": "हमसे जुड़ें",
       "FARSI": "به ما بپیوندید",
       "Español": "Únete a nosotros",
       "Português": "Junte-se a nós",
       "Bahasa Indonesia": "Bergabunglah dengan kami",
       "Vietnam": "Tham gia với chúng tôi",
       "Thailande": "เข้าร่วมกับเรา",
       "UZBEK": "Bizga qo‘shiling",
       "DEUTSCH": "Treten Sie uns bei",
       "TAGALOG": "Sumali sa amin"
     },
     {
       "FR": "Retour",
       "ENGLISH": "Back",
       "RUSSIAN": "Назад",
       "TURKISH": "Geri",
       "HINDI": "वापस",
       "FARSI": "بازگشت",
       "Español": "Volver",
       "Português": "Voltar",
       "Bahasa Indonesia": "Kembali",
       "Vietnam": "Quay lại",
       "Thailande": "กลับ",
       "UZBEK": "Orqaga",
       "DEUTSCH": "Zurück",
       "TAGALOG": "Bumalik"
     },
     {
       "FR": "Continue",
       "ENGLISH": "Continue",
       "RUSSIAN": "Продолжить",
       "TURKISH": "Devam et",
       "HINDI": "जारी रखें",
       "FARSI": "ادامه دهید",
       "Español": "Continuar",
       "Português": "Continuar",
       "Bahasa Indonesia": "Lanjutkan",
       "Vietnam": "Tiếp tục",
       "Thailande": "ดำเนินการต่อ",
       "UZBEK": "Davom eting",
       "DEUTSCH": "Fortsetzen",
       "TAGALOG": "Ipagpatuloy"
     },
   {
  "ENGLISH": "How does our WolfMaster system work?",
  "RUSSIAN": "Как работает наша система WolfMaster?",
  "TURKISH": "WolfMaster sistemimiz nasıl çalışır?",
  "HINDI": "हमारी WolfMaster प्रणाली कैसे काम करती है?",
  "FARSI": "سیستم WolfMaster ما چگونه کار می‌کند؟",
  "Español": "¿Cómo funciona nuestro sistema WolfMaster?",
  "Português": "Como funciona nosso sistema WolfMaster?",
  "Bahasa Indonesia": "Bagaimana sistem WolfMaster kami bekerja?",
  "Vietnam": "Hệ thống WolfMaster của chúng tôi hoạt động như thế nào?",
  "Thai": "ระบบ WolfMaster ของเราทำงานอย่างไร?",
  "UZBEK": "WolfMaster tizimimiz qanday ishlaydi?",
  "DEUTSCH": "Wie funktioniert unser WolfMaster-System?",
  "TAGALOG": "Paano gumagana ang aming WolfMaster system?"
},
 {
  "ENGLISH": "Directly & Raffle:",
  "RUSSIAN": "Напрямую и розыгрыш:",
  "TURKISH": "Doğrudan ve Çekilişle:",
  "HINDI": "सीधे और लॉटरी के माध्यम से:",
  "FARSI": "مستقیم و قرعه‌کشی",
  "Español": "Directamente y Sorteo:",
  "Português": "Diretamente e Sorteio:",
  "Bahasa Indonesia": "Langsung & Undian:",
  "Vietnam": "Trực tiếp & Rút thăm:",
  "Thai": "โดยตรงและจับรางวัล:",
  "UZBEK": "To'g'ridan-to'g'ri & Taqdimot:",
  "DEUTSCH": "Direkt & Verlosung:",
  "TAGALOG": "Direkta & Raffle:"
},
{
  "ENGLISH": "1. Change your name on X and Telegram to | WOLFERA & add",
  "RUSSIAN": "Измените свое имя в X и Telegram на | WOLFERA и добавьте его",
  "TURKISH": "X ve Telegram'daki adınızı | WOLFERA olarak değiştirin ve ekleyin.",
  "HINDI": "X और Telegram पर अपना नाम बदलकर | WOLFERA करें और जोड़ें।",
  "FARSI": "نام خود را در X و Telegram به | WOLFERA تغییر دهید و اضافه کنید.",
  "Español": "Cambia tu nombre en X y Telegram a | WOLFERA y agrégalo",
  "Português": "Altere seu nome no X e no Telegram para | WOLFERA e adicione",
  "Bahasa Indonesia": "Ubah nama Anda di X dan Telegram menjadi | WOLFERA & tambahkan",
  "Vietnam": "Đổi tên của bạn trên X và Telegram thành | WOLFERA & thêm",
  "Thai": "เปลี่ยนชื่อของคุณบน X และ Telegram เป็น | WOLFERA & เพิ่ม",
  "UZBEK": "X va Telegram'dagi ismingizni | WOLFERA ga o'zgartiring va qo'shing",
  "DEUTSCH": "Ändere deinen Namen auf X und Telegram zu | WOLFERA & füge hinzu",
  "TAGALOG": "Baguhin ang iyong pangalan sa X at Telegram sa | WOLFERA & idagdag"
},
{
  "ENGLISH": "2. Change your profile picture to one of our media.",
  "RUSSIAN": "Измените свою фотографию профиля на одну из наших медиа",
  "TURKISH": "Profil resminizi medyamızdan biriyle değiştirin.",
  "HINDI": "अपनी प्रोफ़ाइल तस्वीर को हमारे मीडिया में से किसी एक से बदलें।",
  "FARSI": "عکس پروفایل خود را به یکی از رسانه‌های ما تغییر دهید.",
  "Español": "Cambia tu foto de perfil a una de nuestras imágenes",
  "Português": "Altere sua foto de perfil para uma das nossas mídias",
  "Bahasa Indonesia": "Ubah foto profil Anda menjadi salah satu media kami",
  "Vietnam": "Đổi ảnh hồ sơ của bạn thành một trong những phương tiện của chúng tôi",
  "Thai": "เปลี่ยนรูปโปรไฟล์ของคุณเป็นหนึ่งในสื่อของเรา",
  "UZBEK": "Profil rasmingizni bizning media'dan biriga o'zgartiring",
  "DEUTSCH": "Ändere dein Profilbild auf eines unserer Medien",
  "TAGALOG": "Baguhin ang iyong profile picture sa isa sa aming mga media"
},
{
  "ENGLISH": "3. Invite 3 friends and attain level 3 to get role.",
  "RUSSIAN": "Пригласите 3 друзей и достигните 3 уровня, чтобы получить роль",
  "TURKISH": "3 arkadaş davet edin ve rol almak için 3. seviyeye ulaşın.",
  "HINDI": "दोस्तों को आमंत्रित करें और भूमिका प्राप्त करने के लिए स्तर 3 तक पहुंचें।",
  "FARSI": "۳ دوست را دعوت کنید و به سطح ۳ برسید تا نقش بگیرید.",
  "Español": "Invita a 3 amigos y alcanza el nivel 3 para obtener un rol",
  "Português": "Convide 3 amigos e atinja o nível 3 para obter o papel",
  "Bahasa Indonesia": "Undang 3 teman dan capai level 3 untuk mendapatkan peran",
  "Vietnam": "Mời 3 người bạn và đạt cấp 3 để nhận vai trò",
  "Thai": "เชิญเพื่อน 3 คนและถึงระดับ 3 เพื่อรับบทบาท",
  "UZBEK": "Do'stingizni taklif qiling va rolni olish uchun 3-darajaga eting",
  "DEUTSCH": "Lade 3 Freunde ein und erreiche Level 3, um die Rolle zu erhalten",
  "TAGALOG": "Imbitahan ang 3 kaibigan at umabot sa level 3 upang makuha ang role"
},
{
  "ENGLISH": "4. Check 'Task' to win our games, activities, and special quests.",
  "RUSSIAN": "Проверяйте «Задание», чтобы выиграть наши игры, мероприятия и специальные квесты",
  "TURKISH": "Oyunlarımızı, etkinliklerimizi ve özel görevlerimizi kazanmak için 'Görev' kontrol edin.",
  "HINDI": "हमारे खेल, गतिविधियों और विशेष खोजों को जीतने के लिए 'कार्य' देखें।",
  "FARSI": "وظیفه' را بررسی کنید تا بازی‌ها، فعالیت‌ها و مأموریت‌های ویژه ما را برنده شوید.",
  "Español": "Revisa ‘Tarea’ para ganar en nuestros juegos, actividades y misiones especiales",
  "Português": "Verifique a ‘Tarefa’ para ganhar em nossos jogos, atividades e missões especiais",
  "Bahasa Indonesia": "Periksa ‘Tugas’ untuk memenangkan permainan, aktivitas, dan misi khusus kami",
  "Vietnam": "Kiểm tra ‘Nhiệm vụ’ để thắng các trò chơi, hoạt động và nhiệm vụ đặc biệt của chúng tôi",
  "Thai": "ตรวจสอบ ‘ภารกิจ’ เพื่อชนะเกม กิจกรรม และภารกิจพิเศษของเรา",
  "UZBEK": "O'yinlarimiz, faoliyatlarimiz va maxsus topshiriqlarni yutish uchun ‘Task’ ni tekshiring",
  "DEUTSCH": "Überprüfe ‚Aufgaben‘, um unsere Spiele, Aktivitäten und speziellen Quests zu gewinnen",
  "TAGALOG": "Tingnan ang ‘Task’ para manalo sa aming mga laro, aktibidad, at espesyal na misyon"
},
{
  "ENGLISH": "Contributing to the Community:",
  "RUSSIAN": "Вклад в сообщество:",
  "TURKISH": "Topluluğa Katkıda Bulunma:",
  "HINDI": "समुदाय में योगदान देना:",
  "FARSI": "مشارکت در جامعه:",
  "Español": "Contribuyendo a la Comunidad:",
  "Português": "Contribuindo para a Comunidade:",
  "Bahasa Indonesia": "Berkontribusi pada Komunitas:",
  "Vietnam": "Đóng góp cho Cộng đồng:",
  "Thai": "การมีส่วนร่วมในชุมชน:",
  "UZBEK": "Hamjamiyatga Hissa Qo'shish:",
  "DEUTSCH": "Beitrag zur Community:",
  "TAGALOG": "Pag-aambag sa Komunidad:"
},
{
  "ENGLISH": "1. Engage in chat, comments and AMA with genuine conversations.",
  "RUSSIAN": "Участвуйте в чатах, комментариях и AMA с искренними разговорами",
  "TURKISH": "Sohbetlerde, yorumlarda ve AMA'da samimi sohbetlere katılın.",
  "HINDI": "चैट, टिप्पणियों और AMA में वास्तविक बातचीत में शामिल हों।",
  "FARSI": "در چت‌ها، نظرات و AMA با گفتگوهای واقعی شرکت کنید.",
  "Español": "Participa en chats, comentarios y AMA con conversaciones genuinas",
  "Português": "Participe de chats, comentários e AMA com conversas genuínas",
  "Bahasa Indonesia": "Terlibat dalam obrolan, komentar, dan AMA dengan percakapan yang tulus",
  "Vietnam": "Tham gia vào trò chuyện, bình luận và AMA với các cuộc trò chuyện chân thật.",
  "Thai": "มีส่วนร่วมในการแชท ความคิดเห็น และ AMA ด้วยการสนทนาที่แท้จริง",
  "UZBEK": "Chat, izohlar va AMA da haqiqiy suhbatlar bilan shug'ullaning",
  "DEUTSCH": "Engagiere dich in Chats, Kommentaren und AMAs mit echten Gesprächen",
  "TAGALOG": "Makipag-ugnayan sa chat, mga komento at AMA na may tunay na pag-uusap"
},
{
  "ENGLISH": "2. Be creative on Twitter with fan art, memes, or Wolfera artwork.",
  "RUSSIAN": "Будьте креативны в Twitter с фан-артами, мемами или произведениями Wolfera",
  "TURKISH": "Twitter'da hayran sanatı, memler veya Wolfera eserleriyle yaratıcı olun.",
  "HINDI": "Twitter पर फैन आर्ट, मीम्स, या Wolfera कला के साथ रचनात्मक बनें।",
  "FARSI": "در توییتر با هنر طرفداران، میم‌ها یا آثار هنری Wolfera خلاق باشید.",
  "Español": "Sé creativo en Twitter con arte de fans, memes o ilustraciones de Wolfera",
  "Português": "Seja criativo no Twitter com fan art, memes ou ilustrações do Wolfera",
  "Bahasa Indonesia": "Jadilah kreatif di Twitter dengan fan art, meme, atau karya seni Wolfera",
  "Vietnam": "Sáng tạo trên Twitter với fan art, meme hoặc tác phẩm nghệ thuật Wolfera.",
  "Thai": "สร้างสรรค์บน Twitter ด้วยแฟนอาร์ต มีม หรือผลงานศิลปะของ Wolfera",
  "UZBEK": "Twitter'da fan san'ati, meme yoki Wolfera san'ati bilan ijodkor bo'ling",
  "DEUTSCH": "Sei kreativ auf Twitter mit Fan-Art, Memes oder Wolfera-Kunstwerken",
  "TAGALOG": "Maging malikhain sa Twitter gamit ang fan art, memes, o Wolfera artwork"
},
{
  "ENGLISH": "3. Follow & post on Twitter, Instagram, and tag @Wolfera_io.",
  "RUSSIAN": "Следуйте и публикуйте сообщения в Twitter, Instagram и отмечайте @Wolfera_io",
  "TURKISH": "Twitter, Instagram'da takip edin ve @Wolfera_io'yu etiketleyerek paylaşım yapın.",
  "HINDI": "Twitter, Instagram पर फॉलो करें और @Wolfera_io को टैग करके पोस्ट करें।",
  "FARSI": "در توییتر و اینستاگرام دنبال کنید و با تگ @Wolfera_io پست کنید.",
  "Español": "Sigue y publica en Twitter e Instagram, y etiqueta a @Wolfera_io",
  "Português": "Siga e publique no Twitter, Instagram, e marque @Wolfera_io",
  "Bahasa Indonesia": "Ikuti & posting di Twitter, Instagram, dan tag @Wolfera_io",
  "Vietnam": "Theo dõi & đăng bài trên Twitter, Instagram và gắn thẻ @Wolfera_io",
  "Thai": "ติดตามและโพสต์บน Twitter, Instagram และแท็ก @Wolfera_io",
  "UZBEK": "Twitter, Instagram'da kuzatib boring va @Wolfera_io ni tag qiling",
  "DEUTSCH": "Folge und poste auf Twitter, Instagram und tagge @Wolfera_io",
  "TAGALOG": "Sundan at mag-post sa Twitter, Instagram, at i-tag ang @Wolfera_io"
},
{
  "ENGLISH": "Nomination:",
  "RUSSIAN": "Номинация:",
  "TURKISH": "Adaylık:",
  "HINDI": "नामांकन:",
  "FARSI": "نامزدی:",
  "Español": "Nominación:",
  "Português": "Nomeação:",
  "Bahasa Indonesia": "Nominasi:",
  "Vietnam": "Đề cử:",
  "Thai": "การเสนอชื่อ:",
  "UZBEK": "Nomzodlik:",
  "DEUTSCH": "Nominierung:",
  "TAGALOG": "Nomination:"
},
{
  "ENGLISH": "Mods and team will personally nominate members who most deserve the WolfMaster",
  "RUSSIAN": "Модераторы и команда лично номинируют членов, которые больше всего заслуживают звания WolfMaster",
  "TURKISH": "Modlar ve ekip, WolfMaster'ı en çok hak eden üyeleri şahsen aday gösterecektir.",
  "HINDI": "मॉड और टीम व्यक्तिगत रूप से उन सदस्यों को नामित करेंगे जो WolfMaster के सबसे योग्य हैं।",
  "FARSI": "مدیران و تیم به طور شخصی اعضایی را که بیشترین استحقاق WolfMaster را دارند، نامزد خواهند کرد.",
  "Español": "Los moderadores y el equipo nominarán personalmente a los miembros que más merezcan el título de WolfMaster",
  "Português": "Os moderadores e a equipe nomearão pessoalmente os membros que mais merecem o título de WolfMaster",
  "Bahasa Indonesia": "Mod dan tim akan secara pribadi menominasikan anggota yang paling pantas menjadi WolfMaster",
  "Vietnam": "Mod và đội ngũ sẽ đích thân đề cử những thành viên xứng đáng nhất trở thành WolfMaster",
  "Thai": "ม็อดและทีมจะเสนอชื่อสมาชิกที่สมควรได้รับตำแหน่ง WolfMaster เป็นการส่วนตัว",
  "UZBEK": "Mods va jamoa eng munosib bo'lgan a'zolarni shaxsan nomzod qiladi",
  "DEUTSCH": "Mods und Team werden persönlich Mitglieder nominieren, die den WolfMaster am meisten verdienen",
  "TAGALOG": "Ang mga Mods at team ay personal na magmumungkahi ng mga miyembro na pinaka-karapat-dapat sa WolfMaster"
},
{
  "ENGLISH": "Wallet Dex",
  "RUSSIAN": "Кошелек Dex",
  "TURKISH": "Cüzdan Dex",
  "HINDI": "वॉलेट डेक्स",
  "FARSI": "والت دکس",
  "Español": "Cartera Dex",
  "Português": "Carteira Dex",
  "Bahasa Indonesia": "Dompet Dex",
  "Vietnam": "Ví Dex",
  "Thai": "วอลเล็ท Dex",
  "UZBEK": "Hamyon Dex",
  "DEUTSCH": "Wallet Dex",
  "TAGALOG": "Wallet Dex"
},
{
  "ENGLISH": "Health",
  "RUSSIAN": "Здоровье",
  "TURKISH": "Sağlık",
  "HINDI": "स्वास्थ्य",
  "FARSI": "سلامتی",
  "Español": "Salud",
  "Português": "Saúde",
  "Bahasa Indonesia": "Kesehatan",
  "Vietnam": "Sức khỏe",
  "Thai": "สุขภาพ",
  "UZBEK": "Sog'liq",
  "DEUTSCH": "Gesundheit",
  "TAGALOG": "Kalusugan"
},
 {
  "ENGLISH": "Capital",
  "RUSSIAN": "Капитал",
  "TURKISH": "Sermaye",
  "HINDI": "पूंजी",
  "FARSI": "سرمایه",
  "Español": "Capital",
  "Português": "Capital",
  "Bahasa Indonesia": "Modal",
  "Vietnam": "Vốn",
  "Thai": "ทุน",
  "UZBEK": "Kapital",
  "DEUTSCH": "Kapital",
  "TAGALOG": "Kapital"
},
{
  "ENGLISH": "News",
  "RUSSIAN": "Новости",
  "TURKISH": "Haberler",
  "HINDI": "समाचार",
  "FARSI": "اخبار",
  "Español": "Noticias",
  "Português": "Notícias",
  "Bahasa Indonesia": "Berita",
  "Vietnam": "Tin tức",
  "Thai": "ข่าว",
  "UZBEK": "Yangiliklar",
  "DEUTSCH": "Nachrichten",
  "TAGALOG": "Balita"
},
{
  "ENGLISH": "Daily Fun",
  "RUSSIAN": "Ежедневное развлечение",
  "TURKISH": "Günlük eğlence",
  "HINDI": "दैनिक मज़ा",
  "FARSI": "سرگرمی روزانه",
  "Español": "Diversión diaria",
  "Português": "Diversão diária",
  "Bahasa Indonesia": "Kesenangan harian",
  "Vietnam": "Niềm vui hàng ngày",
  "Thai": "ความสนุกประจำวัน",
  "UZBEK": "Kunlik qiziqarli",
  "DEUTSCH": "Täglicher Spaß",
  "TAGALOG": "Araw-araw na Kasayahan"
},
{
  "ENGLISH": "Profit per day",
  "RUSSIAN": "Прибыль в день",
  "TURKISH": "Günlük kâr",
  "HINDI": "प्रतिदिन का लाभ",
  "FARSI": "سود روزانه",
  "Español": "Ganancias diarias",
  "Português": "Lucro diário",
  "Bahasa Indonesia": "Keuntungan per hari",
  "Vietnam": "Lợi nhuận mỗi ngày",
  "Thai": "กำไรต่อวัน",
  "UZBEK": "Kunlik foyda",
  "DEUTSCH": "Gewinn pro Tag",
  "TAGALOG": "Kita bawat araw"
},
{
  "ENGLISH": "Continuing education",
  "RUSSIAN": "Продолжение обучения",
  "TURKISH": "Sürekli eğitim",
  "HINDI": "निरंतर शिक्षा",
  "FARSI": "آموزش مداوم",
  "Español": "Educación continua",
  "Português": "Educação continuada",
  "Bahasa Indonesia": "Pendidikan berkelanjutan",
  "Vietnam": "Giáo dục liên tục",
  "Thai": "การศึกษาต่อเนื่อง",
  "UZBEK": "Ta'limni davom ettirish",
  "DEUTSCH": "Fortlaufende Weiterbildung",
  "TAGALOG": "Tuloy-tuloy na Edukasyon"
},
{
  "ENGLISH": "Planning and Objectives",
  "RUSSIAN": "Планирование и цели",
  "TURKISH": "Planlama ve hedefler",
  "HINDI": "योजना और उद्देश्य",
  "FARSI": "برنامه‌ریزی و اهداف",
  "Español": "Planificación y objetivos",
  "Português": "Planejamento e objetivos",
  "Bahasa Indonesia": "Perencanaan dan tujuan",
  "Vietnam": "Lập kế hoạch và mục tiêu",
  "Thai": "การวางแผนและวัตถุประสงค์",
  "UZBEK": "Rejalashtirish va maqsadlar",
  "DEUTSCH": "Planung und Ziele",
  "TAGALOG": "Pagpaplano at Layunin"
},
 {
  "ENGLISH": "Financial education",
  "RUSSIAN": "Финансовое образование",
  "TURKISH": "Finansal eğitim",
  "HINDI": "वित्तीय शिक्षा",
  "FARSI": "آموزش مالی",
  "Español": "Educación financiera",
  "Português": "Educação financeira",
  "Bahasa Indonesia": "Pendidikan keuangan",
  "Vietnam": "Giáo dục tài chính",
  "Thai": "การศึกษาด้านการเงิน",
  "UZBEK": "Moliyaviy ta'lim",
  "DEUTSCH": "Finanzbildung",
  "TAGALOG": "Pinansyal na Edukasyon"
},
{
  "ENGLISH": "Personal work life balance",
  "RUSSIAN": "Баланс личной и рабочей жизни",
  "TURKISH": "Kişisel iş-yaşam dengesi",
  "HINDI": "व्यक्तिगत कार्य-जीवन संतुलन",
  "FARSI": "تعادل کار و زندگی شخصی",
  "Español": "Equilibrio entre vida personal y trabajo",
  "Português": "Equilíbrio entre vida pessoal e trabalho",
  "Bahasa Indonesia": "Keseimbangan kerja dan kehidupan pribadi",
  "Vietnam": "Cân bằng công việc và cuộc sống cá nhân",
  "Thai": "สมดุลระหว่างการทำงานและชีวิตส่วนตัว",
  "UZBEK": "Shaxsiy ish va hayot muvozanati",
  "DEUTSCH": "Persönliche Work-Life-Balance",
  "TAGALOG": "Personal na Balanseng Buhay-Trabaho"
},
{
  "ENGLISH": "Restorative sleep",
  "RUSSIAN": "Восстановительный сон",
  "TURKISH": "Onarıcı uyku",
  "HINDI": "पुनर्स्थापनात्मक नींद",
  "FARSI": "خواب بازسازی کننده",
  "Español": "Sueño reparador",
  "Português": "Sono reparador",
  "Bahasa Indonesia": "Tidur yang memulihkan",
  "Vietnam": "Giấc ngủ phục hồi",
  "Thai": "การนอนหลับที่ฟื้นฟูร่างกาย",
  "UZBEK": "Tetik uyqu",
  "DEUTSCH": "Erholsamer Schlaf",
  "TAGALOG": "Nakakapagpahingang Tulog"
},
 {
  "ENGLISH": "Risk management",
  "RUSSIAN": "Управление рисками",
  "TURKISH": "Risk yönetimi",
  "HINDI": "जोखिम प्रबंधन",
  "FARSI": "مدیریت ریسک",
  "Español": "Gestión de riesgos",
  "Português": "Gestão de riscos",
  "Bahasa Indonesia": "Manajemen risiko",
  "Vietnam": "Quản lý rủi ro",
  "Thai": "การจัดการความเสี่ยง",
  "UZBEK": "Xavfni boshqarish",
  "DEUTSCH": "Risikomanagement",
  "TAGALOG": "Pamamahala ng Panganib"
},
{
  "ENGLISH": "Mindfull and meditation",
  "RUSSIAN": "Осознанность и медитация",
  "TURKISH": "Farkındalık ve meditasyon",
  "HINDI": "ध्यान और मेडिटेशन",
  "FARSI": "تمرکز و مدیتیشن",
  "Español": "Atención plena y meditación",
  "Português": "Atenção plena e meditação",
  "Bahasa Indonesia": "Kewaspadaan dan meditasi",
  "Vietnam": "Chánh niệm và thiền",
  "Thai": "การมีสติและการทำสมาธิ",
  "UZBEK": "Diqqat va meditasiya",
  "DEUTSCH": "Achtsamkeit und Meditation",
  "TAGALOG": "Pag-iisip at Meditasyon"
},
{
  "ENGLISH": "Balance nutrition",
  "RUSSIAN": "Сбалансированное питание",
  "TURKISH": "Dengeli beslenme",
  "HINDI": "संतुलित पोषण",
  "FARSI": "تغذیه متعادل",
  "Español": "Nutrición balanceada",
  "Português": "Nutrição balanceada",
  "Bahasa Indonesia": "Nutrisi seimbang",
  "Vietnam": "Dinh dưỡng cân bằng",
  "Thai": "โภชนาการที่สมดุล",
  "UZBEK": "Muvozanatli ovqatlanish",
  "DEUTSCH": "Ausgewogene Ernährung",
  "TAGALOG": "Balanseng Nutrisyon"
},
 {
  "ENGLISH": "AIRDROP COMING SOON",
  "RUSSIAN": "AIRDROP СКОРО",
  "TURKISH": "AIRDROP YAKINDA",
  "HINDI": "एयरड्रॉप जल्द ही आ रहा है",
  "FARSI": "ایردراپ به زودی می‌آید",
  "ESPAÑOL": "AIRDROP PRÓXIMAMENTE",
  "PORTUGUÊS": "AIRDROP EM BREVE",
  "BAHASA INDONESIA": "AIRDROP SEGERA",
  "VIETNAMESE": "AIRDROP SẼ SỚM ĐẾN",
  "THAI": "AIRDROP กำลังมาถึงเร็วๆ นี้",
  "UZBEK": "AIRDROP YAQINDA KELADI",
  "DEUTSCH": "AIRDROP KOMMT BALD",
  "TAGALOG": "MALAPIT NA ANG AIRDROP"
},
 {
  "ENGLISH": "Connect Wallet",
  "RUSSIAN": "Подключить кошелек",
  "TURKISH": "Cüzdanı Bağla",
  "HINDI": "वॉलेट कनेक्ट करें",
  "FARSI": "اتصال کیف پول",
  "ESPAÑOL": "Conectar Billetera",
  "PORTUGUÊS": "Conectar Carteira",
  "BAHASA INDONESIA": "Sambungkan Dompet",
  "VIETNAMESE": "Kết nối Ví",
  "THAI": "เชื่อมต่อกระเป๋าเงิน",
  "UZBEK": "Hamyonni ulash",
  "DEUTSCH": "Wallet verbinden",
  "TAGALOG": "Ikonekta ang Wallet"
},
{
  "ENGLISH": "The earliest and most engaged will be remembered",
  "RUSSIAN": "Самые ранние и активные будут помниться",
  "TURKISH": "En erken ve en aktif olanlar hatırlanacak",
  "HINDI": "सबसे पहले और सबसे सक्रिय व्यक्ति को याद रखा जाएगा",
  "FARSI": "زودترین و پرانگیزه‌ترین‌ها به یاد خواهند ماند",
  "ESPAÑOL": "Los primeros y más comprometidos serán recordados",
  "PORTUGUÊS": "Os primeiros e mais engajados serão lembrados",
  "BAHASA INDONESIA": "Yang paling awal dan paling terlibat akan diingat",
  "VIETNAMESE": "Những người sớm nhất và tham gia nhiều nhất sẽ được ghi nhớ",
  "THAI": "คนที่มาที่แรกและมีส่วนร่วมมากที่สุดจะได้รับการจดจำ",
  "UZBEK": "Birinchi bo'lib kelgan va eng faol bo'lganlar esda qoladi",
  "DEUTSCH": "Die Ersten und Engagiertesten werden in Erinnerung bleiben",
  "TAGALOG": "Ang mga pinakamagaang at pinakaaktibo ay maalala"
},
{
  "ENGLISH": "Tap",
  "RUSSIAN": "Нажмите",
  "TURKISH": "Dokun",
  "HINDI": "टैप करें",
  "FARSI": "لمس کنید",
  "ESPAÑOL": "Tocar",
  "PORTUGUÊS": "Tocar",
  "BAHASA INDONESIA": "Ketuk",
  "VIETNAMESE": "Nhấn",
  "THAI": "แตะ",
  "UZBEK": "Tekshiring",
  "DEUTSCH": "Tippen",
  "TAGALOG": "Tapikin"
},
{
  "ENGLISH": "Mining",
  "RUSSIAN": "Добыча",
  "TURKISH": "Madencilik",
  "HINDI": "खनन",
  "FARSI": "استخراج",
  "ESPAÑOL": "Minería",
  "PORTUGUÊS": "Mineração",
  "BAHASA INDONESIA": "Pertambangan",
  "VIETNAMESE": "Khai thác",
  "THAI": "การขุด",
  "UZBEK": "Konchilik",
  "DEUTSCH": "Mining",
  "TAGALOG": "Pagmimina"
},
{
  "ENGLISH": "Trade",
  "RUSSIAN": "Торговля",
  "TURKISH": "Ticaret",
  "HINDI": "वाणिज्य",
  "FARSI": "تجارت",
  "ESPAÑOL": "Comercio",
  "PORTUGUÊS": "Troca",
  "BAHASA INDONESIA": "Perdagangan",
  "VIETNAMESE": "Giao dịch",
  "THAI": "การซื้อขาย",
  "UZBEK": "Savdo",
  "DEUTSCH": "Handel",
  "TAGALOG": "Kalakalan"
},
{
  "ENGLISH": "Ranking",
  "RUSSIAN": "Рейтинг",
  "TURKISH": "Sıralama",
  "HINDI": "रैंकिंग",
  "FARSI": "رتبه‌بندی",
  "ESPAÑOL": "Clasificación",
  "PORTUGUÊS": "Ranking",
  "BAHASA INDONESIA": "Peringkat",
  "VIETNAMESE": "Xếp hạng",
  "THAI": "จัดอันดับ",
  "UZBEK": "Reyting",
  "DEUTSCH": "Rangliste",
  "TAGALOG": "Ranggo"
},
{
  "ENGLISH": "Friends",
  "RUSSIAN": "Друзья",
  "TURKISH": "Arkadaşlar",
  "HINDI": "मित्र",
  "FARSI": "دوستان",
  "ESPAÑOL": "Amigos",
  "PORTUGUÊS": "Amigos",
  "BAHASA INDONESIA": "Teman",
  "VIETNAMESE": "Bạn bè",
  "THAI": "เพื่อน",
  "UZBEK": "Do'stlar",
  "DEUTSCH": "Freunde",
  "TAGALOG": "Mga Kaibigan"
},
{
  "ENGLISH": "Airdrop",
  "RUSSIAN": "Airdrop",
  "TURKISH": "Airdrop",
  "HINDI": "एयरड्रॉप",
  "FARSI": "Airdrop (ایردراپ)",
  "ESPAÑOL": "Airdrop",
  "PORTUGUÊS": "Airdrop",
  "BAHASA INDONESIA": "Airdrop",
  "VIETNAMESE": "Airdrop",
  "THAI": "Airdrop",
  "UZBEK": "Airdrop",
  "DEUTSCH": "Airdrop",
  "TAGALOG": "Airdrop"

},
{
  "ENGLISH": "Settings",
  "RUSSIAN": "Настройки",
  "TURKISH": "Ayarlar",
  "HINDI": "सेटिंग्स",
  "FARSI": "تنظیمات",
  "ESPAÑOL": "Configuración",
  "PORTUGUÊS": "Configurações",
  "BAHASA INDONESIA": "Pengaturan",
  "VIETNAMESE": "Cài đặt",
  "THAI": "การตั้งค่า",
  "UZBEK": "Sozlamalar",
  "DEUTSCH": "Einstellungen",
  "TAGALOG": "Mga Setting"
},
{
  "ENGLISH": "Choose language",
  "RUSSIAN": "Выберите язык",
  "TURKISH": "Dil seçin",
  "HINDI": "भाषा चुनें",
  "FARSI": "زبان را انتخاب کنید",
  "ESPAÑOL": "Elegir idioma",
  "PORTUGUÊS": "Escolher idioma",
  "BAHASA INDONESIA": "Pilih bahasa",
  "VIETNAMESE": "Chọn ngôn ngữ",
  "THAI": "เลือกภาษา",
  "UZBEK": "Tilni tanlang",
  "DEUTSCH": "Sprache wählen",
  "TAGALOG": "Pumili ng wika"
},
{
  "ENGLISH": "Delete account",
  "RUSSIAN": "Удалить аккаунт",
  "TURKISH": "Hesabı sil",
  "HINDI": "खाता हटाएँ",
  "FARSI": "حذف حساب",
  "ESPAÑOL": "Eliminar cuenta",
  "PORTUGUÊS": "Excluir conta",
  "BAHASA INDONESIA": "Hapus akun",
  "VIETNAMESE": "Xóa tài khoản",
  "THAI": "ลบบัญชี",
  "UZBEK": "Hisobni o'chirish",
  "DEUTSCH": "Konto löschen",
  "TAGALOG": "Tanggalin ang account"
},
{
  "ENGLISH": "Our networks",
  "RUSSIAN": "Наши сети",
  "TURKISH": "Ağlarımız",
  "HINDI": "हमारे नेटवर्क",
  "FARSI": "شبکه‌های ما",
  "ESPAÑOL": "Nuestras redes",
  "PORTUGUÊS": "Nossas redes",
  "BAHASA INDONESIA": "Jaringan kami",
  "VIETNAMESE": "Mạng lưới của chúng tôi",
  "THAI": "เครือข่ายของเรา",
  "UZBEK": "Bizning tarmoqlar",
  "DEUTSCH": "Unsere Netzwerke",
  "TAGALOG": "Aming mga network"
},
{
  "ENGLISH": "Follow us on X",
  "RUSSIAN": "Подписывайтесь на нас в X",
  "TURKISH": "X'te bizi takip edin",
  "HINDI": "हमें X पर फॉलो करें",
  "FARSI": "ما را در X دنبال کنید",
  "ESPAÑOL": "Síguenos en X",
  "PORTUGUÊS": "Siga-nos no X",
  "BAHASA INDONESIA": "Ikuti kami di X",
  "VIETNAMESE": "Theo dõi chúng tôi trên X",
  "THAI": "ติดตามเราบน X",
  "UZBEK": "Bizni X'da kuzatib boring",
  "DEUTSCH": "Folge uns auf X",
  "TAGALOG": "Sundan kami sa X"
},
{
  "ENGLISH": "Join our Telegram",
  "RUSSIAN": "Присоединяйтесь к нашему Telegram",
  "TURKISH": "Telegram'ımıza katılın",
  "HINDI": "हमारे टेलीग्राम से जुड़ें",
  "FARSI": "به تلگرام ما بپیوندید",
  "ESPAÑOL": "Únete a nuestro Telegram",
  "PORTUGUÊS": "Junte-se ao nosso Telegram",
  "BAHASA INDONESIA": "Bergabung dengan Telegram kami",
  "VIETNAMESE": "Tham gia Telegram của chúng tôi",
  "THAI": "เข้าร่วม Telegram ของเรา",
  "UZBEK": "Bizning Telegram-ga qo'shiling",
  "DEUTSCH": "Tritt unserem Telegram bei",
  "TAGALOG": "Sumali sa aming Telegram"
},
{
  "ENGLISH": "Subscribe on Youtube",
  "RUSSIAN": "Подпишитесь на нас на YouTube",
  "TURKISH": "YouTube'da abone olun",
  "HINDI": "यूट्यूब पर सब्सक्राइब करें",
  "FARSI": "در یوتیوب مشترک شوید",
  "ESPAÑOL": "Suscríbete en Youtube",
  "PORTUGUÊS": "Inscreva-se no Youtube",
  "BAHASA INDONESIA": "Langganan di Youtube",
  "VIETNAMESE": "Đăng ký trên Youtube",
  "THAI": "สมัครสมาชิกบน Youtube",
  "UZBEK": "YouTube'da obuna bo'ling",
  "DEUTSCH": "Abonniere uns auf Youtube",
  "TAGALOG": "Mag-subscribe sa Youtube"
},
 {
  "ENGLISH": "Back",
  "RUSSIAN": "Назад",
  "TURKISH": "Geri",
  "HINDI": "वापस",
  "FARSI": "بازگشت",
  "ESPAÑOL": "Atrás",
  "PORTUGUÊS": "Voltar",
  "BAHASA INDONESIA": "Kembali",
  "VIETNAMESE": "Quay lại",
  "THAI": "กลับ",
  "UZBEK": "Orqaga",
  "DEUTSCH": "Zurück",
  "TAGALOG": "Bumalik"
}


   ]
} 
   export const languagesC = [
    {
      title: 'French',
      codeO: 'FR',
      codeW: 'FR'
    },
    {
      title: 'English',
      codeO: 'EN',
      codeW: 'ENGLISH'
    },
    {
      title: 'Russian',
      codeO: 'RU',
      codeW: 'RUSSIAN'
    },
    {
      title: 'Turkish',
      codeO: 'TR',
      codeW: 'TURKISH'
    },
    {
      title: 'Hindi',
      codeO: 'HI',
      codeW: 'HINDI'
    },
    {
      title: 'Farsi',
      codeO: 'FA',
      codeW: 'FARSI'
    },
    {
      title: 'Spanish',
      codeO: 'ES',
      codeW: 'Español'
    },
    {
      title: 'Portuguese',
      codeO: 'PT',
      codeW: 'Português'
    },
    {
      title: 'Indonesian',
      codeO: 'ID',
      codeW: 'Bahasa Indonesia'
    },
    {
      title: 'Vietnamese',
      codeO: 'VI',
      codeW: 'Vietnam'
    },
    {
      title: 'Thai',
      codeO: 'TH',
      codeW: 'Thailande'
    },
    {
      title: 'Uzbek',
      codeO: 'UZ',
      codeW: 'UZBEK'
    },
    {
      title: 'German',
      codeO: 'DE',
      codeW: 'DEUTSCH'
    },
    {
      title: 'Tagalog',
      codeO: 'TL',
      codeW: 'TAGALOG'
    }
  ];
  export const translateOld = (key, lang) => {
    const entry = translations.TR.find(tr => tr[lang] === key);
    return entry ? entry[lang] : key;
  };

  export const translate = (key, lang,larg) => {
    const entry = translations.TR.find(tr => tr[larg] === key); // Assuming 'FR' is the base language key
    return entry ? entry[lang] : key;
  };