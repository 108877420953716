import { useState, useEffect ,useContext,useRef} from "react";
import "../../App.css";
import Clicker from "../../Components/Clicker/Clicker";
import Footer from "../../Components/Footer/Footer";
import Referal from "../../Components/Referal/Referal";
import Recomp from "../../Components/Recomp/Recomp";
import { Context } from "../../Context/Context";
import { Tabs,Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import HeaderEarn from "../../Components/HeaderEarn/HeaderEarn";
import { Button, Space , Toast} from 'antd-mobile'
import Header from "../../Components/Header/Header";
import { translate } from "../../Hooks/translate";
import {add_wallet,check_wallet,check_username} from '../../Hooks/firebase_functions'
import vikexLogo from '../../images/vikexLogo1.png'
import { Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';

const tele = window.Telegram.WebApp;
//const tonConnect = new TonConnect();


function Friends() {
  const {value, value2, value3} = useContext(Context)

  const [langContext, setLangContext] = value
  const [userData, setUserData] = value2
  const [tlgmInfo, setTlgmInfo] = value3
  const [cartItems, setCartItems] = useState([]);
  const [user, setUser] = useState('');
  const [valueT, setValueT] = useState('')
  const [hasClick, setHasClick] = useState(false)
  const [usernameStatus, setUsernameStatus] = useState('')
  const navigate = useNavigate();


  const confirmUsername = async (val) => {
    setHasClick(true)
    const rep = await check_username(val,userData)
    if(rep){
      setUsernameStatus('available')
      const oldUserData = userData
      oldUserData.username = val
      oldUserData.sheep = 0
      setUserData(oldUserData)
      navigate('/friends')
    }else{
      setUsernameStatus('used')
    }
  }

  
  return (
    <div style={{display: 'flex', flexDirection: 'column',backgroundColor:'#131313'}}>
      <div className="main-content">

      <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'100%'}}>
        <img 
          src={vikexLogo}
          style={{width:'22%',objectFit:'cover'}}
        />
        <p style={{color:'#fff',fontSize:16,marginTop:30}}>Welcome!</p>
        <div className="touchable" style={{justifyContent:'center',marginTop:10,border:'1px solid #202630',backgroundColor:'#202630',borderRadius:7,padding:'7px 40px 7px 40px'}}>
        <Input
          min={5}
          max={12}
          style={{'--color':'#fff','--font-size':14,'--placeholder-color':'#CACACA','--text-align':'center'}}
          placeholder='Define your username'
          value={valueT}
          onChange={val => {
            setValueT(val)
          }}
        />
        </div>
        {usernameStatus == 'available' && (<p style={{color:'#4285F4',position:'absolute',fontSize:12,bottom:90}}>Great! This username is available.</p>)}
        {usernameStatus == 'used' && (<p style={{color:'#D83C3C',position:'absolute',fontSize:12,bottom:90}}>This username is already used. Try another!</p>)}
      </div>
     

      <div className={ 'visible bottom-btn'} style={{position:'fixed',bottom:30}}>
          <div   className="card-referal centered-elt"  style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
            <div className="touchable invite-btgn" onClick={async()=>{await confirmUsername(valueT)}} id="shareButton"  fill='solid'  style={{borderRadius:7,padding:5,flex:4,marginRight:10,width:'100%',backgroundColor:valueT.length > 4 ? '#4285F4' : '#202630',border:'solid 0px #BBB9CC',marginLeft:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <p  style={{color:valueT.length > 4 ? '#fff' :'#BBB9CC',textAlign:'center',fontSize:16,fontWeight:'500',marginTop:8,marginBottom:8}}>Confirm</p>
            </div>
          </div>   
        </div>
    </div>

    </div>
  );
}
export default Friends;
